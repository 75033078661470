import { useStores } from 'store';
import { WindsTopCurrent } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const TopWindGusts = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper className="TopWindGusts">
      {widgetStore.topWindGustData &&
        widgetStore.topWindGustData.map((station: WindsTopCurrent) => (
          <DialWrapper key={`topGusts-${station.common_name}`}>
            <SolidDial withBorder widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} current={station.gspeed} />
            <DialTitle>{station.common_name}</DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default TopWindGusts;
