import { USER_TYPES } from '@sdge-web-app/shared/dist/constants';
import { User } from '@sdge-web-app/shared/dist/types';
import { makeAutoObservable } from 'mobx';

class UserStore {
  user?: User;
  userRole?: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setUser(user: User) {
    this.user = user;
    this.userRole = user.role;
  }

  deleteUser() {
    this.user = undefined;
    this.userRole = USER_TYPES.PUBLIC;
  }
}

export default UserStore;
