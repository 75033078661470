import styled from 'styled-components';
import { OPI_LEVELS, PAL_LEVELS, SAWTI_LEVEL_OBJS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { fpiShortLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { opiLevelShort, palLevelShort, redFlagLevelShort, sawtiLevelShort } from 'utils/widgetUtils';
import { GradientPinWithSubtext } from 'components/Map/pins/GradientPinWithSubtext';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { SolidPin } from 'components/Map/pins/SolidPin';
import { PinWithTimes } from 'components/Map/pins/PinWithTimes';
import moment from 'moment';

const ListWrapper = styled.div`
  margin-top: 2rem;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
`;

const DialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

function MapPins() {
  return (
    <div className="MapPins">
      <h2>Map Pin Examples:</h2>
      <ListWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_TEMP}
          <GradientPinWithSubtext
            widgetName={WIDGET_NAMES.CURRENT_TEMP}
            current={80}
            min={50}
            max={110}
            subText="Imperial Valley"
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_RH}
          <GradientPinWithSubtext
            widgetName={WIDGET_NAMES.CURRENT_RH}
            current={50}
            subText="Crestwood Testing the Length"
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.WIND_GUST_FORECAST}
          <SolidPinWithSubtext
            widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
            current={50}
            subText="Crestwood"
            windDirection="SE"
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.FPI}
          <SolidPin widgetName={WIDGET_NAMES.FPI} current={13} innerText={fpiShortLevelFromValue(13)} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.OPI}
          <SolidPin
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.extreme.long}
            innerText={opiLevelShort(OPI_LEVELS.extreme.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.LOW_TEMP}
          <SolidPinWithSubtext widgetName={WIDGET_NAMES.LOW_TEMP} current={62} subText="Imperial Valley" />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.SAWTI}
          <SolidPin
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.moderate.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.moderate.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.PAL}
          <SolidPinWithSubtext
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.blue.long}
            innerText={palLevelShort(PAL_LEVELS.blue.long)}
            subText="Crestwood"
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.RED_FLAG}
          <PinWithTimes
            widgetName={WIDGET_NAMES.RED_FLAG}
            current={true}
            innerText={redFlagLevelShort(true)}
            start={moment().subtract(1, 'day').toDate()}
            stop={moment().add(13, 'hours').toDate()}
          />
        </DialWrapper>
      </ListWrapper>
    </div>
  );
}

export default MapPins;
