import React, { useState } from 'react';
import { deleteApiData, postApiData } from 'utils/axios';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import { LOGIN_ENDPOINT, LOGOUT_ENDPOINT } from '../../constants';
import { InputBox } from '../../layout/InputBox';
import { Button } from '../../layout/Button';
import { useStores } from '../../store';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoginHeaderText = styled.h3`
  padding-bottom: 0.625rem;
  border-bottom: 0.0625rem solid #eee;
`;

const LoginLabel = styled.label`
  margin-bottom: 0.3125rem;
  margin-top: 0.625rem;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  bottom: 0;
  margin: 1rem 0;
`;

interface Props {
  onLogin?: () => void;
}

export const Login = observer(({ onLogin }: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessageText, setErrorMessageText] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { userStore } = useStores();

  const handleLoginSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    postApiData(LOGIN_ENDPOINT, {
      username: username.toLowerCase(),
      password: password,
    })
      .then((response) => {
        setShowErrorMessage(false);
        userStore.setUser(response.data);
        onLogin && onLogin();
      })
      .catch((error: AxiosError) => {
        console.log(error);

        let errorText = 'Unable to login, please try again';
        if (error.response?.status === 403) {
          errorText = 'Access Denied';
        }
        setErrorMessageText(errorText);

        setShowErrorMessage(true);
      });
  };

  const handleLoginFormChange = (e: React.ChangeEvent<HTMLInputElement>, fieldToUpdate: string) => {
    const fieldValue: string = e.target.value.toString();

    if (fieldToUpdate === 'username') {
      setUsername(fieldValue);
    } else {
      setPassword(fieldValue);
    }
  };

  const logout = () => {
    deleteApiData(LOGOUT_ENDPOINT)
      .then(() => {
        userStore.deleteUser();
      })
      .catch((error: unknown) => {
        console.log(error);
      });
  };

  return (
    <>
      {userStore.user ? (
        <>
          You are currently logged in as {userStore.user.first_name}. <Button label="Logout" onClick={logout} />
        </>
      ) : (
        <form onSubmit={handleLoginSubmit}>
          <LoginContainer id="login">
            <LoginHeaderText>Login</LoginHeaderText>

            <LoginLabel htmlFor="username">Username</LoginLabel>
            <InputBox
              data-cy="username-field"
              value={username}
              onChange={handleLoginFormChange}
              fieldToUpdate="username"
              fieldType="text"
              autofocus
            />

            <LoginLabel htmlFor="password">Password</LoginLabel>
            <InputBox
              data-cy="password-field"
              value={password}
              onChange={handleLoginFormChange}
              fieldToUpdate="password"
              fieldType="password"
            />

            <ButtonWrapper>
              <Button data-cy="login-submit-button" label="Login" type="submit" />
            </ButtonWrapper>

            {showErrorMessage && <div>{errorMessageText}</div>}
          </LoginContainer>
        </form>
      )}
    </>
  );
});

export default Login;
