import React from 'react';
import moment, { Moment } from 'moment-timezone';
import styled from 'styled-components';
import { FORECAST_STATIONS_LIST } from '@sdge-web-app/shared/dist/constants';
import { Forecast } from '@sdge-web-app/shared/dist/types';
import WeatherForecastLegend from './WeatherForecastLegend';
import WeatherConditionIcon from './WeatherConditionIcon';
import { SectionTitle, SectionWithTable } from './shared';
import { temperatureColor } from '../../../utils/widgetUtils';

const Wrapper = styled(SectionWithTable)`
  table {
    thead tr {
      border: none;
    }

    th {
      padding: 14px 0 20px;
      color: ${props => props.theme.foreground};
    }

    td {
      font-weight: normal;
      color: ${props => props.theme.foreground};
    }
  }
`;

const Temperature = styled.span<{ temperature: number }>`
  color: ${({ temperature }) => temperatureColor(temperature)};
`;

const RowLabel = styled.tr`
  border-bottom: none !important;

  th {
    text-align: left;
    padding-bottom: 0 !important;
    text-transform: uppercase;
  }
`;

const ConditionCell = styled.td`
  line-height: 1.2;

  img {
    width: 100%;
    max-width: 68px;
    height: auto;
    padding: 0 4px 4px 4px;
  }
`;

interface Props {
  reportDate: Date | string;
  show: boolean;
  forecast?: Forecast[];
}

export default function SevenDayWeatherForecast({ reportDate, show, forecast }: Props) {
  if (!show || forecast == null) return null;

  const reportStartDate = moment(reportDate).startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 7; i++) {
    days.push(reportStartDate.clone().add(i, 'days'));
  }

  const stations = [
    FORECAST_STATIONS_LIST.SOB,
    FORECAST_STATIONS_LIST.MVN,
    FORECAST_STATIONS_LIST.MPE,
    FORECAST_STATIONS_LIST.CRI,
    FORECAST_STATIONS_LIST.ECJ,
    FORECAST_STATIONS_LIST.RAM,
    FORECAST_STATIONS_LIST.JUL,
    FORECAST_STATIONS_LIST.BRG,
  ];

  const campoForecast = forecast.filter((f) => f.station === 'CPO');

  return (
    <Wrapper>
      <SectionTitle>Latest 7-Day Forecast</SectionTitle>
      <table>
        <thead>
          <tr>
            {days.map((day) => (
              <th key={day.toISOString()}>
                {day.format('ddd').toUpperCase()}
                <br />
                {day.format('M/D')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <RowLabel>
            <th colSpan={7}>Expected Weather Conditions</th>
          </RowLabel>
          <tr>
            {days.map((day) => {
              const condition = forecast.find(
                (f) =>
                  f.station === FORECAST_STATIONS_LIST.SOB.code &&
                  day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
              );

              return condition != null ? (
                <ConditionCell key={day.toISOString()}>
                  <WeatherConditionIcon condition={condition.expected_weather_conditions} />
                  <br />
                  {condition.expected_weather_conditions}
                </ConditionCell>
              ) : (
                <td key={day.toISOString()}></td>
              );
            })}
          </tr>
          {stations.map((station) => (
            <React.Fragment key={station.code}>
              <RowLabel>
                <th colSpan={7}>{station.area_label}</th>
              </RowLabel>
              <tr>
                {days.map((day) => {
                  const areaForecast = forecast.find(
                    (f) =>
                      f.station === station.code &&
                      day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
                  );

                  return areaForecast != null ? (
                    <td key={day.toISOString()}>
                      <Temperature temperature={areaForecast.mint}>{areaForecast.mint}</Temperature>&nbsp;/&nbsp;
                      <Temperature temperature={areaForecast.maxt}>{areaForecast.maxt}</Temperature>
                    </td>
                  ) : (
                    <td key={day.toISOString()}></td>
                  );
                })}
              </tr>
            </React.Fragment>
          ))}
          <RowLabel>
            <th colSpan={7}>Backcountry Winds</th>
          </RowLabel>
          <tr>
            {days.map((day) => {
              const windForecast = campoForecast.find(
                (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
              );

              return <td key={day.toISOString()}>{windForecast?.gust}</td>;
            })}
          </tr>
          <RowLabel>
            <th colSpan={7}>Chance of Precipitation</th>
          </RowLabel>
          <tr>
            {days.map((day) => {
              const windForecast = campoForecast.find(
                (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
              );

              return <td key={day.toISOString()}>{windForecast?.pop != null ? `${windForecast?.pop}%` : ''}</td>;
            })}
          </tr>
        </tbody>
      </table>
      <WeatherForecastLegend />
    </Wrapper>
  );
}
