import styled from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 80px 0 50px;
  border-bottom: 1px solid ${COLORS.disabled};
  padding-bottom: 60px;

  @media (max-width: 620px) {
    display: block;
  }

  svg {
    display: block;
    width: 184px;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: -4px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  width: 50%;
  color: #666;

  @media (max-width: 620px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export default function Warranty() {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      {themeContext.logo.render({ title: 'SDG&E Logo' })}
      <Text>
        Materials and information are provided "as is" without warranty of any kind, either express or implied,
        including but not limited to the implied warranties of merchantability or fitness for a particular purpose. In
        no event will San Diego Gas & Electric Company be liable to any party for any direct, indirect, special or
        consequential damages for any use of the materials or information provided herein, including without limitation,
        any claims or demands for lost profits or business interruption, even if advised of the possibility of such
        damages.
      </Text>
    </Wrapper>
  );
}
