import styled from 'styled-components';

import { ReactComponent as SpeechBubbleIcon } from 'assets/svg/speech_bubble.svg';
import { ReactComponent as SunIcon } from 'assets/svg/sun.svg';
import { ReactComponent as TwitterLogo } from 'assets/svg/twitter.svg';
import SafetyMessage from 'components/SafetyMessage';
import { useState } from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import WeatherCarouselMobile from '../../WeatherCarouselMobile';
import TwitterCarouselMobile from '../../TwitterCarouselMobile';
import ProgressBar from './ProgressBar';

const Wrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.background};
  width: 100%;
  height: 5.375rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  svg {
    width: 5.75rem;
    height: auto;
  }
`;

const SafetyMessageButton = styled.button<{ active: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  margin-top: 0.4rem;

  svg {
    width: 1.625rem;
    height: auto;
    fill: ${({ active }) => (active ? '#000' : '#d9d8d9')};
  }
`;

const WeatherButton = styled.button<{ active: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.8rem;
  cursor: pointer;

  svg {
    width: 1.375rem;
    height: auto;
    fill: ${({ active }) => (active ? '#000' : '#d9d8d9')};
  }
`;

const TwitterButton = styled.button<{ active: boolean }>`
  appearance: none;
  background: none;
  border: none;
  padding: 0.8rem;
  cursor: pointer;

  svg {
    width: 1.375rem;
    height: auto;
    fill: ${({ active }) => (active ? '#000' : '#d9d8d9')};
  }
`;

enum OverlayTypes {
  'SafetyMessage',
  'Weather',
  'Twitter',
}

export default function MainNavMobile() {
  const [currentOverlay, setCurrentOverlay] = useState<OverlayTypes | null>(null);

  const overlayButtonHandler = (overlayType: OverlayTypes) =>
    currentOverlay === overlayType ? setCurrentOverlay(null) : setCurrentOverlay(overlayType);

  const showSafetyMessage = currentOverlay === OverlayTypes.SafetyMessage;
  const showWeatherCarousel = currentOverlay === OverlayTypes.Weather;
  const showTwitterCarousel = currentOverlay === OverlayTypes.Twitter;

  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      <Left>
        <LogoWrapper>{themeContext.logo.render({ title: 'SDG&E Logo' })}</LogoWrapper>
      </Left>
      <Right>
        <SafetyMessageButton
          active={showSafetyMessage}
          onClick={() => overlayButtonHandler(OverlayTypes.SafetyMessage)}>
          <SpeechBubbleIcon />
        </SafetyMessageButton>
        <WeatherButton active={showWeatherCarousel} onClick={() => overlayButtonHandler(OverlayTypes.Weather)}>
          <SunIcon />
        </WeatherButton>
        <TwitterButton active={showTwitterCarousel} onClick={() => overlayButtonHandler(OverlayTypes.Twitter)}>
          <TwitterLogo />
        </TwitterButton>
      </Right>
      <SafetyMessage show={showSafetyMessage} />
      {showWeatherCarousel && <WeatherCarouselMobile />}
      {showTwitterCarousel && <TwitterCarouselMobile />}
      <ProgressBar />
    </Wrapper>
  );
}
