import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuthenticatedApiData } from 'utils/axios';
import { AxiosError } from 'axios';

import { WEATHER_BRIEFING_ENDPOINT } from '../../constants';
import { WeatherBriefing as IWeatherBriefing } from '@sdge-web-app/shared/dist/types';
import { Loading, NotFound } from '../WeatherBriefingSearch';
import WeatherBriefing from 'components/WeatherBriefing';

export default function WeatherBriefingById() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [weatherBriefing, setWeatherBriefing] = useState<IWeatherBriefing | null>(null);

  useEffect(() => {
    if (!loading) return;

    getAuthenticatedApiData(`${WEATHER_BRIEFING_ENDPOINT}/${id}`)
      .then((response) => setWeatherBriefing(response.data))
      .catch((error: AxiosError) => setError(error))
      .finally(() => setLoading(false));
  }, [id, loading]);

  if (loading) {
    return <Loading>Loading...</Loading>;
  }

  if (error || weatherBriefing == null) {
    return <NotFound>Unable to find a weather briefing for id "{id}".</NotFound>;
  }

  return <WeatherBriefing weatherBriefing={weatherBriefing} />;
}
