import { useMap } from 'react-map-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl, { CanvasSource } from 'mapbox-gl';
import { observer } from 'mobx-react-lite';

import { Windy } from '../windy';

import { useStores } from '../../../store';

function getEventObject(map: mapboxgl.Map) {
  const canvas = map.getCanvas();
  const dimensions = map.getBounds();

  const canvasSource = map.getSource('canvas-source') as CanvasSource;

  canvasSource.setCoordinates([
    dimensions.getNorthWest().toArray(),
    dimensions.getNorthEast().toArray(),
    dimensions.getSouthEast().toArray(),
    dimensions.getSouthWest().toArray(),
  ]);

  const result = {
    width: canvas.width,
    height: canvas.height,
    north: dimensions.getNorth(),
    south: dimensions.getSouth(),
    west: dimensions.getWest(),
    east: dimensions.getEast(),
  };
  return result;
}

type Props = {
  canvasRef: HTMLCanvasElement | null;
  initialRotationComplete: boolean;
  windsLoaded: boolean;
};

const WindStreamLines = observer(({ canvasRef, initialRotationComplete, windsLoaded }: Props) => {
  const { current: map } = useMap();

  const [windyLoaded, setWindyLoaded] = useState<boolean>(false);

  const { windsStore } = useStores();

  let windy = useRef<any>(null);

  const resetWind = useCallback(
    (map: any) => {
      const obj = getEventObject(map);
      const { north, south, west, east, width, height } = obj;
      const zoomLevel = map.getZoom();
      let particleMultiplier = 1;
      if (zoomLevel > 9) {
        particleMultiplier = 0.8;
      }
      if (zoomLevel > 10) {
        particleMultiplier = 0.15;
      }
      if (zoomLevel > 11) {
        particleMultiplier = 0.08;
      }
      if (zoomLevel > 12) {
        particleMultiplier = 0.05;
      }

      canvasRef!.width = width;
      canvasRef!.height = height;

      windy.current.start(
        [
          [0, 0],
          [width, height],
        ],
        width,
        height,
        [
          [west, south],
          [east, north],
        ],
        { particleMultiplier: particleMultiplier },
      );
    },
    [canvasRef],
  );

  const clearCanvas = useCallback(() => {
    if (canvasRef) {
      canvasRef.getContext('2d')?.clearRect(0, 0, canvasRef.width, canvasRef.height);
    }
  }, [canvasRef]);

  // Clean up windy.ts on unmount
  useEffect(
    () => () => {
      if (windy.current) {
        windy.current.stop();
        windy.current = null;

        clearCanvas();
      }
    },
    [canvasRef, clearCanvas],
  );

  useEffect(() => {
    if (canvasRef == null || !initialRotationComplete) return;

    // Stop anything that may be running to prevent extra wind lines from appearing.
    if (windy.current) {
      windy.current.stop();
      windy.current = null;
      clearCanvas();
    }

    // Only load if the current active widget is one that should have the wind animation on the map via canvas.
    if (windsLoaded && windsStore.streamlines) {
      windy.current = Windy({ canvas: canvasRef, data: windsStore.streamlines });
      resetWind(map?.getMap());
      setWindyLoaded(true);
    }
  }, [windsStore.streamlines, resetWind, map, canvasRef, initialRotationComplete, windsLoaded, clearCanvas]);

  useEffect(() => {
    const stop = () => {
      if (windy.current) {
        windy.current.stop();
        clearCanvas();
      }
    };

    const restart = (mapEvent: any) => {
      clearCanvas();
      windy.current = Windy({ canvas: canvasRef, data: windsStore.streamlines });
      if (windyLoaded) resetWind(mapEvent.target);
    };

    map?.on('dragstart', stop);
    map?.on('zoomstart', stop);

    map?.on('dragend', restart);
    map?.on('zoomend', restart);

    return () => {
      map?.off('dragstart', stop);
      map?.off('zoomstart', stop);

      map?.off('dragend', restart);
      map?.off('zoomend', restart);
    };
  }, [map, resetWind, windyLoaded, canvasRef, windsStore.streamlines, clearCanvas]);

  return null;
});

export default WindStreamLines;
