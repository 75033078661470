import { getApiData } from 'utils/axios';
import { SAFETY_MESSAGE_ENDPOINT } from '../../constants';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { useStores } from 'store';

const Container = styled.div<{ show: boolean; isMobile: boolean }>`
  background-color: ${({ isMobile }) =>
    isMobile ? (props) => props.theme.background : (props) => props.theme.safetyMessage};
  border-radius: ${({ isMobile }) => (isMobile ? '2.5rem 2.5rem 2.5rem 2.5rem' : '2.5rem 2.5rem 0 0')};
  border-bottom: 0.0625rem solid ${(props) => props.theme.borderColor};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 1.5rem 3rem 2rem 1.5rem;
  height: ${({ isMobile }) => (isMobile ? 'min-content' : '11rem')};
  max-height: 18rem;
  position: ${({ isMobile }) => (isMobile ? 'absolute' : 'initial')};
  top: ${({ isMobile }) => (isMobile ? '7rem' : 'unset')};
  left: ${({ isMobile }) => (isMobile ? '2.5%' : 'unset')};
  width: ${({ isMobile }) => (isMobile ? '95%' : 'initial')};
`;

const Header = styled.div`
  span {
    color: ${(props) => props.theme.foreground};
  }
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 0.75rem;
  height: 1.25rem;
  & > div {
    position: relative;
    bottom: 1.2rem;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: 1rem;
`;

const MessageText = styled.div<{ isMobile: boolean }>`
  margin-top: 0.5rem;
  color: ${(props) => props.theme.foregroundGray};
  font-size: 0.85rem;
  overflow: auto;
  height: ${({ isMobile }) => (isMobile ? 'min-content' : '7rem')};
  max-height: 15rem;
`;

const ClickableInfoIcon = styled(InfoIcon)`
  cursor: pointer;
`;

type SafetyMessageProps = {
  show?: boolean;
};

export default function SafetyMessage({ show = true }: SafetyMessageProps) {
  const [safetyMessage, setSafetyMessage] = useState<string>('');
  const isMobile = useMediaQuery({ query: '(max-width: 1140px)' });
  const { overlayStore } = useStores();

  useEffect(() => {
    getApiData(`${SAFETY_MESSAGE_ENDPOINT}`)
      .then((response) => {
        setSafetyMessage(response.data[0].message);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }, []);
  return (
    <Container show={show} isMobile={isMobile}>
      <Header>
        <span>Safety Message</span>
        <ClickableInfoIcon onClick={() => overlayStore.open('Safety Message')} />
      </Header>
      {safetyMessage && (
        <MessageContainer>
          <MessageText isMobile={isMobile}>{safetyMessage}</MessageText>
        </MessageContainer>
      )}
    </Container>
  );
}
