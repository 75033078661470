import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { Subtext, SubtextWrapper } from './SharedStyles';
import { BottomTriangle } from './Triangle';

type PinProps = {
  widgetName: string;
  current: string | number | boolean;
  innerText?: string;
  subText: string;
  windDirection?: string;
  withBorder?: boolean;
};

export function SolidPinWithSubtext({ widgetName, current, innerText, subText, windDirection, withBorder }: PinProps) {
  return (
    <div>
      <SolidDial
        large
        withBorder={withBorder}
        widgetName={widgetName}
        current={current}
        innerText={innerText}
        windDirection={windDirection}
      />
      <SubtextWrapper>
        <Subtext
          hasOutline={widgetName === WIDGET_NAMES.TOP_CURRENT_GUSTS || widgetName === WIDGET_NAMES.WIND_GUST_FORECAST}>
          {subText}
        </Subtext>
        {BottomTriangle(
          true,
          widgetName === WIDGET_NAMES.TOP_CURRENT_GUSTS || widgetName === WIDGET_NAMES.WIND_GUST_FORECAST,
        )}
      </SubtextWrapper>
    </div>
  );
}
