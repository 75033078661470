import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ADMIN_TOOL_URL } from '../../constants';
import {
  AQI,
  FORECAST,
  FPI,
  HUMIDITY,
  LOW_TEMP,
  OBSERVATIONS,
  OPI,
  PROJECT_ACTIVITY_LEVEL,
  PSPS,
  RAINFALL,
  RED_FLAG,
  SAFETY_MESSAGE,
  SAWTI,
  SNOWFALL,
  SUNRISE_SUNSET,
  TEMPERATURES,
  THUNDERSTORM_THREAT,
  TIDES,
  WIDGETS,
  WIND_GUSTS,
  WINDS,
  WINDS_STREAMLINES,
  WINDS_TOP_CURRENT,
  WINDS_TOP_MAX,
  WINTER_STORM_THREAT,
  DEFAULT_WIDGETS,
} from '@sdge-web-app/shared/dist/constants';
import useWidgetDataLoader from 'hooks/useWidgetDataLoader';

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    display: flex;
    padding: 0.25rem 1rem;
  }
`;

function Api() {
  useWidgetDataLoader(DEFAULT_WIDGETS);
  return (
    <div>
      <Nav>
        <div>
          <b>
            <Link to="/">Home</Link>
          </b>
        </div>
        <div>
          <b>
            <a href={ADMIN_TOOL_URL}>Admin Tool</a>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WIDGETS}`}>Selected Widgets</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${FPI}`}>FPI</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${OPI}`}>OPI</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${AQI}`}>AQI</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${SAWTI}`}>Santa Ana Wildfire Threat Index (SAWTII)</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${OBSERVATIONS}`}>Observations</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${TEMPERATURES}`}>Temperatures</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${HUMIDITY}`}>Humidity</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WINDS}`}>Winds</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WINDS_TOP_CURRENT}`}>Top 5 Current Gusts</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WINDS_TOP_MAX}`}>Top 5 Max Gusts</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${FORECAST}`}>Seven Day Forecast</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${LOW_TEMP}`}>Low Temp Forecast</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WIND_GUSTS}`}>Wind Gust Forecast</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${RAINFALL}`}>Rainfall Forecast</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${SNOWFALL}`}>Snowfall Forecast</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WINDS_STREAMLINES}`}>Wind Streamlines</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${THUNDERSTORM_THREAT}`}>Thunderstorm Threat</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${WINTER_STORM_THREAT}`}>Winter Storm Threat</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${TIDES}`}>Tides</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${RED_FLAG}`}>Red Flag Warnings</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${SUNRISE_SUNSET}`}>Sunrise/Sunset</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${SAFETY_MESSAGE}`}>Safety Message</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${PROJECT_ACTIVITY_LEVEL}`}>Project Activity Level</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/${PSPS}`}>PSPS Potential Index</Link>
          </b>
        </div>
        <div>
          <b>
            <Link to={`/dials`}>Dial Emporium</Link>
          </b>
        </div>
      </Nav>
      <div>
        <b>Select a link above to display data here.</b>
      </div>
    </div>
  );
}

export default Api;
