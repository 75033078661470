import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';

import Map, { Layer, MapRef, NavigationControl, Source, AttributionControl } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { observe } from 'mobx';
import { USER_ROLES, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';

import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

import { useStores } from '../../store';
import { MAPBOX_ACCESS_TOKEN } from '../../constants';
import CameraIcon from '../../assets/svg/cameras.svg';
import CameraIconDark from '../../assets/svg/cameras_dark.svg';
import LightModeIcon from '../../assets/svg/light_icon.svg';
import DarkModeIcon from '../../assets/svg/dark_icon.svg';

import dots1 from './png/dots1.png';
import dots2 from './png/dots2.png';
import dots3 from './png/dots3.png';
import lines from './png/lines.png';
import linesCrossed from './png/lines_crossed.png';

import Styles from './Styles';
import Fpi from './components/Fpi';
import Pal from './components/Pal';
import RedFlagWarnings from './components/RedFlagWarnings';
import Sawti from './components/Sawti';
import TieLines from './components/TieLines';
import Cameras from './components/Cameras';
import CurrentTemps from './components/CurrentTemps';
import CurrentRH from './components/CurrentRH';
import CurrentGusts from './components/CurrentGusts';
import ForecastedGusts from './components/ForecastedGusts';
import Opi from './components/Opi';
import WindStreamLines from './components/WindStreamLines';
import LowTemps from './components/LowTemps';
import Radar from './components/Radar';


if (MAPBOX_ACCESS_TOKEN) {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
}

export function isFeatureCollection(data: unknown): data is FeatureCollection {
  return typeof data === 'object' && data !== null && 'type' in data && 'features' in data;
}

class CameraControl {
  _map: any;
  _container: any;

  darkMode: boolean;
  constructor(dark: boolean) {
    this.darkMode = dark;
  }

  update(dark: boolean) {
    this.darkMode = dark;

    if (this._container == null) return null;

    this._container = document.getElementById('camera-toggle-button');
    this._container.className = `camera-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'camera-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? CameraIconDark : CameraIcon}' alt='Toggle Camera'/>`;

    return this._container;
  }

  onAdd(map: any) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = `camera-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'camera-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? CameraIconDark : CameraIcon}' alt='Toggle Camera'/>`;

    return this._container;
  }

  onRemove() {
    this._container = document.getElementById('camera-toggle-button');
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

class DarkModeControl {
  _map: any;
  _container: any;

  darkMode: boolean;
  constructor(dark: boolean) {
    this.darkMode = dark;
  }

  update(dark: boolean) {
    this.darkMode = dark;

    if (this._container == null) return null;

    this._container = document.getElementById('darkmode-toggle-button');
    this._container.className = `darkmode-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'darkmode-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? DarkModeIcon : LightModeIcon}' alt='Toggle Dark Mode'/>`;

    return this._container;
  }

  onAdd(map: any) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = `darkmode-toggle-button ${this.darkMode ? 'toggle-dark' : ''}`;
    this._container.id = 'darkmode-toggle-button';
    this._container.innerHTML = `<img src='${this.darkMode ? DarkModeIcon : LightModeIcon}' alt='Toggle Dark Mode'/>`;

    return this._container;
  }

  onRemove() {
    this._container = document.getElementById('darkmode-toggle-button');
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

const MapContainer = observer(() => {
  // @refresh reset
  // This comment ^ causes this component and children to refresh completely on save.
  const themeContext = useContext(ThemeContext);

  const { mapStateStore, widgetStore, userStore } = useStores();

  const map = useRef<MapRef | null>(null);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1140px)' });
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [windsLoaded, setWindsLoaded] = useState<boolean>(false);
  const [initialBearingSet, setInitialBearingSet] = useState<boolean>(false);
  const [initialRotationComplete, setIinitialRotationComplete] = useState<boolean>(false);
  const [showCameras, setShowCameras] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(mapStateStore.darkMode);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const cameraControl = new CameraControl(darkMode);
  const darkModeControl = new DarkModeControl(darkMode);

  const mapStateObserver = observe(mapStateStore, (change) => {
    // Change is an object with a couple properties that describe what has changed and how
    setDarkMode((darkModeCurrent) => {
      if (darkModeControl == null || cameraControl == null) return mapStateStore.darkMode;

      //mapStateStore.toggleDarkMode();
      //darkModeControl.darkMode = !darkModeCurrent;
      darkModeControl.update(mapStateStore.darkMode);

      //cameraControl.darkMode = !darkModeCurrent;
      cameraControl.update(mapStateStore.darkMode);

      return mapStateStore.darkMode;
    });
  });

  const widgetsShowingWindAnimation = [
    WIDGET_NAMES.TOP_CURRENT_GUSTS.toString(),
    WIDGET_NAMES.WIND_GUST_FORECAST.toString(),
    WIDGET_NAMES.PSPS.toString(),
    WIDGET_NAMES.SUN.toString(),
  ];

  useEffect(() => {
    if (!mapLoaded) return;

    const darkModeButtonClickHandler = () => {
      setDarkMode((darkModeCurrent) => {
        mapStateStore.toggleDarkMode();
        //darkModeControl.darkMode = !darkModeCurrent;
        darkModeControl.update(mapStateStore.darkMode);

        //cameraControl.darkMode = !darkModeCurrent;
        cameraControl.update(mapStateStore.darkMode);

        return mapStateStore.darkMode;
      });
    };

    const cameraButtonClickHandler = () => {
      setShowCameras((showCamerasCurrent) => {
        if (!showCamerasCurrent && !mapStateStore.paused) {
          mapStateStore.togglePlayOrPaused();
        }

        return !showCamerasCurrent;
      });
    };

    document.getElementById('camera-toggle-button')?.addEventListener('click', cameraButtonClickHandler);
    document.getElementById('darkmode-toggle-button')?.addEventListener('click', darkModeButtonClickHandler);
    return () => {
      document.getElementById('camera-toggle-button')?.removeEventListener('click', cameraButtonClickHandler);
      document.getElementById('darkmode-toggle-button')?.removeEventListener('click', darkModeButtonClickHandler);
    };
  }, [mapLoaded, mapStateStore]);

  useEffect(
    () => () => {
      mapStateStore.stop();
    },
    [mapStateStore],
  );

  /**
   * Set 'windsLoaded' flag when a wind animation widget is active in the rotation. Flag is used
   * by WindStreamLines to toggle the animation on the shared canvas.
   */
  useEffect(() => {
    if (mapStateStore.currentWidgetName !== null) {
      if (widgetsShowingWindAnimation.includes(mapStateStore.currentWidgetName ?? '')) {
        setWindsLoaded(true);
      } else {
        setWindsLoaded(false);
      }
    }
  }, [mapStateStore.currentWidgetName]);

  useEffect(() => {
    if (widgetStore.widgetList.length === 0) return;

    if (firstLoad) {
      setFirstLoad(false);
      mapStateStore.start(widgetStore.widgetList);
    } else {
      mapStateStore.resyncWithNewWidgets(widgetStore.widgetList);
    }
  }, [widgetStore.widgetList, mapStateStore, firstLoad]);

  useEffect(() => () => mapStateStore.stop(), [mapStateStore]);

  if (!mapboxgl.supported()) return null;

  const getCurrentSource = (widgetName: string) => {
    switch (widgetName) {
      case WIDGET_NAMES.FPI:
        return <Fpi data={widgetStore.fpiRegionData} />;
      case WIDGET_NAMES.OPI:
        return <Opi data={widgetStore.opiRegionData} />;
      case WIDGET_NAMES.PAL:
        return <Pal data={widgetStore.palData} />;
      case WIDGET_NAMES.RED_FLAG:
        return <RedFlagWarnings data={widgetStore.redFlagData} />;
      case WIDGET_NAMES.SAWTI:
        return <Sawti data={widgetStore.sawtiData} />;
      case WIDGET_NAMES.SNOWFALL:
      case WIDGET_NAMES.THUNDERSTORM:
      case WIDGET_NAMES.WINTER_STORM:
      case WIDGET_NAMES.RAINFALL:
        return <Radar />;
      case WIDGET_NAMES.CURRENT_TEMP:
        return <CurrentTemps />;
      case WIDGET_NAMES.CURRENT_RH:
        return <CurrentRH />;
      case WIDGET_NAMES.WIND_GUST_FORECAST:
        return <ForecastedGusts />;
      case WIDGET_NAMES.TOP_CURRENT_GUSTS:
        return <CurrentGusts />;
      case WIDGET_NAMES.LOW_TEMP:
        return <LowTemps />;
      default:
        return null;
    }
  };

  return (
    <Styles isMobile={isMobile} paused={mapStateStore.paused}>
      <>
        <Map
          reuseMaps
          ref={map}
          attributionControl={false}
          mapStyle={
            mapStateStore.darkMode
              ? 'mapbox://styles/svantenilsonvaltech/cl1nz23b7000015mq2es9ew1t'
              : 'mapbox://styles/svantenilsonvaltech/cldc22lx4001k01p5dh4qaxpc'
          }
          initialViewState={{
            bounds: [
              [-117.7, 32.4],
              [-115.9, 33.7],
            ],
          }}
          minPitch={0}
          maxPitch={0}
          dragRotate={false}
          maxBounds={[
            [-118.8, 31.7],
            [-115.7, 34.5],
          ]}
          onStyleData={(mapEvent) => {
            // `onStyleData` was the best place to put this.  `onLoad` happens too late for some reason.
            if (!initialBearingSet) {
              mapEvent.target.setBearing(30);
              setInitialBearingSet(true);
            }
          }}
          onLoad={async (mapEvent) => {
            const images = [
              { name: 'dots1', png: dots1 },
              { name: 'dots2', png: dots2 },
              { name: 'dots3', png: dots3 },
              { name: 'lines', png: lines },
              { name: 'lines_crossed', png: linesCrossed },
            ];

            mapEvent.target.on('styleimagemissing', (e) => {
              const id = e.id;
              mapEvent.target.addImage(id, { width: 0, height: 0, data: new Uint8Array() });
              const missingImage = images.find((image) => e.id === image.name);
              if (missingImage) {
                mapEvent.target.loadImage(missingImage.png, (error, img: any) => {
                  if (error) throw error;
                  mapEvent.target.removeImage(id);
                  mapEvent.target.addImage(id, img);
                });
              }
            });

            mapEvent.target.rotateTo(0, { duration: 1000 });

            if (document.getElementById('camera-toggle-button') != null) {
              mapEvent.target.removeControl(cameraControl);
              mapEvent.target.removeControl(darkModeControl);
            }

            mapEvent.target.addControl(cameraControl);
            mapEvent.target.addControl(darkModeControl);

            await Promise.all(
              images.map((image, i) => {
                const loadedImages = mapEvent.target.listImages();
                if (loadedImages.includes(image.name)) return;
                mapEvent.target.loadImage(image.png, (error, img: any) => {
                  if (error) throw error;
                  mapEvent.target.addImage(image.name, img);
                });
              }),
            ).then(() => {
              setMapLoaded(true);
              return;
            });
          }}
          onRotateEnd={() => {
            if (initialBearingSet) {
              // Initial map rotation is done.
              setIinitialRotationComplete(true);
            }
          }}>
          <AttributionControl customAttribution={`<a href="https://www.rainviewer.com/api.html">RainViewer </a>`} />
          <NavigationControl showCompass={false} />
          {mapStateStore.currentWidgetName != null && getCurrentSource(mapStateStore.currentWidgetName)}
          {userStore.userRole === USER_ROLES.TRANSMISSION && <TieLines />}
          {showCameras && <Cameras />}

          {/* WindStreamLines is now shared by all widgets but dynamically activated/deactivated based on 'windsLoaded' flag. See SFP-1125. */}
          <WindStreamLines
            canvasRef={canvasRef.current}
            initialRotationComplete={initialRotationComplete}
            windsLoaded={windsLoaded}
          />

          {/* Source and Layer for <WindStreamLines /> */}
          <Source
            id="canvas-source"
            type="canvas"
            canvas="canvasID"
            coordinates={[
              [-117.7, 33.7],
              [-115.9, 33.7],
              [-115.9, 32.4],
              [-117.7, 32.4],
            ]}
            animate={true}>
            <Layer type="raster" source="canvas-source" id="canvas-layer" />
          </Source>
        </Map>
        {/* <canvas> for <WindStreamLines /> */}
        <canvas ref={canvasRef} id="canvasID" style={{ display: 'none' }}>
          Canvas not supported
        </canvas>
      </>
    </Styles>
  );
});

export default MapContainer;
