import React from 'react';
import SevenDayForecastStore from './SevenDayForecastStore';
import UserStore from './UserStore';
import WeatherBriefingStore from './WeatherBriefingStore';
import WidgetStore from './WidgetStore';
import MapStateStore from './MapStateStore';
import CameraDataStore from './CameraDataStore';
import WindStore from './WindStore';
import OverlayStore from './OverlayStore';
import RadarStore from './RadarStore';

/**
 * Root Store Class with
 */
class RootStore {
  sevenDayForecastStore: SevenDayForecastStore;
  userStore: UserStore;
  widgetStore: WidgetStore;
  weatherBriefingStore: WeatherBriefingStore;
  mapStateStore: MapStateStore;
  cameraDataStore: CameraDataStore;
  windsStore: WindStore;
  overlayStore: OverlayStore;
  radarStore: RadarStore;

  constructor() {
    this.sevenDayForecastStore = new SevenDayForecastStore();
    this.userStore = new UserStore();
    this.widgetStore = new WidgetStore();
    this.weatherBriefingStore = new WeatherBriefingStore();
    this.mapStateStore = new MapStateStore();
    this.cameraDataStore = new CameraDataStore();
    this.windsStore = new WindStore();
    this.overlayStore = new OverlayStore();
    this.radarStore = new RadarStore();
  }
}

const StoresContext = React.createContext(new RootStore());

// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);
