import { WinterStormThreat } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';
import { ColumnTitle, ColumnWrapper, ListItem, ListSection, WidgetWrapper } from '../components/SharedStyles';

const WSWidgetWrapper = styled(WidgetWrapper)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;
`;

const WinterStormThreatContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <>
      {widgetStore.winterStormData &&
        widgetStore.winterStormData.map((threat: WinterStormThreat) => (
          <WSWidgetWrapper key={`winterStormThreat-${threat.id}`}>
            <ColumnWrapper>
              <ColumnTitle>Start</ColumnTitle> {threat.start}
            </ColumnWrapper>
            <ColumnWrapper>
              <ColumnTitle>End</ColumnTitle> {threat.end}
            </ColumnWrapper>
            <ColumnWrapper>
              <ColumnTitle>Peak</ColumnTitle> {threat.peak}
            </ColumnWrapper>
            <ColumnWrapper>
              <ColumnTitle>Strength</ColumnTitle> {threat.strength}
            </ColumnWrapper>
            <ColumnWrapper>
              <ColumnTitle>Threats</ColumnTitle>
              <ListSection>
                {threat.threats.map((threatItem) => (
                  <ListItem key={threatItem}>{threatItem}</ListItem>
                ))}
              </ListSection>
            </ColumnWrapper>
          </WSWidgetWrapper>
        ))}
    </>
  );
});

export default WinterStormThreatContainer;
