import { useState } from 'react';
import styled from 'styled-components';

import Login from 'components/Login';

import { LOGOUT_ENDPOINT } from '../../../constants';
import { deleteApiData } from 'utils/axios';
import { useStores } from 'store';
import { observer } from 'mobx-react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: center;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const Link = styled.div`
  font-weight: bold;
  font-size: 1.5625rem;
  cursor: pointer;
  margin: 0.5rem 0;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkWrapper = styled.div`
  text-align: center;
  height: 100%;
`;

const LoginWrapper = styled.div`
  width: 100%;
  max-width: 18.75rem;
  margin: 0 auto;
`;

export const AccountOverlay = observer(() => {
  const [showLogin, setShowLogin] = useState(false);
  const [showLegalText, setShowLegalText] = useState(false);
  const { userStore, overlayStore } = useStores();
  const isLoggedIn = !!userStore.user;

  const logout = () => {
    deleteApiData(LOGOUT_ENDPOINT)
      .then(() => {
        userStore.deleteUser();
      })
      .catch((error: unknown) => {
        console.log(error);
      });
  };

  return (
    <Wrapper>
      <Content>
        {!showLegalText && !showLogin && (
          <LinkWrapper>
            {isLoggedIn ? (
              <Link onClick={logout}>Logout</Link>
            ) : (
              <Link data-cy="login-link" onClick={() => setShowLogin(true)}>
                Login
              </Link>
            )}
            <Link onClick={() => setShowLegalText(true)}>Legal</Link>
          </LinkWrapper>
        )}
        {showLogin && (
          <LoginWrapper>
            <Login onLogin={overlayStore.close} />
          </LoginWrapper>
        )}
        {showLegalText && (
          <>
            <h3>Legal Disclaimer</h3>
            <p>
              Materials and information are provided "As is" without warranty of any kind, either express or implied,
              including but not limited to the implied warranties of merchantability or fitness for a particular
              purpose. In no event will San Diego Gas & Electric Company be liable to any party for any direct,
              indirect, special or consequential damages for any use of the materials or information provided herein,
              including, without limitation, any claims or demands for lost profits or business interruption, even if
              advised of the possibility of such damages.
            </p>
          </>
        )}
      </Content>
    </Wrapper>
  );
});
