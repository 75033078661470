import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { Row, Wrapper } from './SharedStyles';

export default function CurrentRHInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <SolidDial widgetName={WIDGET_NAMES.CURRENT_RH} current={10} />
        <SolidDial widgetName={WIDGET_NAMES.CURRENT_RH} current={25} />
        <SolidDial widgetName={WIDGET_NAMES.CURRENT_RH} current={40} />
        <SolidDial widgetName={WIDGET_NAMES.CURRENT_RH} current={80} />
      </Row>
      Map indicators with station names
      <Row>
        <SolidPinWithSubtext widgetName={WIDGET_NAMES.CURRENT_RH} current={10} subText="Crestwood" />
        <SolidPinWithSubtext widgetName={WIDGET_NAMES.CURRENT_RH} current={100} subText="Imperial Valley" />
      </Row>
    </Wrapper>
  );
}
