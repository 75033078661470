import styled from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { FunctionComponent } from 'react';

const ButtonWrapper = styled.button<{ active: boolean | undefined }>`
  display: inline-block;
  background: ${(props) =>
    props.disabled
      ? COLORS.disabled
      : props.color
      ? `${props.color}`
      : props.active
      ? COLORS.buttonHover
      : COLORS.buttonStatic};
  color: white;
  font-weight: bold;
  border: ${(props) => (props.active ? '1px solid black' : 'none')};
  padding: 6px 12px;

  &:hover {
    background: ${(props) => (props.disabled ? COLORS.disabled : props.color ? `${props.color}` : COLORS.buttonHover)};
    cursor: pointer;
    opacity: ${(props) => (props.color ? '80%' : 'null')};
  }
`;

type ButtonProps = {
  label: string;
  type?: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  'data-cy'?: string;
};

export const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => (
  <ButtonWrapper
    onClick={props.onClick}
    color={props.color}
    disabled={props.disabled}
    active={props.active}
    data-cy={props['data-cy']}>
    {props.label}
  </ButtonWrapper>
);
