import { useState, useEffect } from 'react';
import { STREAMLINES_ENDPOINT } from '../../../constants';
import axios from 'axios';

function Streamliness() {
  const [streamlinesData, setStreamlinesData] = useState([]);
  const [dataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    setDataRetreived(true);
  }, []);

  // Make a request for the Max Gust data from the database
  if (!dataRetreived) {
    axios
      .get(STREAMLINES_ENDPOINT)
      .then(function (response) {
        // handle success
        setStreamlinesData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  return (
    <div className="Streamlines">
      <h2>Wind Streamlines:</h2>
      {streamlinesData &&
        streamlinesData.map((part: any) => (
          <div key={`streamlinesData-${part.meta.date}`}>
            <div>
              <b>Parameter Number Name: </b>
              {part.header.parameterNumberName}
            </div>
            <div>
              <b>Ref DateTime:</b> {part.header.refTime}
            </div>
            <div>
              <b>Meta DateTime: </b>
              {part.meta.date}
            </div>
            <div>
              <b>nx:</b> {part.header.nx}
            </div>
            <div>
              <b>ny:</b> {part.header.ny}
            </div>
            <div>
              <b>dx: </b>
              {part.header.dx}
            </div>
            <div>
              <b>dy: </b>
              {part.header.dy}
            </div>
            <div>
              <b>Data:</b> {part.data}
            </div>
            <hr />
          </div>
        ))}
    </div>
  );
}

export default Streamliness;
