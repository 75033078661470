import { THREAT_AREAS, THREAT_COLORS, THREATS, TIME_FRAMES, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import {
  ColumnTitle,
  ColumnWrapper,
  DialTitle,
  DialWrapper,
  ListItem,
  ListSection,
} from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { ThunderstormColumnSection } from 'components/Widgets/ThunderstormThreats';
import { thunderstormLevelShort } from 'utils/widgetUtils';
import { Row, Wrapper } from './SharedStyles';

export default function ThunderstormInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.THUNDERSTORM}
            current={THREAT_COLORS[0]}
            innerText={thunderstormLevelShort(THREAT_COLORS[0])}
          />
          <DialTitle>Normal</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.THUNDERSTORM}
            current={THREAT_COLORS[1]}
            innerText={thunderstormLevelShort(THREAT_COLORS[1])}
          />
          <DialTitle>Elevated</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.THUNDERSTORM}
            current={THREAT_COLORS[2]}
            innerText={thunderstormLevelShort(THREAT_COLORS[2])}
          />
          <DialTitle>Extreme</DialTitle>
        </DialWrapper>
      </Row>
      Forecast details
      <Row>
        <ThunderstormColumnSection>
          <ColumnWrapper>
            <ColumnTitle>Start</ColumnTitle> {TIME_FRAMES[1]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>End</ColumnTitle> {TIME_FRAMES[3]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Where</ColumnTitle> {THREAT_AREAS[4]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Threats</ColumnTitle>
            <ListSection>
              <ListItem key={`threatItem-${THREATS[1]}`}>{THREATS[1]}</ListItem>
              <ListItem key={`threatItem-${THREATS[5]}`}>{THREATS[5]}</ListItem>
            </ListSection>
          </ColumnWrapper>
        </ThunderstormColumnSection>
      </Row>
    </Wrapper>
  );
}
