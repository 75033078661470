import { Marker } from 'react-map-gl';
import { useMemo } from 'react';
import { useStores } from 'store';
import { WindsCurrentGusts } from '@sdge-web-app/shared/dist/types';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

export default function CurrentGusts() {
  const { windsStore } = useStores();
  const windMarkers = useMemo(() => {
    const markers: WindsCurrentGusts[] = [];

    if (windsStore.currentGusts) {
      windsStore.currentGusts.forEach((station: WindsCurrentGusts) => {
        markers.push(station);
      });
    }

    return markers;
  }, [windsStore.currentGusts]);

  return (
    <>
      {windMarkers.map((marker) => (
        <Marker key={marker.station} longitude={marker.lon} latitude={marker.lat} anchor="bottom">
          <SolidPinWithSubtext
            widgetName={WIDGET_NAMES.TOP_CURRENT_GUSTS}
            current={marker.gspeed}
            windDirection={marker.gdir}
            subText={marker.common_name}
            withBorder
          />
        </Marker>
      ))}
    </>
  );
}
