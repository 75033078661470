import cloudyIcon from './png/cloudy.png';
import partyCloudyIcon from './png/partly_cloudy.png';
import rainyIcon from './png/rainy.png';
import sunnyIcon from './png/sunny.png';
import windyIcon from './png/windy.png';

const icons: {
  [key: string]: string;
} = {
  Sunny: sunnyIcon,
  'Partly Cloudy': partyCloudyIcon,
  Cloudy: cloudyIcon,
  Rainy: rainyIcon,
  Windy: windyIcon,
};

interface Props {
  condition: string;
}

export default function WeatherConditionIcon({ condition }: Props) {
  return <img src={icons[condition]} alt="" role="presentation" />;
}
