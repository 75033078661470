import { FORECAST_STATIONS_LIST } from '@sdge-web-app/shared/dist/constants';
import { LowTempForecast } from '@sdge-web-app/shared/dist/types';
import { useStores } from 'store';
import { observer } from 'mobx-react-lite';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const LowTempForecastContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper className="LowTemp">
      {widgetStore.lowTempData &&
        widgetStore.lowTempData.map((forecast: LowTempForecast, i) => {
          if (forecast.area !== 'additional_stations') {
            return (
              <DialWrapper key={`lowTemp-${forecast.area}`}>
                <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={forecast.mint} />
                <DialTitle>{FORECAST_STATIONS_LIST[forecast.code].area_label}</DialTitle>
              </DialWrapper>
            );
          }
          return null;
        })}
    </WidgetWrapper>
  );
});

export default LowTempForecastContainer;
