import styled from 'styled-components';
import { useStores } from 'store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 98%;
`;

const Title = styled.div`
  font-weight: bold;
  width: 100%;
  padding-bottom: 1rem;
`;

export default function SafetyMessageOverlay() {
  const { overlayStore } = useStores();

  return (
    <Wrapper>
      <Content>
        {overlayStore.overlayType && !overlayStore.widgetTooltip && (
          <>
            <Title>{overlayStore.overlayType}</Title>
            Important information for today's weather and fire situation. Updated daily by SDGE's meteorology team.
          </>
        )}
      </Content>
    </Wrapper>
  );
}
