import Autolinker from 'autolinker';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Tweet as TweetType } from '@sdge-web-app/shared/dist/types';

import { ReactComponent as TwitterLogo } from 'assets/svg/twitter.svg';

const AccountName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;

  svg {
    height: 1.125rem;
    width: auto;
    margin-right: 0.5rem;
  }
`;

function TweetSlide({ tweet }: { tweet: TweetType }) {
  return (
    <div key={tweet.id}>
      {tweet.accountName && (
        <AccountName>
          <TwitterLogo />
          <strong>@{tweet.accountName}</strong>&nbsp;&bull;&nbsp;<small>{moment(tweet.created_at).fromNow()}</small>
        </AccountName>
      )}
      <span
        dangerouslySetInnerHTML={{
          __html: Autolinker.link(tweet.text, {
            hashtag: 'twitter',
            mention: 'twitter',
            sanitizeHtml: true,
          }),
        }}
      />
    </div>
  );
}

export default TweetSlide;
