import { FPI_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from 'components/Map/pins/SolidPin';
import { DialTitle, DialWrapper } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { Row, Wrapper } from './SharedStyles';

export default function FpiInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <DialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.FPI} current={10} innerText={`10`} />
          <DialTitle>{FPI_LEVELS.normal.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.FPI} current={12} innerText={`12`} />
          <DialTitle>{FPI_LEVELS.elevated_low.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.FPI} current={14} innerText={`14`} />
          <DialTitle>{FPI_LEVELS.elevated_high.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.FPI} current={16} innerText={`16`} />
          <DialTitle>{FPI_LEVELS.extreme.long}</DialTitle>
        </DialWrapper>
      </Row>
      Map indicators
      <Row>
        <SolidPin widgetName={WIDGET_NAMES.FPI} current={10} innerText={`10`} />
        <SolidPin widgetName={WIDGET_NAMES.FPI} current={14} innerText={`14`} />
      </Row>
    </Wrapper>
  );
}
