import { SolidDial } from 'components/Widgets/components/SolidDial';
import { SubtextWrapper } from './SharedStyles';
import { BottomTriangle } from './Triangle';

type PinProps = {
  widgetName: string;
  current: string | number | boolean;
  innerText?: string;
};

export function SolidPin({ widgetName, current, innerText }: PinProps) {
  return (
    <div>
      <SolidDial large widgetName={widgetName} current={current} innerText={innerText} />
      <SubtextWrapper>{BottomTriangle()}</SubtextWrapper>
    </div>
  );
}
