import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';
import { SAWTI } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useStores } from 'store';
import styled from 'styled-components';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { sawtiLevelShort } from 'utils/widgetUtils';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const SawtiDialWrapper = styled(DialWrapper)`
  white-space: break-spaces;
`;

const SawtiContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <>
      {widgetStore.sawtiData &&
        widgetStore.sawtiData.map((day: SAWTI) => (
          <WidgetWrapper key={`sawtiData-${day.id}`}>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.today} innerText={sawtiLevelShort(day.today)} />
              <DialTitle>{moment().format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day2} innerText={sawtiLevelShort(day.day2)} />
              <DialTitle>{moment().add(1, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day3} innerText={sawtiLevelShort(day.day3)} />
              <DialTitle>{moment().add(2, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day4} innerText={sawtiLevelShort(day.day4)} />
              <DialTitle>{moment().add(3, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day5} innerText={sawtiLevelShort(day.day5)} />
              <DialTitle>{moment().add(4, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day6} innerText={sawtiLevelShort(day.day6)} />
              <DialTitle>{moment().add(5, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
            <SawtiDialWrapper>
              <SolidDial widgetName={WIDGET_NAMES.SAWTI} current={day.day7} innerText={sawtiLevelShort(day.day7)} />
              <DialTitle>{moment().add(6, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
            </SawtiDialWrapper>
          </WidgetWrapper>
        ))}
    </>
  );
});

export default SawtiContainer;
