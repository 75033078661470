// NO preceding slash - these are path components and will be inserted as ${HOST_DOMAIN}/${PATH}/etc

const FPI = 'fpi';
const OPI = 'opi';
const AQI = 'aqi';
const OBSERVATIONS = 'observations';
const WINDS = 'winds';
const WINDS_TOP_MAX = 'winds/top-5-max-gusts';
const WINDS_CURRENT_GUSTS = 'winds/current-gusts';
const WINDS_FORECASTED_GUSTS = 'winds/forecasted-gusts';
const WINDS_TOP_CURRENT = 'winds/top-5-current-gusts';
const WINDS_STREAMLINES = 'winds/streamlines';
const TEMPERATURES = 'temps';
const HUMIDITY = 'humidity';
const TIDES = 'tides';
const RED_FLAG = 'red-flag';
const SNOWFALL = 'snowfall-forecast';
const RAINFALL = 'rainfall-forecast';
const WIND_GUSTS = 'wind-gust-forecast';
const LOW_TEMP = 'low-temp-forecast';
const PROJECT_ACTIVITY_LEVEL = 'project-activity-level';
const PUSH_NOTIFICATIONS = 'push-notifications';
const FORECAST = 'forecast';
const SEVEN_DAY_FORECAST = 'seven-day-forecast';
const SUNRISE_SUNSET = 'sunrise-sunset';
const SAWTI = 'sawti';
const THUNDERSTORM_THREAT = 'thunderstorm-threat';
const WINTER_STORM_THREAT = 'winter-storm-threat';
const SAFETY_MESSAGE = 'safety-message';
const USERS = 'users';
const CAMERAS = 'cameras';
const LOGIN = 'login';
const LOGOUT = 'logout';
const CHECK_USER_AUTHENTICATED = 'check';
const WEATHER_BRIEFING = 'weather-briefing';
const AUTHENTICATE = 'authenticate';
const WIDGETS = 'widgets';
const TWITTER = 'twitter';
const PSPS = 'psps';
const API = 'api';
const IMAGES = 'images';
const POWER = 'power';
const CONFIG = 'config';
const CLIENT_CHECK = 'client-check';
const POLYGONS = 'polygons'

export {
  FPI,
  OPI,
  AQI,
  OBSERVATIONS,
  WINDS,
  WINDS_TOP_MAX,
  WINDS_TOP_CURRENT,
  WINDS_CURRENT_GUSTS,
  WINDS_FORECASTED_GUSTS,
  WINDS_STREAMLINES,
  TEMPERATURES,
  HUMIDITY,
  TIDES,
  RED_FLAG,
  SNOWFALL,
  RAINFALL,
  WIND_GUSTS,
  LOW_TEMP,
  PROJECT_ACTIVITY_LEVEL,
  THUNDERSTORM_THREAT,
  WINTER_STORM_THREAT,
  SAFETY_MESSAGE,
  SAWTI,
  PUSH_NOTIFICATIONS,
  SUNRISE_SUNSET,
  FORECAST,
  SEVEN_DAY_FORECAST,
  CAMERAS,
  USERS,
  LOGIN,
  LOGOUT,
  CHECK_USER_AUTHENTICATED,
  WEATHER_BRIEFING,
  AUTHENTICATE,
  WIDGETS,
  TWITTER,
  PSPS,
  API,
  IMAGES,
  POWER,
  CONFIG,
  CLIENT_CHECK,
  POLYGONS
};
