import { Camera } from '@sdge-web-app/shared/dist/types';
import { makeAutoObservable } from 'mobx';

class CameraDataStore {
  data: Camera[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setData(data: Camera[]) {
    this.data = data;
  }
}

export default CameraDataStore;
