import { Layer, Source } from 'react-map-gl';
import { useStores } from 'store';

// Open source RainVewer API:  https://www.rainviewer.com/api.html

export default function Radar() {
  const { radarStore } = useStores();

  // Check that we have a url loaded so we don't cause a React error when it fails to load a WMS tileset
  return radarStore.url === '' ? null : (
    <Source type="raster" tiles={[radarStore.url]} tileSize={256}>
      <Layer type="raster" paint={{}} beforeId="settlement-minor-label" />
    </Source>
  );
}
