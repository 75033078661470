import { SnowfallForecast } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';
import { isSnowfallNum, shortenIfDusting, stripSymbol } from 'utils/widgetUtils';
import { ColumnTitle, ColumnWrapper, VolumeSection, VolumeWrapper, WidgetWrapper } from '../components/SharedStyles';

export const SnowfallWidgetWrapper = styled(WidgetWrapper)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-flow: column;
`;

export const SnowVolumeWrapper = styled(VolumeWrapper)`
  padding: 0;
`;

const SnowfallForecastContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <SnowfallWidgetWrapper>
      {widgetStore.snowfallData &&
        widgetStore.snowfallData.map((element: SnowfallForecast) => {
          let to = stripSymbol(element.to);
          let from = stripSymbol(element.from);
          return (
            <ColumnWrapper key={`snowfall-${element.elevation}`}>
              <ColumnTitle noTextCaseChange>{element.elevation} ft</ColumnTitle>
              <VolumeSection>
                <SnowVolumeWrapper>
                  {shortenIfDusting(from)} {isSnowfallNum(from) && 'inch'}
                </SnowVolumeWrapper>
                —
                <SnowVolumeWrapper>
                  {shortenIfDusting(to)} {isSnowfallNum(to) && 'inch'}
                </SnowVolumeWrapper>
              </VolumeSection>
            </ColumnWrapper>
          );
        })}
    </SnowfallWidgetWrapper>
  );
});

export default SnowfallForecastContainer;
