import React from 'react';
import styled from 'styled-components';
import { Forecast } from '@sdge-web-app/shared/dist/types';
import { DATETIME_FORMATS, FORECAST_STATIONS_LIST, FORECAST_STATION_CODES } from '@sdge-web-app/shared/dist/constants';
import moment, { Moment } from 'moment';
import { useStores } from '../../store';

const Table = styled.table`
  width: 100%;
  min-width: 82rem;
  table-layout: fixed;
  border-collapse: collapse;
  text-align: left;
  margin-top: 25px;
`;

const Header = styled.th`
  padding: 0.5rem;
  border-collapse: collapse;
`;

const Row = styled.tr`
  border-collapse: collapse;
`;

const Cell = styled.td`
  padding: 0.5rem;
  vertical-align: top;
  border-collapse: collapse;
  white-space: normal;
`;

function SevenDayForecast() {
  const { sevenDayForecastStore } = useStores();

  const calculateTimeBlockArray = () => {
    let timeArray: Array<Moment> = [];
    sevenDayForecastStore.data.forEach((entry) => {
      let found = timeArray.find(
        (timeBlock) =>
          moment(timeBlock).format(DATETIME_FORMATS.DATE_FORMAT) ===
          moment(entry.valid_time).format(DATETIME_FORMATS.DATE_FORMAT),
      );
      if (!found) {
        timeArray.push(moment(entry.valid_time));
      }
    });
    return timeArray;
  };

  const timeBlockArray: Array<Moment> = calculateTimeBlockArray();

  const formatTimeHeader = (timeBlock: Moment) => {
    return timeBlock.format(DATETIME_FORMATS.DATE_FORMAT);
  };

  const makeForecastRegionCells = (code: string, idx: number) => {
    return (
      <>
        {code !== 'CPO' ? (
          <Row key={`row-${code}-${idx}`}>
            <Cell>
              {FORECAST_STATIONS_LIST[code].area_label} ({code})
            </Cell>

            {timeBlockArray.map((timeBlock) => {
              let selectedData: any = sevenDayForecastStore.data.find((data: Forecast) => {
                if (
                  timeBlock.format(DATETIME_FORMATS.DATE_FORMAT) ===
                    moment(data.valid_time).format(DATETIME_FORMATS.DATE_FORMAT) &&
                  data.station === code
                ) {
                  return data;
                }
                return null;
              });
              return (
                <Cell key={`cell-${selectedData.id}`}>
                  LO: {selectedData.mint}
                  <br />
                  HI: {selectedData.maxt}
                </Cell>
              );
            })}
          </Row>
        ) : (
          <>
            <Row key={`row-bc-${idx}`}>
              <Cell>
                {FORECAST_STATIONS_LIST[`${code}_BC`].area_label} ({code})
              </Cell>

              {timeBlockArray.map((timeBlock) => {
                let selectedData: any = sevenDayForecastStore.data.find((data: Forecast) => {
                  if (
                    timeBlock.format(DATETIME_FORMATS.DATE_FORMAT) ===
                      moment(data.valid_time).format(DATETIME_FORMATS.DATE_FORMAT) &&
                    data.station === code
                  ) {
                    return data;
                  }
                  return null;
                });
                return <Cell key={`cell-${selectedData.id}`}>{selectedData.gust} mph</Cell>;
              })}
            </Row>
            <Row key={`row-pop-${idx}`}>
              <Cell>
                {FORECAST_STATIONS_LIST[`${code}_POP`].area_label} ({code})
              </Cell>

              {timeBlockArray.map((timeBlock) => {
                let selectedData: any = sevenDayForecastStore.data.find((data: Forecast) => {
                  if (
                    timeBlock.format(DATETIME_FORMATS.DATE_FORMAT) ===
                      moment(data.valid_time).format(DATETIME_FORMATS.DATE_FORMAT) &&
                    data.station === code
                  ) {
                    return data;
                  }
                  return null;
                });
                return <Cell key={`cell-${selectedData.id}`}>{selectedData.pop} %</Cell>;
              })}
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <div className="CurrentGusts">
      <h1>7 Day Forecast:</h1>
      <Table>
        <thead>
          <tr>
            <Header></Header>
            {timeBlockArray.map((timeBlock, idx) => (
              <Header key={`forecast-data-time-${idx}`}>{formatTimeHeader(timeBlock)}</Header>
            ))}
          </tr>
        </thead>
        <tbody>
          {sevenDayForecastStore.data &&
            FORECAST_STATION_CODES.map((code, idx) => {
              return (
                <React.Fragment key={`stationRow-${code}-${idx}`}>{makeForecastRegionCells(code, idx)}</React.Fragment>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default SevenDayForecast;
