import styled from 'styled-components';

import SafetyMessage from 'components/SafetyMessage';
import { ReactComponent as ArrowUp } from 'assets/svg/arrow_up.svg';
import { useState } from 'react';
import { useStores } from 'store';
import { SingleWidget } from 'components/Widgets/components/SingleWidget';
import { observer } from 'mobx-react-lite';

const Wrapper = styled.section`
  width: 33rem;
  height: 100%;
  max-height: 71rem;
  flex: 1;
  position: relative;
`;

const ExpandCollapseWrapper = styled.div<{ expanded: boolean }>`
  background-color: ${props => props.theme.background};
  width: 100%;
  height: ${({ expanded }) => (expanded ? '100%' : '13rem')};
  position: absolute;
  bottom: 0;
  border-radius: 2.5rem;
  transition: height 0.35s;
  overflow: hidden;
  pointer-events: visible;
  :after {
    display: ${({ expanded }) => (expanded ? 'block' : 'none')}
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 4em;
  }
`;

const FiveWidgetContainer = styled.div<{ expanded: boolean }>`
  height: ${({ expanded }) => (expanded ? 'calc(100% - 10rem)' : '100%')};
  width: ${({ expanded }) => (expanded ? '100%' : 'calc(100% - 2.25rem)')};
  scrollbar-color: ${props => props.theme.scrollbarColor};
  overflow-y: auto;
  padding-bottom: 1rem;
`;

const CollapseButton = styled.button<{ expanded: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ expanded }) => (expanded ? props => props.theme.safetyMessageToggle : props => props.theme.background)};
  border: none;
  border-left: 0.0625rem solid ${props => props.theme.borderColor};
  border-bottom: 0.0625rem solid ${props => props.theme.borderColor};
  border-radius: ${({ expanded }) => (expanded ? '0 2.5rem 0 0' : '0 2.5rem 2.5rem 0')};
  width: 2.75rem;
  ${({ expanded }) => (expanded ? 'height: 11rem;' : '')};
  padding: 0.8rem;
  cursor: pointer;
  z-index: 2;
  svg {
    width: 1.125rem;
    height: auto;
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'none')};
    path {
      stroke:${props => props.theme.foreground};
    }
  }
`;

export const WidgetNav = observer(() => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { widgetStore, mapStateStore } = useStores();

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Wrapper>
      <ExpandCollapseWrapper expanded={expanded}>
        <CollapseButton expanded={expanded} onClick={toggleExpanded}>
          <ArrowUp />
        </CollapseButton>
        {expanded && <SafetyMessage show={expanded} />}
        <FiveWidgetContainer expanded={expanded}>
          {expanded &&
            widgetStore.widgetList.map((widget) => <SingleWidget key={widget.name} widgetName={widget.name} />)}
          {!expanded && mapStateStore.currentWidgetName && (
            <SingleWidget key={mapStateStore.currentWidgetName} widgetName={mapStateStore.currentWidgetName} />
          )}
        </FiveWidgetContainer>
      </ExpandCollapseWrapper>
    </Wrapper>
  );
});
