import styled from 'styled-components';
import { useStores } from 'store';
import { WIDGET_DESCRIPTIONS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import CurrentTempInfographic from '../infographics/CurrentTempInforgraphic';
import CurrentRHInfographic from '../infographics/CurrentRHInforgraphic';
import PalInfographic from '../infographics/PalInforgraphic';
import SawtiInfographic from '../infographics/SawtiInfographic';
import WindGustInfographic from '../infographics/WindGustInfographic';
import LowTempInfographic from '../infographics/LowTempInfographic';
import PspsInfographic from '../infographics/PspsInfographic';
import TopGustsInfographic from '../infographics/TopGustsInfographic';
import RedFlagInfographic from '../infographics/RedFlagInfographic';
import FpiInfographic from '../infographics/FpiInfographic';
import OpiInfographic from '../infographics/OpiInfographic';
import ThunderstormInfographic from '../infographics/ThunderstormInfographic';
import RainfallInfographic from '../infographics/RainfallInfographic';
import SnowfallInfographic from '../infographics/SnowfallInfographic';
import WinterStormInfographic from '../infographics/WinterStormInfographic';
import SunInfographic from '../infographics/SunInfographic';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-y: auto;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  width: 100%;
  padding-bottom: 1rem;
`;

export default function TooltipOverlay() {
  const { overlayStore } = useStores();

  const returnWidgetInfographic = () => {
    switch (overlayStore.widgetTooltip) {
      case WIDGET_NAMES.LOW_TEMP:
        return <LowTempInfographic />;
      case WIDGET_NAMES.FPI:
        return <FpiInfographic />;
      case WIDGET_NAMES.OPI:
        return <OpiInfographic />;
      case WIDGET_NAMES.PAL:
        return <PalInfographic />;
      case WIDGET_NAMES.PSPS:
        return <PspsInfographic />;
      case WIDGET_NAMES.RAINFALL:
        return <RainfallInfographic />;
      case WIDGET_NAMES.RED_FLAG:
        return <RedFlagInfographic />;
      case WIDGET_NAMES.SAWTI:
        return <SawtiInfographic />;
      case WIDGET_NAMES.SNOWFALL:
        return <SnowfallInfographic />;
      case WIDGET_NAMES.SUN:
        return <SunInfographic />;
      case WIDGET_NAMES.THUNDERSTORM:
        return <ThunderstormInfographic />;
      case WIDGET_NAMES.WIND_GUST_FORECAST:
        return <WindGustInfographic />;
      case WIDGET_NAMES.WINTER_STORM:
        return <WinterStormInfographic />;
      case WIDGET_NAMES.CURRENT_TEMP:
        return <CurrentTempInfographic />;
      case WIDGET_NAMES.CURRENT_RH:
        return <CurrentRHInfographic />;
      case WIDGET_NAMES.TOP_CURRENT_GUSTS:
        return <TopGustsInfographic />;
      default:
        return <div>No data found.</div>;
    }
  };

  return (
    <Wrapper>
      <Content>
        {overlayStore.widgetTooltip && (
          <>
            <Title>{overlayStore.widgetTooltip}</Title>
            {WIDGET_DESCRIPTIONS[overlayStore.widgetTooltip]}
            {returnWidgetInfographic()}
          </>
        )}
      </Content>
    </Wrapper>
  );
}
