import { useEffect, useState } from 'react';
import { useStores } from 'store';
import { STREAMLINES_ENDPOINT } from '../constants';
import { getApiData } from '../utils/axios';

export default function useSteamlinesDataLoader(): {
  errors: unknown[];
} {
  const [errors, setErrors] = useState<unknown[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);
  const { windsStore } = useStores();

  useEffect(() => {
    if (dataRetreived) return;
    // Get Streamlines
    getApiData(STREAMLINES_ENDPOINT)
      .then((response) => {
        if (response.headers['content-type'].includes('application/json') && response.data.length === 2) {
          windsStore.setStreamlines(response.data);
        } else {
          throw new Error('Invalid wind streamline data');
        }
        setDataRetreived(true);
      })
      .catch((err) => {
        setDataRetreived(true);
        console.error(err);
        setErrors([...errors].concat(err));
      });
  }, [errors, windsStore, dataRetreived]);

  // Reload App data on interval
  useEffect(() => {
    const interval = setInterval(() => {
      setDataRetreived(false);
    }, 1000 * 60 * 10); // 10 minutes
    return () => clearInterval(interval);
  }, [dataRetreived]);

  return { errors };
}
