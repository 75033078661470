import { useEffect, useState } from 'react';
import { getApiData } from 'utils/axios';
import { AxiosResponse, AxiosError } from 'axios';
import moment from 'moment-timezone';

import { WEATHER_BRIEFING_ARCHIVE_ENDPOINT } from '../../constants';
import { WeatherBriefing as IWeatherBriefing } from '@sdge-web-app/shared/dist/types';
import { Loading, NotFound } from '../WeatherBriefingSearch';
import WeatherBriefing from 'components/WeatherBriefing';

export default function WeatherBriefingArchived({ date }: { date: string }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [weatherBriefing, setWeatherBriefing] = useState<IWeatherBriefing | null>(null);

  useEffect(() => {
    if (!date) return;

    setLoading(true);

    getApiData(`${WEATHER_BRIEFING_ARCHIVE_ENDPOINT}?date=${date}`)
      .then((response: AxiosResponse) => {
        setWeatherBriefing(response.data);
        setError(null);
      })
      .catch((error: AxiosError) => setError(error))
      .finally(() => {
        if (date === moment(localStorage.getItem('last_weather_brief')).format('YYYY-MM-DD')) {
          localStorage.setItem('new_brief', 'false');
        }
        setLoading(false);
      });
  }, [date]);

  if (!date) return null;

  if (loading) {
    return <Loading>Loading...</Loading>;
  }

  if (error || weatherBriefing == null) {
    return <NotFound>Unable to find a weather briefing for {moment(date).format('dddd, MMMM DD, YYYY')}.</NotFound>;
  }

  return <WeatherBriefing weatherBriefing={weatherBriefing} />;
}
