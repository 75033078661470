import { useStores } from 'store';
import { DistrictTemp } from '@sdge-web-app/shared/dist/types';
import { FORECAST_STATIONS_LIST, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { observer } from 'mobx-react-lite';
import { SolidDial } from '../components/SolidDial';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const Temperatures = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper className="Temperatures">
      {widgetStore.currentTempData &&
        widgetStore.currentTempData.districts.map((station: DistrictTemp) => (
          <DialWrapper key={`temps-${FORECAST_STATIONS_LIST[station.station].area_label}`}>
            <SolidDial widgetName={WIDGET_NAMES.CURRENT_TEMP} current={station.temp ?? 'N/A'} />
            <DialTitle>{FORECAST_STATIONS_LIST[station.station].area_label}</DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default Temperatures;
