import { PSPS_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from 'components/Map/pins/SolidPin';
import { DialTitle, DialWrapper } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { pspsLevelShort } from 'utils/widgetUtils';
import { Row, Wrapper } from './SharedStyles';

export default function PspsInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PSPS}
            current={PSPS_LEVELS.normal.long}
            innerText={pspsLevelShort(PSPS_LEVELS.normal.long)}
          />
          <DialTitle>{PSPS_LEVELS.normal.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PSPS}
            current={PSPS_LEVELS.watch.long}
            innerText={pspsLevelShort(PSPS_LEVELS.watch.long)}
          />
          <DialTitle>{PSPS_LEVELS.watch.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PSPS}
            current={PSPS_LEVELS.warning.long}
            innerText={pspsLevelShort(PSPS_LEVELS.warning.long)}
          />
          <DialTitle>{PSPS_LEVELS.warning.long}</DialTitle>
        </DialWrapper>
      </Row>
      Map indicators
      <Row>
        <SolidPin
          widgetName={WIDGET_NAMES.PSPS}
          current={PSPS_LEVELS.normal.long}
          innerText={pspsLevelShort(PSPS_LEVELS.normal.long)}
        />
        <SolidPin
          widgetName={WIDGET_NAMES.PSPS}
          current={PSPS_LEVELS.warning.long}
          innerText={pspsLevelShort(PSPS_LEVELS.warning.long)}
        />
      </Row>
    </Wrapper>
  );
}
