import { useState, useEffect } from 'react';
import { WINDS_TOP_MAX_ENDPOINT } from '../../../constants';
import axios from 'axios';
import { CurrentObservation } from '@sdge-web-app/shared/dist/types';
import moment from 'moment-timezone';

function MaxGusts() {
  const [maxGustData, setMaxGustData] = useState<CurrentObservation[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    setDataRetreived(true);
  }, []);

  // Make a request for the Max Gust data from the database
  if (!dataRetreived) {
    axios
      .get(WINDS_TOP_MAX_ENDPOINT)
      .then(function (response) {
        // handle success
        setMaxGustData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  return (
    <div className="MaxGusts">
      <h2>Top 5 Max Gusts:</h2>
      {maxGustData &&
        maxGustData.map((station: CurrentObservation) => (
          <div key={`maxGustData-${station.station}`}>
            <h3>Station: {station.common_name}</h3>
            <div>Station Identifier: {station.station}</div>
            <div>Max gust speed: {station.max_gust}</div>
            <div>Max gust direction: {station.max_gust_dir}</div>
            <div>Max gust time: {moment(station.max_gust_time).format()}</div>
          </div>
        ))}
    </div>
  );
}

export default MaxGusts;
