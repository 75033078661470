import { useEffect, useState } from 'react';
import { getApiData } from 'utils/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { WEATHER_BRIEFING_OLD_ARCHIVE_ENDPOINT } from '../../constants';
import { WeatherBriefingOld as IWeatherBriefingOld } from '@sdge-web-app/shared/dist/types';
import moment from 'moment-timezone';

import './styles.css';
import SafetyTalkingPoint from './components/SafetyTalkingPoint';
import ExecutiveSummary from './components/ExecutiveSummary';
import ForecastDiscussion from './components/ForecastDiscussion';
import Header from './components/Header';
import FireDiscussion from './components/FireDiscussion';
import FPI from './components/FPI';
import FPIOutlook from './components/FPIOutlook';
import SevenDayForecast from './components/SevenDayForecast';
import TideReport from './components/TideReport';
import Almanac from './components/Almanac';
import Warranty from './components/Warranty';
import Footer from './components/Footer';
import { Loading, NotFound } from '../WeatherBriefingSearch';

export default function WeatherBriefingArchivedOld({ date }: { date: string }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [weatherBriefing, setWeatherBriefing] = useState<IWeatherBriefingOld | null>(null);

  useEffect(() => {
    if (!date) return;

    setLoading(true);

    getApiData(`${WEATHER_BRIEFING_OLD_ARCHIVE_ENDPOINT}?date=${date}`)
      .then((response: AxiosResponse) => {
        setWeatherBriefing(response.data);
        setError(null);
      })
      .catch((error: AxiosError) => setError(error))
      .finally(() => setLoading(false));
  }, [date]);

  if (!date) return null;

  if (loading) {
    return <Loading>Loading...</Loading>;
  }

  if (error || weatherBriefing == null) {
    return <NotFound>Unable to find a weather briefing for {moment(date).format('dddd, MMMM DD, YYYY')}.</NotFound>;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <table
        className="body"
        data-made-with-foundation=""
        style={{
          borderSpacing: '0',
          borderCollapse: 'collapse',
          padding: '0',
          verticalAlign: 'top',
          textAlign: 'left',
          height: '100%',
          width: '100%',
        }}>
        <tbody>
          <tr
            style={{
              padding: '0',
              verticalAlign: 'top',
              textAlign: 'left',
            }}>
            <td
              className="center"
              align="center"
              valign="top"
              style={{
                wordWrap: 'break-word',

                hyphens: 'auto',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                borderCollapse: 'collapse',
              }}>
              <div
                data-parsed=""
                style={{
                  width: '100%',
                  minWidth: '580px',
                }}>
                <table
                  className="container text-center"
                  style={{
                    borderSpacing: '0',
                    borderCollapse: 'collapse',
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'center',
                    background: '#fefefe',
                    width: '800px',
                    margin: '0 auto',
                  }}>
                  <tbody>
                    <tr
                      style={{
                        padding: '0',
                        verticalAlign: 'top',
                        textAlign: 'left',
                      }}>
                      <td
                        style={{
                          wordWrap: 'break-word',

                          hyphens: 'auto',
                          padding: '0',
                          verticalAlign: 'top',
                          textAlign: 'left',
                          borderCollapse: 'collapse',
                        }}>
                        <Header reportDate={weatherBriefing.report_date} />
                        <SafetyTalkingPoint weatherBriefing={weatherBriefing} />
                        <ExecutiveSummary weatherBriefing={weatherBriefing} />
                        <ForecastDiscussion weatherBriefing={weatherBriefing} />
                        <FireDiscussion weatherBriefing={weatherBriefing} />
                        <FPI weatherBriefing={weatherBriefing} />
                        <FPIOutlook weatherBriefing={weatherBriefing} />
                        <SevenDayForecast weatherBriefing={weatherBriefing} />
                        <TideReport weatherBriefing={weatherBriefing} />
                        <Almanac weatherBriefing={weatherBriefing} />
                        <Warranty />
                        <Footer />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
