import { WeatherBriefingOld } from '@sdge-web-app/shared/dist/types';

export default function ForecastDiscussion({ weatherBriefing }: { weatherBriefing: WeatherBriefingOld }) {
  if (!weatherBriefing.data.forecast_discussion) return null;

  return (
    <table
      className="row row-forecast-discussion"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-12 columns first last"
            style={{
              padding: '75px 60px 75px',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '564px',
              paddingRight: '16px',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="padding-lr-5"
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}>
                    <div
                      className="section-header text-center"
                      style={{
                        textAlign: 'center',
                      }}>
                      <img
                        alt=""
                        src="/media/old_weather_briefings/forecast-discussion.png"
                        className="ibl"
                        style={{
                          outline: 'none',
                          textDecoration: 'none',

                          width: 'auto',
                          maxWidth: '100%',
                          clear: 'both',
                          display: 'inline-block',
                        }}
                      />
                      <h4
                        className="text-blue text-center forecast-section-header-title"
                        style={{
                          textAlign: 'center',
                          color: '#004286',
                          margin: '0 0 30px',
                          fontSize: '28px',
                          fontWeight: 'normal',
                        }}>
                        Forecast Discussion
                      </h4>
                    </div>
                    <div className="section-content">
                      <span
                        className="text-block forecast-discussion"
                        style={{
                          fontWeight: 'normal',
                        }}
                        dangerouslySetInnerHTML={{ __html: weatherBriefing.data.forecast_discussion }}
                      />
                    </div>
                  </th>
                  <th
                    className="expander"
                    style={{
                      visibility: 'hidden',
                      width: '0',
                      padding: '0 !important',
                    }}></th>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
