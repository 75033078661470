import { PSPS } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import { SolidDial } from '../components/SolidDial';
import { DATETIME_FORMATS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { pspsLevelShort } from 'utils/widgetUtils';
import moment from 'moment';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const PSPSContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper>
      {widgetStore.pspsData &&
        widgetStore.pspsData.map((element: PSPS) => (
          <DialWrapper key={`psps-${element.report_date}`}>
            <SolidDial
              widgetName={WIDGET_NAMES.PSPS}
              current={element.report_level}
              innerText={pspsLevelShort(element.report_level)}
            />
            <DialTitle>
              {moment(element.report_date).format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}
            </DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default PSPSContainer;
