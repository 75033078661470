import { Widget } from '@sdge-web-app/shared/dist/types';
import { useStores } from 'store';

function Widgets() {
  const { widgetStore } = useStores();

  return (
    <div className="Selected-Widgets">
      <h2>Selected Widgets:</h2>
      <ol>
        {widgetStore.widgetList &&
          widgetStore.widgetList.map((widget: Widget) => (
            <li key={`${widget.name}-${widget.userRole}`}>{widget.name}</li>
          ))}
      </ol>
    </div>
  );
}

export default Widgets;
