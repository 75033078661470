import { SAWTI_LEVEL_OBJS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from 'components/Map/pins/SolidPin';
import { DialTitle } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { sawtiLevelShort } from 'utils/widgetUtils';
import { Row, WideDialWrapper, Wrapper } from './SharedStyles';

export default function SawtiInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.none.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.none.long)}
          />
          <DialTitle>{SAWTI_LEVEL_OBJS.none.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.marginal.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.marginal.long)}
          />
          <DialTitle>{SAWTI_LEVEL_OBJS.marginal.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.moderate.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.moderate.long)}
          />
          <DialTitle>{SAWTI_LEVEL_OBJS.moderate.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.high.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.high.long)}
          />
          <DialTitle>{SAWTI_LEVEL_OBJS.high.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.extreme.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.extreme.long)}
          />
          <DialTitle>{SAWTI_LEVEL_OBJS.extreme.long}</DialTitle>
        </WideDialWrapper>
      </Row>
      Map indicators
      <Row>
        <SolidPin
          widgetName={WIDGET_NAMES.SAWTI}
          current={SAWTI_LEVEL_OBJS.marginal.long}
          innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.marginal.long)}
        />
        <SolidPin
          widgetName={WIDGET_NAMES.SAWTI}
          current={SAWTI_LEVEL_OBJS.extreme.long}
          innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.extreme.long)}
        />
      </Row>
    </Wrapper>
  );
}
