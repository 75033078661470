import styled from 'styled-components';
import { useState } from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment-timezone';

import { ReactComponent as ArrowUp } from 'assets/svg/arrow_up.svg';
import BottomButtons from './BottomButtons';
import TwitterAndWeatherCarousel from '../../TwitterAndWeatherCarousel';

const Wrapper = styled.section`
  width: 33rem;
  background-color: ${(props) => props.theme.backgroundBottomNav};
  border-radius: 2.5rem;
  overflow: hidden;
  pointer-events: visible;

  @media (min-width: 1920px) {
    margin-bottom: 0.5rem;
  }
`;

const MainSection = styled.div<{ drawerOpen: boolean }>`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ drawerOpen }) => (drawerOpen ? '1px' : '0')} solid ${(props) => props.theme.borderColor};
`;

const LogoWrapper = styled.div`
  margin-left: 1.75rem;
  padding-bottom: 2rem;

  svg {
    width: 5.75rem;
    height: auto;
  }
`;

const AppName = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${(props) => props.theme.foreground};
`;

const DateText = styled.div`
  font-size: 1.125rem;
  color: ${(props) => props.theme.foreground};
`;

const OpenCloseButton = styled.button<{ drawerOpen: boolean; isJumbo: boolean }>`
  appearance: none;
  background: ${(props) => props.theme.bottomNavToggle};
  border: none;
  padding: 0.8rem;
  height: 100%;
  cursor: pointer;
  width: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;

  border-left: 0.0625rem solid ${(props) => props.theme.borderColor};

  svg {
    path {
      stroke: ${(props) => props.theme.foreground};
    }

    width: 1.125rem;
    height: auto;
    transform: ${({ drawerOpen, isJumbo }) =>
      isJumbo ? (drawerOpen ? 'none' : 'rotate(180deg)') : drawerOpen ? 'rotate(180deg)' : 'none'};
  }
`;

const Drawer = styled.div<{ open: boolean }>`
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
  width: 100%;
  max-height: ${({ open }) => (open ? '44rem' : 0)};
  background-color: ${(props) => props.theme.background};
  transition: max-height 0.25s;
  overflow: hidden;
`;

export default function MainNav() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isJumbo = useMediaQuery({ query: '(min-width: 1920px)' });
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      <MainSection drawerOpen={drawerOpen}>
        <LogoWrapper>{themeContext.logo.render({ title: 'SDG&E Logo' })}</LogoWrapper>
        <div>
          <AppName>Weather Awareness System</AppName>
          <DateText>{moment().format('dddd, MMMM D')}</DateText>
        </div>
        <OpenCloseButton
          data-cy="main-menu-toggle"
          drawerOpen={drawerOpen}
          isJumbo={isJumbo}
          onClick={() => setDrawerOpen(!drawerOpen)}>
          <ArrowUp />
        </OpenCloseButton>
      </MainSection>
      <Drawer open={drawerOpen}>
        {drawerOpen && (
          <>
            <TwitterAndWeatherCarousel />
            <BottomButtons />
          </>
        )}
      </Drawer>
    </Wrapper>
  );
}
