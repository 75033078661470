import { Layer, Marker, Source } from 'react-map-gl';
import { useMemo } from 'react';
import geoJson from '../sources/red_flag.json';
import { RedFlagWarning } from '@sdge-web-app/shared/dist/types';
import { isFeatureCollection } from '../index';
import centerOfMass from '@turf/center-of-mass';
import * as turfHelper from '@turf/helpers';
import { COLORS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { redFlagLevelShort } from 'utils/widgetUtils';
import { PinWithTimes } from '../pins/PinWithTimes';
import moment from 'moment-timezone';

export default function RedFlagWarnings({ data }: { data?: RedFlagWarning[] }) {
  const processedData = useMemo(() => {
    if (data != null && isFeatureCollection(geoJson)) {
      const features = geoJson.features.map((feature) => {
        const rfw = data.find((rfw: RedFlagWarning) => rfw.zone === feature.properties?.title);

        if (feature.properties && rfw) {
          // @ts-ignore
          feature.properties.active = rfw.active ?? false;
          // @ts-ignore
          feature.properties.start = rfw.start_date;
          // @ts-ignore
          feature.properties.stop = rfw.end_date;

          let featurePolygon = turfHelper.polygon(feature.geometry.coordinates);
          // @ts-ignore
          feature.properties.center = centerOfMass(featurePolygon).geometry.coordinates;
        }

        return { ...feature, properties: { ...feature.properties } };
      });

      return { ...geoJson, features };
    } else {
      return null;
    }
  }, [data]);

  if (!isFeatureCollection(processedData)) return null;

  return (
    <>
      <Source type="geojson" data={processedData}>
        <Layer
          type="fill"
          paint={{
            'fill-color': ['case', ['boolean', ['get', 'active'], false], COLORS.energyRed, COLORS.slate],
            'fill-opacity': 0.7,
            'fill-outline-color': 'black',
          }}
          beforeId="settlement-minor-label"
        />
        <Layer
          type="fill"
          layout={{ 'fill-sort-key': 2 }}
          paint={{
            'fill-pattern': ['case', ['boolean', ['get', 'active'], false], 'lines_crossed', ''],
          }}
          beforeId="settlement-minor-label"
        />
      </Source>

      {processedData.features
        .filter((feature) => feature.properties.center.length === 0 && feature.properties.active)
        .map((feature) => (
          <Marker
            key={feature.properties.title}
            longitude={feature.properties.center[0]}
            latitude={feature.properties.center[1]}
            anchor="bottom">
            <PinWithTimes
              widgetName={WIDGET_NAMES.RED_FLAG}
              current={feature.properties.active}
              innerText={redFlagLevelShort(feature.properties.active)}
              start={moment(feature.properties.start).toDate()}
              stop={moment(feature.properties.stop).toDate()}
            />
          </Marker>
        ))}
    </>
  );
}
