import { FORECAST_STATIONS } from '@sdge-web-app/shared/dist/constants';
import { WindGustForecast } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const WindForecastWidgetWrapper = styled(WidgetWrapper)`
  flex-wrap: wrap;
`;

const SubHeader = styled.div`
  width: 100%;
  font-size: 0.85rem;
  padding: 0 0 0.5rem 1rem;
  color: ${(props) => props.theme.foreground};
`;

const Dials = styled(WidgetWrapper)`
  display: flex;
  overflow-y: auto;
  padding-bottom: 1rem;
`;

const WindGustForecastContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <>
      {widgetStore.windGustData &&
        widgetStore.windGustData.map((forecast: WindGustForecast) => {
          return (
            <WindForecastWidgetWrapper key={`windGustForecast-${forecast.id}`}>
              <SubHeader>Peak time - {forecast.peak_time}</SubHeader>
              <Dials>
                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.beach_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.beach_station.area_label}</DialTitle>
                </DialWrapper>
                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.mesas_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.mesas_station.area_label}</DialTitle>
                </DialWrapper>
                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.inland_valleys_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.inland_valleys_station.area_label}</DialTitle>
                </DialWrapper>
                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.foothills_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.foothills_station.area_label}</DialTitle>
                </DialWrapper>
                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.mountains_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.mountains_station.area_label}</DialTitle>
                </DialWrapper>

                <DialWrapper>
                  <SolidDial
                    withBorder
                    widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
                    current={forecast.desert_station.gust}
                  />
                  <DialTitle>{FORECAST_STATIONS.desert_station.area_label}</DialTitle>
                </DialWrapper>
              </Dials>
            </WindForecastWidgetWrapper>
          );
        })}
    </>
  );
});

export default WindGustForecastContainer;
