import { DistrictRH } from '@sdge-web-app/shared/dist/types';
import { FORECAST_STATIONS_LIST, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { useStores } from 'store';
import { observer } from 'mobx-react-lite';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';
import { SolidDial } from '../components/SolidDial';

const Humidity = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper className="Humidity">
      {widgetStore.currentRhData &&
        widgetStore.currentRhData.districts.map((station: DistrictRH) => (
          <DialWrapper key={`humidity-${station.station}`}>
            <SolidDial widgetName={WIDGET_NAMES.CURRENT_RH} current={station.rh} />
            <DialTitle>{FORECAST_STATIONS_LIST[station.station].area_label}</DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default Humidity;
