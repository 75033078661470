import { GradientDial } from 'components/Widgets/components/GradientDial';
import { Subtext, SubtextWrapper } from './SharedStyles';
import { BottomTriangle } from './Triangle';

type PinProps = {
  widgetName: string;
  current: string | number | boolean;
  min?: number;
  max?: number;
  innerText?: string;
  subText: string;
};

export function GradientPinWithSubtext({ widgetName, current, min, max, innerText, subText }: PinProps) {
  return (
    <div>
      <GradientDial large widgetName={widgetName} current={current} min={min} max={max} innerText={innerText} />
      <SubtextWrapper>
        <Subtext>{subText}</Subtext>
        {BottomTriangle(true)}
      </SubtextWrapper>
    </div>
  );
}
