import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';

import App from './App';
import reportWebVitals from './reportWebVitals';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// This only needs to be done once here.
moment.tz.setDefault(DATETIME_FORMATS.TIME_ZONE);

// register Swiper custom elements
register();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
