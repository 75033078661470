import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import RedFlagWarnings from 'components/Widgets/RedFlagWarnings';
import RainfallForecastContainer from 'components/Widgets/RainfallForecast';
import SnowfallForecastContainer from 'components/Widgets/SnowfallForecast';
import PSPSContainer from 'components/Widgets/PSPS';
import ProjectActivityLevelContainer from 'components/Widgets/ProjectActivityLevel';
import Fpi from 'components/Widgets/Fpi';
import Opi from 'components/Widgets/Opi';
import SawtiContainer from 'components/Widgets/Sawti';
import SunriseSunsetContainer from 'components/Widgets/SunriseSunset';
import ThunderstormThreatContainer from 'components/Widgets/ThunderstormThreats';
import WindGustForecastContainer from 'components/Widgets/WindGustForecast';
import WinterStormThreatContainer from 'components/Widgets/WinterStormThreat';
import LowTempForecastContainer from 'components/Widgets/LowTempForecast';
import Temperatures from 'components/Widgets/Temperatures';
import Humidity from 'components/Widgets/Humidity';
import TopWindGusts from '../TopWindGusts';
import ProgressBar from './ProgressBar';
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { useStores } from 'store';
import { useMediaQuery } from 'react-responsive';

const WidgetSelector = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;

  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 0.0625rem solid ${props => props.theme.borderColor};

  &:last-child {
    border: none;
  }
`;

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 0 1rem 0 0.25rem;
  cursor: pointer;
  width: 100%;
`;

const WidgetTitle = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  column-gap: 1rem;
  margin: 0.5rem 0;
  color: ${props => props.theme.foreground};
`;

const WidgetData = styled.div<{ hideData?: boolean }>`
  display: ${({ hideData }) => (hideData ? 'none' : 'block')};
  padding-bottom: 1rem;
  overflow-y: auto;
`;

type SingleWidgetProps = {
  widgetName: string;
  hideData?: boolean;
};

export const SingleWidget = observer(({ widgetName, hideData }: SingleWidgetProps) => {
  const { mapStateStore, overlayStore } = useStores();
  const isMobile = useMediaQuery({ query: '(max-width: 1140px)' });

  const createWidgetContent = () => {
    switch (widgetName) {
      case WIDGET_NAMES.LOW_TEMP:
        return <LowTempForecastContainer />;
      case WIDGET_NAMES.FPI:
        return <Fpi />;
      case WIDGET_NAMES.OPI:
        return <Opi />;
      case WIDGET_NAMES.PAL:
        return <ProjectActivityLevelContainer />;
      case WIDGET_NAMES.PSPS:
        return <PSPSContainer />;
      case WIDGET_NAMES.RAINFALL:
        return <RainfallForecastContainer />;
      case WIDGET_NAMES.RED_FLAG:
        return <RedFlagWarnings />;
      case WIDGET_NAMES.SAWTI:
        return <SawtiContainer />;
      case WIDGET_NAMES.SNOWFALL:
        return <SnowfallForecastContainer />;
      case WIDGET_NAMES.SUN:
        return <SunriseSunsetContainer />;
      case WIDGET_NAMES.THUNDERSTORM:
        return <ThunderstormThreatContainer />;
      case WIDGET_NAMES.WIND_GUST_FORECAST:
        return <WindGustForecastContainer />;
      case WIDGET_NAMES.WINTER_STORM:
        return <WinterStormThreatContainer />;
      case WIDGET_NAMES.CURRENT_TEMP:
        return <Temperatures />;
      case WIDGET_NAMES.CURRENT_RH:
        return <Humidity />;
      case WIDGET_NAMES.TOP_CURRENT_GUSTS:
        return <TopWindGusts />;
      default:
        return <div>No data found.</div>;
    }
  };

  return (
    <WidgetSelector>
      {!isMobile && <ProgressBar widgetName={widgetName} />}
      <Wrapper key={widgetName} onClick={() => mapStateStore.switchToWidget(widgetName)}>
        <WidgetTitle>
          <span>{widgetName}</span>
          <InfoIcon
            data-cy="widget-info"
            onClick={(e) => {
              e.stopPropagation();
              overlayStore.open('tooltip', widgetName);
            }}
          />
        </WidgetTitle>
        <WidgetData hideData={hideData}>{createWidgetContent()}</WidgetData>
      </Wrapper>
    </WidgetSelector>
  );
});
