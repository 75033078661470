// @ts-nocheck
import { DISTRICTS } from '@sdge-web-app/shared/dist/constants';

const importOrder = ['ME', 'RA', 'EA', 'NE', 'OC', 'NC', 'BC', 'CM'];

const customDistrictSort = importOrder.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index,
  };
}, {});

export const sortRegionArray = (arrayToSort: any[]) => {
  return arrayToSort.sort(
    (a, b) =>
      customDistrictSort[DISTRICTS[a.region].code.toUpperCase()] -
      customDistrictSort[DISTRICTS[b.region].code.toUpperCase()],
  );
};
