export default function Warranty() {
  return (
    <table
      className="row row-warranty"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-6 columns first text-middle has-logo"
            style={{
              verticalAlign: 'middle',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '297px',
              paddingRight: '8px',
              padding: '38px 0 67px',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="text-center"
                    style={{
                      textAlign: 'center',
                    }}>
                    <img
                      alt="SDG&E Logo"
                      src="/media/old_weather_briefings/logo-color.svg"
                      className="ibl"
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        width: 'auto',
                        maxWidth: '160px',
                        clear: 'both',
                        display: 'inline-block',
                      }}
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
          <th
            className="small-12 large-6 columns last text-small has-content"
            style={{
              margin: '0 auto',
              paddingLeft: '8px',
              paddingBottom: '16px',
              width: '274px',
              paddingRight: '16px',
              padding: '75px 60px 75px 0',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="padding-lr-5"
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}>
                    <p
                      style={{
                        margin: '0',
                        lineHeight: '24px',
                        marginBottom: '0',
                        fontSize: '12px',
                        fontWeight: 'normal',
                      }}>
                      Materials and information are provided "as is" without warranty of any kind, either express or
                      implied, including but not limited to the implied warranties of merchantability or fitness for a
                      particular purpose. In no event will San Diego Gas & Electric Company be liable to any party for
                      any direct, indirect, special or consequential damages for any use of the materials or information
                      provided herein, including without limitation, any claims or demands for lost profits or business
                      interruption, even if advised of the possibility of such damages.
                    </p>
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
