import styled from 'styled-components';
import moment from 'moment-timezone';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.briefingBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 70px 0;

  svg {
    width: 210px;
    height: auto;
    margin-right: 50px;
  }

  @media (max-width: 720px) {
    display: block;
    text-align: center;

    svg {
      width: 190px;
      margin: 0 0 20px;
    }
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.foreground};
  font-size: 25px;
  line-height: 1.3;
  margin: 0;
`;

const Date = styled.div`
  color: ${(props) => props.theme.foreground};
  font-size: 16px;
  font-weight: bold;
`;

export default function Header({ date }: { date: any }) {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      {themeContext.logo.render({ title: 'SDG&E Logo' })}
      <div>
        <Title>Weather Briefing</Title>
        <Date>{moment(date).format('dddd, MMMM DD, YYYY')}</Date>
      </div>
    </Wrapper>
  );
}
