import { Triangle } from './SharedStyles';

export const BottomTriangle = (hasSubtext?: boolean, hasOutline?: boolean): JSX.Element => {
  return (
    <Triangle hasSubtext={hasSubtext}>
      <svg
        id="Triangle"
        data-name="Triangle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 98.94 49.71"
        height={'1rem'}>
        <path
          className="cls-1"
          style={{ stroke: hasOutline ? 'gray' : 'unset', strokeWidth: hasOutline ? '4px' : 'unset' }}
          d="M.75.68c.48-.4,97,0,97,0l-48.01,48.32L.75.68Z"
        />
      </svg>
    </Triangle>
  );
};
