import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { ColumnSection, DialWrapper } from 'components/Widgets/components/SharedStyles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
  row-gap: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-column: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  column-gap: 1rem;
  padding-bottom: 0.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.lightGray};
  }
`;

export const ForecastTimes = styled(ColumnSection)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
`;

export const ForecastVolumes = styled(ColumnSection)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;
`;

export const WideDialWrapper = styled(DialWrapper)`
  width: 4.5rem;
`;
