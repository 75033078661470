import { RED_FLAG_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { DialTitle } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { redFlagLevelShort } from 'utils/widgetUtils';
import { Row, WideDialWrapper, Wrapper } from './SharedStyles';

export default function RedFlagInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <WideDialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.RED_FLAG} current={false} innerText={redFlagLevelShort(false)} />
          <DialTitle>{RED_FLAG_LEVELS.not_active.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial widgetName={WIDGET_NAMES.RED_FLAG} current={true} innerText={redFlagLevelShort(true)} />
          <DialTitle>{RED_FLAG_LEVELS.active.long}</DialTitle>
        </WideDialWrapper>
      </Row>
      Map indicators with region numbers
      <Row>
        <SolidPinWithSubtext
          widgetName={WIDGET_NAMES.RED_FLAG}
          current={false}
          innerText={redFlagLevelShort(false)}
          subText="243"
        />
        <SolidPinWithSubtext
          widgetName={WIDGET_NAMES.RED_FLAG}
          current={true}
          innerText={redFlagLevelShort(true)}
          subText="554"
        />
      </Row>
    </Wrapper>
  );
}
