import { Marker } from 'react-map-gl';
import { useMemo } from 'react';
import { useStores } from 'store';
import { StationRH } from '@sdge-web-app/shared/dist/types';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

export default function CurrentRH() {
  const { widgetStore } = useStores();
  const currentRHMarkers = useMemo(() => {
    const markers: StationRH[] = [];

    if (widgetStore.currentRhData) {
      widgetStore.currentRhData.stations.forEach((station: StationRH) => {
        markers.push(station);
      });
    }

    return markers;
  }, [widgetStore.currentRhData]);

  return (
    <>
      {currentRHMarkers.map((marker) => (
        <Marker key={marker.station} longitude={marker.lon} latitude={marker.lat} anchor="bottom">
          <SolidPinWithSubtext widgetName={WIDGET_NAMES.CURRENT_RH} current={marker.rh} subText={marker.common_name} />
        </Marker>
      ))}
    </>
  );
}
