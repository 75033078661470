import { Marker, Popup, useMap } from 'react-map-gl';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Camera as DBCamera } from '@sdge-web-app/shared/dist/types';

import 'swiper/swiper.min.css';

import { useStores } from '../../../store';

import closeX from 'assets/svg/close_x.svg';
import CameraPopupContent from './CameraPopupContent';

export const cameraDirections = ['north', 'northeast', 'east', 'southeast', 'south', 'southwest', 'west', 'northwest'];

export type Camera = DBCamera & {
  id: number;
  imageUrl: string;
  latitude: number;
  longitude: number;
};

const CameraMarker = styled.div<{ imageUrl: string }>`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
`;

const CameraPopup = styled(Popup)`
  width: 28rem;
  max-width: 80% !important;
  z-index: 11;

  .mapboxgl-popup-close-button {
    width: 2rem;
    height: 2rem;
    font-size: 0;
    background-image: url(${closeX});
    background-size: contain;
    top: 1rem;
    right: 1rem;
    z-index: 9999;

    &:hover {
      background-color: transparent;
      opacity: 0.6;
    }
  }

  .mapboxgl-popup-content {
    border-radius: 1.875rem;
    padding: 0;
    overflow: hidden;
  }

  .mapboxgl-popup-tip {
    border-width: 36px;
  }
`;

export default function Cameras() {
  const { current } = useMap();
  const { cameraDataStore } = useStores();
  const [cameraForPopup, setCameraForPopup] = useState<Camera | null>(null);

  const cameraMarkers = useMemo(() => {
    const markers: Camera[] = [];

    if (cameraDataStore.data != null) {
      for (const camera of cameraDataStore.data) {
        const direction = cameraDirections.find((direction) => camera[direction] != null);
        if (direction == null) continue;
        if (camera.id == null || camera.longitude == null || camera.latitude == null) continue;

        markers.push({
          ...camera,
          id: camera.id,
          imageUrl: camera[direction],
          longitude: camera.longitude,
          latitude: camera.latitude,
        });
      }
    }

    return markers;
  }, [cameraDataStore.data]);

  return (
    <>
      {cameraMarkers.map((marker) => (
        <Marker
          key={marker.id}
          longitude={marker.longitude}
          latitude={marker.latitude}
          anchor="center"
          style={{ zIndex: 10 }}>
          <CameraMarker
            imageUrl={marker.imageUrl}
            onClick={() => {
              setTimeout(() => {
                current?.jumpTo({ center: [marker.longitude, marker.latitude + 0.2] });
                setCameraForPopup(marker);
              }, 10); // This is needed so that the map will close a currently open popup first if there is one.
            }}
          />
        </Marker>
      ))}

      {cameraForPopup && (
        <CameraPopup
          longitude={cameraForPopup.longitude}
          latitude={cameraForPopup.latitude}
          anchor="bottom"
          onClose={() => setCameraForPopup(null)}>
          <CameraPopupContent camera={cameraForPopup} />
        </CameraPopup>
      )}
    </>
  );
}
