export default function Footer() {
  return (
    <>
      <table
        className="row row-footer"
        style={{
          borderSpacing: '0',
          borderCollapse: 'collapse',
          padding: '0',
          verticalAlign: 'top',
          textAlign: 'left',
          width: '100%',
          position: 'relative',
          display: 'table',
        }}>
        <tbody>
          <tr
            style={{
              padding: '0',
              verticalAlign: 'top',
              textAlign: 'left',
            }}>
            <th className="text-small">
              <p
                className="no-margin text-center"
                style={{
                  margin: '0',
                  lineHeight: '24px',
                  marginBottom: '0',
                  textAlign: 'center',
                  fontSize: '12px',
                }}>
                Any specific{' '}
                <a
                  href="https://sdgeweather.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light-blue"
                  style={{
                    lineHeight: '30px',
                    color: '#20B5FF',
                    marginBottom: '0',
                  }}>
                  questions or comments?
                </a>
              </p>
              <p
                className="no-margin text-center"
                style={{
                  margin: '0',
                  lineHeight: '24px',
                  marginBottom: '0',
                  textAlign: 'center',
                  fontSize: '12px',
                }}>
                <a
                  href="https://sdgeweather.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    lineHeight: '30px',
                  }}>
                  sdgeweather.com
                </a>
              </p>
              <p
                className="text-center"
                style={{
                  fontSize: '10px',
                  margin: '10px 0 0',
                  lineHeight: '24px',
                  marginBottom: '0',
                  textAlign: 'center',
                }}>
                SDG&E® and San Diego Gas & Electric® are registered trademarks of San Diego Gas & Electric Company.
              </p>
              <p
                className="text-center"
                style={{
                  fontSize: '10px',
                  margin: '10px 0 0',
                  lineHeight: '24px',
                  marginBottom: '0',
                  textAlign: 'center',
                }}>
                &copy; 1998-2019 San Diego Gas & Electric Company. The trademarks used herein are the property of their
                respective owners. All rights reserved.
              </p>
            </th>
          </tr>
        </tbody>
      </table>

      <table
        className="row row-horizontal-line"
        style={{
          borderSpacing: '0',
          borderCollapse: 'collapse',
          padding: '0',
          verticalAlign: 'top',
          textAlign: 'left',
          width: '100%',
          position: 'relative',
          display: 'table',
        }}>
        <tbody>
          <tr
            style={{
              padding: '0',
              verticalAlign: 'top',
              textAlign: 'left',
            }}>
            <th
              className="small-12 large-12 columns first last"
              style={{
                padding: '0 50px',
                margin: '0 auto',
                paddingLeft: '16px',
                paddingBottom: '16px',
                width: '564px',
                paddingRight: '16px',
              }}>
              <div
                className="section-separated-line"
                style={{
                  margin: '0',
                  borderBottom: '1px solid #D9D9D9',
                  marginTop: '35px',
                }}></div>
            </th>
          </tr>
        </tbody>
      </table>
    </>
  );
}
