import { Marker } from 'react-map-gl';
import { useMemo } from 'react';
import { useStores } from 'store';
import { LowTempForecast } from '@sdge-web-app/shared/dist/types';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

export default function LowTemps() {
  const { widgetStore } = useStores();
  const lowTempMarkers = useMemo(() => {
    const markers: LowTempForecast[] = [];

    if (widgetStore.lowTempData) {
      widgetStore.lowTempData.forEach((station: LowTempForecast) => {
        if (station.lon && station.lat) {
          markers.push(station);
        }
      });
    }

    return markers;
  }, [widgetStore.lowTempData]);

  return (
    <>
      {lowTempMarkers.map((marker) => (
        <Marker key={marker.code} longitude={marker.lon} latitude={marker.lat} anchor="bottom">
          <SolidPinWithSubtext widgetName={WIDGET_NAMES.LOW_TEMP} current={marker.mint} subText={marker.name} />
        </Marker>
      ))}
    </>
  );
}
