import { USER_ROLES, USER_TYPES } from '@sdge-web-app/shared/dist/constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useStores } from 'store';
import {
  CHECK_USER_AUTHENTICATED_ENDPOINT,
  FORECAST_ENDPOINT,
  WEATHER_BRIEFING_ENDPOINT,
  WIDGETS_ENDPOINT,
  CAMERA_ENDPOINT,
} from '../constants';
import { getAuthenticatedApiData, getApiData } from '../utils/axios';
import useWidgetDataLoader from './useWidgetDataLoader';
import { Camera } from '@sdge-web-app/shared/dist/types';
import useSteamlinesDataLoader from './useStreamlinesDataLoader';
import useRadarDataLoader from './useRadarDataLoader';

export default function useAppDataLoader(): {
  errors: unknown[];
} {
  const [errors, setErrors] = useState<unknown[]>([]);
  const [loadingUser, setLoadingUser] = useState(true);
  const [dataRetreived, setDataRetreived] = useState(false);
  const { sevenDayForecastStore, userStore, weatherBriefingStore, widgetStore, cameraDataStore } = useStores();

  // Load user
  useEffect(() => {
    if (!loadingUser) return;

    // Check server for session
    getAuthenticatedApiData(CHECK_USER_AUTHENTICATED_ENDPOINT)
      .then((response) => {
        userStore.setUser(response.data);
      })
      .catch((err) => {
        userStore.deleteUser();
        setErrors([...errors].concat(err));
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, [errors, loadingUser, userStore]);

  useEffect(() => {
    if (loadingUser || dataRetreived) return;

    const widgetType =
      userStore.userRole === USER_ROLES.ADMIN || userStore.userRole === USER_ROLES.VIEWER
        ? USER_TYPES.AUTHENTICATED
        : userStore.userRole;

    Promise.all([
      // Get Forecast data
      getApiData(FORECAST_ENDPOINT)
        .then((response) => {
          sevenDayForecastStore.setData(response.data);
        })
        .catch((err) => {
          console.error(err);
          setErrors([...errors].concat(err));
        }),

      // Get camera data
      getApiData(CAMERA_ENDPOINT)
        .then((response) => {
          cameraDataStore.setData(response.data.filter((camera: Camera) => camera.type === 'camera'));
        })
        .catch((err) => {
          console.error(err);
          setErrors([...errors].concat(err));
        }),

      // Get currently published weather brief
      getApiData(`${WEATHER_BRIEFING_ENDPOINT}/archive?current=true`)
        .then((response) => {
          weatherBriefingStore.setData(response.data);

          // Check for previous brief data in local storage
          let previousBrief = localStorage.getItem('last_weather_brief');
          let formattedDate = moment(response.data.report_date).format();
          // Compare previous brief date with current published brief and set the new brief flag if they don't match
          if (moment(previousBrief).format() !== formattedDate) {
            localStorage.setItem('last_weather_brief', formattedDate);
            localStorage.setItem('new_brief', 'true');
          }
        })
        .catch((err) => {
          weatherBriefingStore.setBriefLoadingEnd(); //
          console.error(err);
          setErrors([...errors].concat(err));
        }),

      // Check for new Widget List
      getAuthenticatedApiData(`${WIDGETS_ENDPOINT}/${widgetType}`)
        .then((response) => {
          widgetStore.setWidgetList(response.data);
        })
        .catch((err) => {
          console.error(err);
          setErrors([...errors].concat(err));
        }),
    ]).finally(() => {
      // console.log('I got new data!', new Date());
      setDataRetreived(true);
    });
  }, [
    errors,
    loadingUser,
    widgetStore,
    dataRetreived,
    sevenDayForecastStore,
    weatherBriefingStore,
    cameraDataStore,
    userStore.userRole,
  ]);

  // Reload App data on interval
  useEffect(() => {
    const interval = setInterval(() => {
      setDataRetreived(false);
    }, 1000 * 60); // 1 minute
    return () => clearInterval(interval);
  }, [dataRetreived]);

  // Refresh when user type changes
  useEffect(() => {
    setDataRetreived(false);
  }, [userStore.userRole]);

  useWidgetDataLoader(widgetStore.widgetList);
  useSteamlinesDataLoader();
  useRadarDataLoader();

  return { errors };
}
