import { ProjectActivityLevel } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { palLevelShort } from 'utils/widgetUtils';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const ProjectActivityLevelContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper>
      {widgetStore.palData &&
        widgetStore.palData.map((element: ProjectActivityLevel) => (
          <DialWrapper key={`pal-${element.zone_id}`}>
            <SolidDial widgetName={WIDGET_NAMES.PAL} current={element.color} innerText={palLevelShort(element.color)} />
            <DialTitle>{element.zone_id}</DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default ProjectActivityLevelContainer;
