import { Tide } from '@sdge-web-app/shared/dist/types';
import moment, { Moment } from 'moment-timezone';
import { SectionTitle, SectionSubtitle, SectionWithTable } from './shared';

interface Props {
  reportDate: Date | string;
  show: boolean;
  tides?: Tide[];
}

export default function TideReport({ reportDate, show, tides }: Props) {
  if (!show || tides == null) return null;

  const reportStartDate = moment(reportDate).startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 2; i++) {
    days.push(reportStartDate.clone().add(i, 'days'));
  }

  return (
    <SectionWithTable>
      <SectionTitle>Tide Report</SectionTitle>
      <SectionSubtitle>La Jolla, Scripps Pier, California</SectionSubtitle>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>High Tide</th>
            <th>Low Tide</th>
            <th>Water Temp</th>
          </tr>
        </thead>
        <tbody>
          {days.map((day) => {
            const tide = tides.find((t) => moment(t.date).startOf('day').toISOString() === day.toISOString());

            if (tide == null) return null;

            const firstHighTideTime = moment(tide.first_high_tide_time, 'HH:mm').format('hh:mm A');
            const secondHighTideTime = moment(tide.second_high_tide_time, 'HH:mm').format('hh:mm A');
            const firstLowTideTime = moment(tide.first_low_tide_time, 'HH:mm').format('hh:mm A');
            const secondLowTideTime = moment(tide.second_low_tide_time, 'HH:mm').format('hh:mm A');

            return (
              <tr key={day.toISOString()}>
                <td>{day.format('MMMM D')}</td>
                <td>
                  {tide.first_high_tide_value != null ? `${firstHighTideTime} (${tide.first_high_tide_value} ft.)` : ''}
                  <br />
                  {tide.second_high_tide_value != null
                    ? `${secondHighTideTime} (${tide.second_high_tide_value} ft.)`
                    : ''}
                </td>
                <td>
                  {tide.first_low_tide_value != null ? `${firstLowTideTime} (${tide.first_low_tide_value} ft.)` : ''}
                  <br />
                  {tide.second_low_tide_value != null ? `${secondLowTideTime} (${tide.second_low_tide_value} ft.)` : ''}
                </td>
                <td>{tide.water_temp !== '' && `${tide.water_temp}° F`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </SectionWithTable>
  );
}
