import styled from 'styled-components';
import ZoomInIcon from '../../assets/svg/zoom-in.svg';
import ZoomOutIcon from '../../assets/svg/zoom-out.svg';
import ArrowIcon from '../../assets/svg/arrow_up.svg';
import PauseIcon from '../../assets/svg/pause.svg';
import PlayIcon from '../../assets/svg/play.svg';

export default styled.div<{ isMobile: boolean; paused: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;

  .mapboxgl-ctrl-bottom-right {
    right: 35rem;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
    margin-bottom: 0.125rem;
    ${(props) => (props.theme.background !== 'white' ? 'filter: invert(100%)' : '')};
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(${ZoomInIcon});
    background-size: 80%;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
    ${(props) => (props.theme.background !== 'white' ? 'filter: invert(100%)' : '')};
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(${ZoomOutIcon});
    background-size: 80%;
  }

  .mapboxgl-ctrl-top-right {
    margin: ${(props) => (props.isMobile ? '5.5rem 0 0' : '0')};
  }

  .mapboxgl-ctrl-group {
    width: 1.75rem;
    margin: 0.5rem;
    border: none;
    border-radius: unset;
    box-shadow: none;
    background: none;

    button {
      width: 1.75rem;
      height: 1.75rem;
      border: none;
      background-color: white;
      border-radius: 0;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .camera-toggle-button {
    pointer-events: auto;
    margin: 0.5rem;
    z-index: 10;
    img {
      width: 1.75rem;
      background-color: ${(props) => props.theme.backgroundCircle};
      padding: 0.29rem;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.iconHoverColor};
      }
    }
  }

  .darkmode-toggle-button {
    pointer-events: auto;
    margin: 0.5rem;
    z-index: 10;
    img {
      width: 1.75rem;
      background-color: ${(props) => props.theme.backgroundCircle};
      padding: 0.45rem;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.iconHoverColor};
      }
    }
  }

  .mapboxgl-ctrl-top-left {
    position: relative;
    z-index: 10;
    margin: 5.5rem 0 0;

    .mobile-widget-nav-controls {
      display: flex;
      gap: 0.5rem;
      padding-top: 0.5rem;
      padding-left: 0.5rem;
    }

    button {
      appearance: none;
      border: none;
      pointer-events: all;
      font-size: 0;
      width: 1.75rem;
      height: 1.75rem;
      background-color: white;
      background-size: 60% 60%;
      background-position: center;

      &.prev-widget {
        background-image: url(${ArrowIcon});
        transform: rotate(-90deg);
      }

      &.play-pause {
        background-image: ${({ paused }) => (paused ? `url(${PlayIcon})` : `url(${PauseIcon})`)};
        background-size: ${({ paused }) => (paused ? '60% 60%' : '50% 50%')};
      }

      &.next-widget {
        background-image: url(${ArrowIcon});
        transform: rotate(90deg);
      }
    }
  }

  @media (min-width: 1141px) {
    .mapboxgl-ctrl-top-left {
      display: none;
    }
  }
`;
