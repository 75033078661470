import { WeatherBriefingOld } from '@sdge-web-app/shared/dist/types';

export default function TideReport({ weatherBriefing }: { weatherBriefing: WeatherBriefingOld }) {
  if (!weatherBriefing.includetidereport) return null;

  return (
    <table
      className="row row-tide-report"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-12 columns first last"
            style={{
              padding: '40px 50px',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '564px',
              paddingRight: '16px',
            }}>
            <table
              className=""
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th>
                    <div
                      className="section-header text-center"
                      style={{
                        textAlign: 'center',
                      }}>
                      <h4
                        className="text-blue text-center forecast-section-header-title"
                        style={{
                          textAlign: 'center',
                          color: '#004286',
                          margin: '0 0 30px',
                          fontSize: '28px',
                          fontWeight: 'normal',
                        }}>
                        Tide Report
                      </h4>
                      <div
                        className="text-center header-description"
                        style={{
                          textAlign: 'center',
                          marginBottom: '20px',
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }}>
                        LA JOLLA, SCRIPPS PIER, CALIFORNIA
                      </div>
                    </div>
                    <div className="section-content">
                      <div className="hidden-xs">
                        <table
                          className="main-table black-text"
                          style={{
                            borderSpacing: '0',
                            borderCollapse: 'collapse',
                            padding: '0',
                            verticalAlign: 'top',
                            textAlign: 'left',
                            width: '100%',
                          }}>
                          <thead>
                            <tr
                              style={{
                                padding: '0',
                                verticalAlign: 'top',
                                textAlign: 'left',
                                background: '#004285',
                              }}>
                              <th
                                style={{
                                  color: '#FFF',
                                  textAlign: 'center',
                                  padding: '20px 0',
                                  width: '12.5%',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}>
                                DATE
                              </th>
                              <th
                                style={{
                                  color: '#FFF',
                                  textAlign: 'center',
                                  padding: '20px 0',
                                  width: '12.5%',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}>
                                HIGH TIDE
                              </th>
                              <th
                                style={{
                                  color: '#FFF',
                                  textAlign: 'center',
                                  padding: '20px 0',
                                  width: '12.5%',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}>
                                LOW TIDE
                              </th>
                              <th
                                style={{
                                  color: '#FFF',
                                  textAlign: 'center',
                                  padding: '20px 0',
                                  width: '12.5%',
                                  fontSize: '12px',
                                  fontWeight: 'bold',
                                }}>
                                CURRENT WATER TEMP
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tbody-main">
                            {weatherBriefing.formattedTideReport &&
                              weatherBriefing.formattedTideReport.map((day) => (
                                <tr
                                  key={day.date}
                                  style={{
                                    padding: '0',
                                    verticalAlign: 'top',
                                    textAlign: 'left',
                                  }}>
                                  <td
                                    style={{
                                      wordWrap: 'break-word',

                                      hyphens: 'auto',
                                      padding: '20px 0',
                                      verticalAlign: 'middle',
                                      textAlign: 'center',
                                      color: '#333',
                                      fontWeight: 'bold',
                                      fontSize: '14px',
                                      borderBottom: '1px solid #E2E2E2',
                                      borderCollapse: 'collapse',
                                    }}>
                                    {day.date}
                                  </td>
                                  <td
                                    style={{
                                      wordWrap: 'break-word',

                                      hyphens: 'auto',
                                      padding: '20px 0',
                                      verticalAlign: 'middle',
                                      textAlign: 'center',
                                      color: '#333',
                                      fontWeight: 'bold',
                                      fontSize: '14px',
                                      borderBottom: '1px solid #E2E2E2',
                                      borderCollapse: 'collapse',
                                    }}>
                                    {day.highs.map((high) => (
                                      <span key={high.time}>
                                        {high.time} ({high.height} ft.)
                                        <br />
                                      </span>
                                    ))}
                                  </td>
                                  <td
                                    style={{
                                      wordWrap: 'break-word',

                                      hyphens: 'auto',
                                      padding: '20px 0',
                                      verticalAlign: 'middle',
                                      textAlign: 'center',
                                      color: '#333',
                                      fontWeight: 'bold',
                                      fontSize: '14px',
                                      borderBottom: '1px solid #E2E2E2',
                                      borderCollapse: 'collapse',
                                    }}>
                                    {day.lows.map((low) => (
                                      <span key={low.time}>
                                        {low.time} ({low.height} ft.)
                                        <br />
                                      </span>
                                    ))}
                                  </td>
                                  <td
                                    style={{
                                      wordWrap: 'break-word',

                                      hyphens: 'auto',
                                      padding: '20px 0',
                                      verticalAlign: 'middle',
                                      textAlign: 'center',
                                      color: '#333',
                                      fontWeight: 'bold',
                                      fontSize: '14px',
                                      borderBottom: '1px solid #E2E2E2',
                                      borderCollapse: 'collapse',
                                    }}>
                                    {day.waterTemp && (
                                      <>
                                        {day.waterTemp}
                                        <sup>o</sup> F
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="visible-xs"
                        style={{
                          display: 'none',
                          height: '0',
                          width: '0',
                          overflow: 'hidden',
                          margin: '0 !important',
                          padding: '0 !important',
                        }}>
                        {weatherBriefing.formattedTideReport &&
                          weatherBriefing.formattedTideReport.map((day) => (
                            <div key={day.date} className="subsection">
                              <p
                                className="headline text-center"
                                style={{
                                  margin: '20px 0 0',
                                  lineHeight: '30px',
                                  marginBottom: '30px',
                                  textAlign: 'center',
                                  color: '#004286',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                }}>
                                {day.date}
                              </p>
                              <table
                                style={{
                                  borderSpacing: '0',
                                  borderCollapse: 'collapse',
                                  padding: '0',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                  margin: '20px 0',
                                  width: '100%',
                                }}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <table
                                        className="row-as-table"
                                        style={{
                                          borderSpacing: '0',
                                          borderCollapse: 'collapse',
                                          padding: '0',
                                          verticalAlign: 'top',
                                          textAlign: 'left',
                                          marginTop: '20px',
                                          width: '100%',
                                        }}>
                                        <thead>
                                          <tr
                                            style={{
                                              padding: '0',
                                              verticalAlign: 'top',
                                              textAlign: 'left',
                                            }}>
                                            <th
                                              style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                color: '#004286',
                                              }}>
                                              HIGH TIDE
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {day.highs.map((high) => (
                                            <tr
                                              key={high.time}
                                              style={{
                                                padding: '0',
                                                verticalAlign: 'top',
                                                textAlign: 'left',
                                              }}>
                                              <td
                                                style={{
                                                  wordWrap: 'break-word',

                                                  hyphens: 'auto',
                                                  padding: '0',
                                                  verticalAlign: 'middle',
                                                  textAlign: 'center',
                                                  fontSize: '14px',
                                                  fontWeight: 'bold',
                                                  borderCollapse: 'collapse',
                                                }}>
                                                {high.time} ({high.height} ft.)
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <table
                                        className="row-as-table"
                                        style={{
                                          borderSpacing: '0',
                                          borderCollapse: 'collapse',
                                          padding: '0',
                                          verticalAlign: 'top',
                                          textAlign: 'left',
                                          marginTop: '20px',
                                          width: '100%',
                                        }}>
                                        <thead>
                                          <tr
                                            style={{
                                              padding: '0',
                                              verticalAlign: 'top',
                                              textAlign: 'left',
                                            }}>
                                            <th
                                              style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                color: '#004286',
                                              }}>
                                              LOW TIDE
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {day.lows.map((low) => (
                                            <tr
                                              key={low.time}
                                              style={{
                                                padding: '0',
                                                verticalAlign: 'top',
                                                textAlign: 'left',
                                              }}>
                                              <td
                                                style={{
                                                  wordWrap: 'break-word',

                                                  hyphens: 'auto',
                                                  padding: '0',
                                                  verticalAlign: 'middle',
                                                  textAlign: 'center',
                                                  fontSize: '14px',
                                                  fontWeight: 'bold',
                                                  borderCollapse: 'collapse',
                                                }}>
                                                {low.time} ({low.height} ft.)
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <p
                                className="headline text-center no-margin"
                                style={{
                                  margin: '20px 0 0',
                                  lineHeight: '30px',
                                  marginBottom: '30px',
                                  textAlign: 'center',
                                  color: '#004286',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                }}>
                                CURRENT WATER TEMP
                              </p>
                              <p
                                className="text-center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  margin: '0',
                                  lineHeight: '30px',
                                  marginBottom: '0',
                                  textAlign: 'center',
                                }}>
                                {day.waterTemp && (
                                  <>
                                    {day.waterTemp}
                                    <sup>o</sup>F
                                  </>
                                )}
                              </p>
                            </div>
                          ))}
                        <div
                          className="subsection-separated-line"
                          style={{
                            borderBottom: '1px solid #E2E2E2',
                            margin: '40px 0',
                          }}></div>
                      </div>
                    </div>
                  </th>
                  <th
                    className="expander"
                    style={{
                      visibility: 'hidden',
                      width: '0',
                      padding: '0 !important',
                    }}></th>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
