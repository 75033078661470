import styled from 'styled-components';

export const Circle = styled.div<{ large?: boolean; withBorder?: boolean }>`
  position: relative;
  font-family: Interstate Condensed;
  height: fit-content;
  width: ${({ large }) => (large ? 'clamp(4rem, 20vw, 8rem)' : '3.75rem')};

  svg {
    width: 100%;
    height: auto;
    margin: 0 auto;

    g path {
      fill: ${(props) => props.theme.backgroundCircle};
      stroke: ${({ withBorder }) => (withBorder ? '#666' : 'none')};
      stroke-width: 0.75px;
    }
  }
`;

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-around;
  width: 100%;
  font-family: Interstate Condensed;
  text-transform: uppercase;

  @media (max-width: 472px) {
    justify-content: flex-start;
  }
`;

export const DialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 3.75rem;
  color: ${(props) => props.theme.columnWrapper};
  font-size: 0.85rem;
  line-height: 1rem;
`;

export const DialTitle = styled.div`
  width: 100%;
`;

export const ColumnSection = styled.section`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.columnWrapper};
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 0 0.5rem;
  &:not(:last-child) {
    border-right: 0.0625rem solid #c4c4c4;
  }
`;

export const ColumnTitle = styled.div<{ noTextCaseChange?: boolean }>`
  width: 100%;
  font-weight: bold;
  padding-bottom: 0.25rem;
  font-size: 0.85rem;
  color: ${(props) => props.theme.foreground};
  text-transform: ${({ noTextCaseChange }) => (noTextCaseChange ? 'none' : 'inherit')};
`;

export const VolumeSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;
`;

export const VolumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: min-content;
`;

export const ListSection = styled.section`
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ListItem = styled.span`
  padding: 0 0.25rem;
  &:not(:last-child):after {
    content: ',';
  }
`;
