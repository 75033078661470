import styled from 'styled-components';
import { TIME_FRAMES, WINTER_STORM_STRENGTH_LIST, WINTER_STORM_THREATS } from '@sdge-web-app/shared/dist/constants';
import { ColumnTitle, ColumnWrapper, ListItem, ListSection } from 'components/Widgets/components/SharedStyles';
import { ForecastTimes, ForecastVolumes, Row, Wrapper } from './SharedStyles';

const StormDetails = styled(ForecastVolumes)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
`;

export default function WinterStormInfographic() {
  return (
    <Wrapper>
      Forecast timeframes
      <Row>
        <ForecastTimes>
          <ColumnWrapper>
            <ColumnTitle>Start</ColumnTitle>
            {TIME_FRAMES[5]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>End</ColumnTitle>
            {TIME_FRAMES[7]}
          </ColumnWrapper>
        </ForecastTimes>
      </Row>
      Forecast details
      <Row>
        <StormDetails>
          <ColumnWrapper>
            <ColumnTitle>Peak</ColumnTitle> {TIME_FRAMES[2]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Strength</ColumnTitle> {WINTER_STORM_STRENGTH_LIST[3]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Threats</ColumnTitle>
            <ListSection>
              <ListItem>{WINTER_STORM_THREATS[2]}</ListItem>
              <ListItem>{WINTER_STORM_THREATS[3]}</ListItem>
              <ListItem>{WINTER_STORM_THREATS[5]}</ListItem>
            </ListSection>
          </ColumnWrapper>
        </StormDetails>
      </Row>
    </Wrapper>
  );
}
