import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from 'assets/svg/arrow_up.svg';
import { useStores } from 'store';
import { SingleWidget } from 'components/Widgets/components/SingleWidget';
import { observer } from 'mobx-react-lite';
import ArrowIcon from 'assets/svg/arrow_up.svg';
import PauseIcon from 'assets/svg/pause.svg';
import PlayIcon from 'assets/svg/play.svg';

const Wrapper = styled.section<{ expanded: boolean }>`
  position: absolute;
  bottom: 5.25rem;
  left: 50%;
  transform: translate(-50%);
  background-color: ${props => props.theme.background};
  width: calc(100% - 1rem);
  max-width: 34rem;
  max-height: ${({ expanded }) => (expanded ? '14rem' : '4.5rem')};
  height: ${({ expanded }) => (expanded ? 'auto' : '4.5rem')};
  border-radius: 1.875rem;
  transition: max-height 200ms;
  overflow: hidden;
`;

const CollapseButton = styled.button<{ expanded: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.background};
  border: none;
  border-radius: ${({ expanded }) => (expanded ? '0 2.5rem 0 0' : '0 2.5rem 2.5rem 0')};
  width: 2.75rem;
  padding: 2.125rem 1rem 1rem;
  cursor: pointer;
  z-index: 2;
  svg {
    width: 1.125rem;
    height: auto;
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'none')};
  }
`;

const WidgetControlsContainer = styled.div`
  position: absolute;
  bottom: 4.5rem;
  left: 30%;
  transform: translate(-45%);
`;

const WidgetControls = styled.button<{ paused: boolean }>`
  appearance: none;
  border: none;
  pointer-events: all;
  font-size: 0;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${props => props.theme.background};
  background-size: 60% 60%;
  background-position: center;
  margin: 0 0.15rem;
  padding: 0.25rem;

  &.prev-widget {
    background-image: url(${ArrowIcon});
    transform: rotate(-90deg);
    border-radius: 5rem 0 0 5rem;
  }

  &.play-pause {
    background-image: ${({ paused }) => (paused ? `url(${PlayIcon})` : `url(${PauseIcon})`)};
    background-size: ${({ paused }) => (paused ? '60% 60%' : '50% 50%')};
    border-radius: 0 0 5rem 5rem;
  }

  &.next-widget {
    background-image: url(${ArrowIcon});
    transform: rotate(90deg);
    border-radius: 0 5rem 5rem 0;
  }
`;

export const WidgetNavMobile = observer(() => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { mapStateStore } = useStores();

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      <Wrapper expanded={expanded}>
        <CollapseButton expanded={expanded} onClick={toggleExpanded}>
          <ArrowUp />
        </CollapseButton>
        {mapStateStore.currentWidgetName && (
          <SingleWidget
            hideData={!expanded}
            key={mapStateStore.currentWidgetName}
            widgetName={mapStateStore.currentWidgetName}
          />
        )}
      </Wrapper>
      <WidgetControlsContainer>
        <WidgetControls
          paused={mapStateStore.paused}
          id="prev-widget"
          className="prev-widget"
          onClick={() => mapStateStore.prevWidget()}>
          Previous Widget
        </WidgetControls>
        <WidgetControls
          paused={mapStateStore.paused}
          id="play-pause"
          className="play-pause"
          onClick={() => mapStateStore.togglePlayOrPaused()}>
          Play/Pause
        </WidgetControls>
        <WidgetControls
          paused={mapStateStore.paused}
          id="next-widget"
          className="next-widget"
          onClick={() => mapStateStore.nextWidget()}>
          Next Widget
        </WidgetControls>
      </WidgetControlsContainer>
    </>
  );
});
