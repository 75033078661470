import { makeAutoObservable } from 'mobx';
import {
  ProjectActivityLevel,
  PSPS,
  RainfallForecast,
  RedFlagWarning,
  SnowfallForecast,
  Widget,
  OPIRegions,
  OPISevenDay,
  SAWTI,
  SunriseSunset,
  ThunderstormThreat,
  WindGustForecast,
  WinterStormThreat,
  LowTempForecast,
  TempObject,
  RHObject,
  WindsTopCurrent,
  FpiRegion,
  FpiSevenDay,
} from '@sdge-web-app/shared/dist/types';
import { sortWidgetRegionArray } from 'utils/widgetUtils';
import { FORECAST_STATIONS } from '@sdge-web-app/shared/dist/constants';

const widgetRegionSortOrder = ['beach', 'mesas', 'inland_orange_county', 'inland_valleys', 'foothills', 'mountains', 'desert'];
const widgetRegionCodeSortOrder = [
  FORECAST_STATIONS.beach_station.code,
  FORECAST_STATIONS.mesas_station.code,
  FORECAST_STATIONS.inland_valleys_station.code,
  FORECAST_STATIONS.foothills_station.code,
  FORECAST_STATIONS.mountains_station.code,
  FORECAST_STATIONS.desert_station.code,
  FORECAST_STATIONS.inland_orange_county_station.code
];

class WidgetStore {
  widgetList: Widget[] = [];

  lowTempData?: LowTempForecast[];
  fpiRegionData?: FpiRegion[];
  fpiSevenDayData?: FpiSevenDay[];
  fpiTimeOfDay?: string;
  snowfallData?: SnowfallForecast[];
  rainfallData?: RainfallForecast;
  redFlagData?: RedFlagWarning[];
  pspsData?: PSPS[];
  palData?: ProjectActivityLevel[];
  opiRegionData?: OPIRegions[];
  opiSevenDayData?: OPISevenDay[];
  sawtiData?: SAWTI[];
  sunData?: SunriseSunset[];
  thunderstormData?: ThunderstormThreat[];
  windGustData?: WindGustForecast[];
  winterStormData?: WinterStormThreat[];
  currentTempData?: TempObject;
  currentRhData?: RHObject;
  topWindGustData?: WindsTopCurrent[];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setWidgetList(widgetList: Widget[]) {
    this.widgetList = widgetList;
  }

  setLowTempData(data: LowTempForecast[]) {
    this.lowTempData = sortWidgetRegionArray(data, widgetRegionSortOrder, 'area');
  }

  setSnowfallData(data: SnowfallForecast[]) {
    this.snowfallData = data;
  }

  setRainfallData(data: RainfallForecast) {
    this.rainfallData = data;
  }

  setRedFlagData(data: RedFlagWarning[]) {
    this.redFlagData = data;
  }
  setPspsData(data: PSPS[]) {
    this.pspsData = data;
  }

  setPalData(data: ProjectActivityLevel[]) {
    this.palData = data;
  }

  setRegionFpiData(data: FpiRegion[]) {
    this.fpiRegionData = data;
  }
  setSevenDayFpiData(data: FpiSevenDay[]) {
    this.fpiSevenDayData = data;
  }
  setFpiTimeOfDay(data: string) {
    this.fpiTimeOfDay = data;
  }

  setRegionOpiData(data: OPIRegions[]) {
    this.opiRegionData = data;
  }

  setSevenDayOpiData(data: OPISevenDay[]) {
    this.opiSevenDayData = data;
  }

  setSawtiData(data: SAWTI[]) {
    this.sawtiData = data;
  }

  setSunData(data: SunriseSunset[]) {
    this.sunData = data;
  }

  setThunderstormData(data: ThunderstormThreat[]) {
    this.thunderstormData = data;
  }
  setWindGustData(data: WindGustForecast[]) {
    this.windGustData = sortWidgetRegionArray(data, widgetRegionSortOrder, 'common_name');
  }
  setWinterStormData(data: WinterStormThreat[]) {
    this.winterStormData = data;
  }

  setCurrentTempData(data: TempObject) {
    data.districts = sortWidgetRegionArray(data.districts, widgetRegionCodeSortOrder, 'station')
    this.currentTempData = data;
  }
  setCurrentRhData(data: RHObject) {
    data.districts = sortWidgetRegionArray(data.districts, widgetRegionCodeSortOrder, 'station')
    this.currentRhData = data;
  }

  setTopWindGustData(data: WindsTopCurrent[]) {
    this.topWindGustData = data;
  }
}

export default WidgetStore;
