import { CONFIG_KEYS } from '@sdge-web-app/shared/dist/constants';
import {
  SNOWFALL,
  WIDGETS,
  WIND_GUSTS,
  THUNDERSTORM_THREAT,
  RAINFALL,
  SAFETY_MESSAGE,
  SAWTI,
  PROJECT_ACTIVITY_LEVEL,
  FPI,
  OPI,
  AQI,
  LOGIN,
  LOGOUT,
  OBSERVATIONS,
  WINDS,
  WINDS_TOP_MAX,
  WINDS_TOP_CURRENT,
  WINDS_CURRENT_GUSTS,
  WINDS_FORECASTED_GUSTS,
  WINDS_STREAMLINES,
  TEMPERATURES,
  HUMIDITY,
  SUNRISE_SUNSET,
  TIDES,
  RED_FLAG,
  TWITTER,
  LOW_TEMP,
  FORECAST,
  WINTER_STORM_THREAT,
  PSPS,
  WEATHER_BRIEFING,
  CAMERAS,
  CONFIG,
} from '@sdge-web-app/shared/src/constants/paths';

const API_SERVER_URL = `${process.env.REACT_APP_API_SERVER_URL}/${process.env.REACT_APP_API_VERSION}`;
const ADMIN_TOOL_URL = process.env.REACT_APP_ADMIN_TOOL_URL;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const FPI_REGION_ENDPOINT = `${API_SERVER_URL}/${FPI}?type=region`;
const FPI_SEVEN_DAY_ENDPOINT = `${API_SERVER_URL}/${FPI}?type=seven-day`;
const FPI_TIME_OF_DAY_ENDPOINT = `${API_SERVER_URL}/${CONFIG}?keys=${CONFIG_KEYS.fpiTimeOFDay}`;
const OPI_REGION_ENDPOINT = `${API_SERVER_URL}/${OPI}?type=region`;
const OPI_SEVEN_DAY_ENDPOINT = `${API_SERVER_URL}/${OPI}?type=seven-day`;
const AQI_ENDPOINT = `${API_SERVER_URL}/${AQI}`;
const OBSERVATIONS_ENDPOINT = `${API_SERVER_URL}/${OBSERVATIONS}`;
const WINDS_ENDPOINT = `${API_SERVER_URL}/${WINDS}`;
const WINDS_CURRENT_GUSTS_ENDPOINT = `${API_SERVER_URL}/${WINDS_CURRENT_GUSTS}`;
const WINDS_FORECASTED_GUSTS_ENDPOINT = `${API_SERVER_URL}/${WINDS_FORECASTED_GUSTS}`;
const WINDS_TOP_MAX_ENDPOINT = `${API_SERVER_URL}/${WINDS_TOP_MAX}`;
const WINDS_TOP_CURRENT_ENDPOINT = `${API_SERVER_URL}/${WINDS_TOP_CURRENT}`;
const WIND_GUSTS_ENDPOINT = `${API_SERVER_URL}/${WIND_GUSTS}`;
const STREAMLINES_ENDPOINT = `${API_SERVER_URL}/${WINDS_STREAMLINES}`;
const TEMPERATURES_ENDPOINT = `${API_SERVER_URL}/${TEMPERATURES}`;
const LOW_TEMP_ENDPOINT = `${API_SERVER_URL}/${LOW_TEMP}`;
const HUMIDITY_ENDPOINT = `${API_SERVER_URL}/${HUMIDITY}`;
const SUNRISE_SUNSET_ENDPOINT = `${API_SERVER_URL}/${SUNRISE_SUNSET}`;
const TIDES_ENDPOINT = `${API_SERVER_URL}/${TIDES}`;
const RED_FLAG_WARNINGS_ENDPOINT = `${API_SERVER_URL}/${RED_FLAG}`;
const RAINFALL_FORECAST_ENDPOINT = `${API_SERVER_URL}/${RAINFALL}`;
const SNOWFALL_FORECAST_ENDPOINT = `${API_SERVER_URL}/${SNOWFALL}`;
const THUNDERSTORM_THREAT_ENDPOINT = `${API_SERVER_URL}/${THUNDERSTORM_THREAT}`;
const SAFETY_MESSAGE_ENDPOINT = `${API_SERVER_URL}/${SAFETY_MESSAGE}`;
const PROJECT_ACTIVITY_LEVEL_ENDPOINT = `${API_SERVER_URL}/${PROJECT_ACTIVITY_LEVEL}`;
const SAWTI_ENDPOINT = `${API_SERVER_URL}/${SAWTI}`;
const WIDGETS_ENDPOINT = `${API_SERVER_URL}/${WIDGETS}`;
const TWITTER_ENDPOINT = `${API_SERVER_URL}/${TWITTER}`;
const FORECAST_ENDPOINT = `${API_SERVER_URL}/${FORECAST}`;
const WINTER_STORM_ENDPOINT = `${API_SERVER_URL}/${WINTER_STORM_THREAT}`;
const PSPS_ENDPOINT = `${API_SERVER_URL}/${PSPS}`;
const WEATHER_BRIEFING_ENDPOINT = `${API_SERVER_URL}/${WEATHER_BRIEFING}`;
const WEATHER_BRIEFING_ARCHIVE_ENDPOINT = `${API_SERVER_URL}/${WEATHER_BRIEFING}/archive`;
const WEATHER_BRIEFING_OLD_ARCHIVE_ENDPOINT = `${API_SERVER_URL}/${WEATHER_BRIEFING}/archive/old`;
const CAMERA_ENDPOINT = `${API_SERVER_URL}/${CAMERAS}`;
const AUTHENTICATION_URL = `${API_SERVER_URL}/authenticate`;
const CHECK_USER_AUTHENTICATED_ENDPOINT = `${AUTHENTICATION_URL}/check`;
const LOGIN_ENDPOINT = `${AUTHENTICATION_URL}/${LOGIN}`;
const LOGOUT_ENDPOINT = `${AUTHENTICATION_URL}/${LOGOUT}`;
const CONFIG_ENDPOINT = `${API_SERVER_URL}/${CONFIG}`;

export {
  API_SERVER_URL,
  ADMIN_TOOL_URL,
  MAPBOX_ACCESS_TOKEN,
  FPI_REGION_ENDPOINT,
  FPI_SEVEN_DAY_ENDPOINT,
  FPI_TIME_OF_DAY_ENDPOINT,
  OPI_REGION_ENDPOINT,
  OPI_SEVEN_DAY_ENDPOINT,
  AQI_ENDPOINT,
  OBSERVATIONS_ENDPOINT,
  WINDS_ENDPOINT,
  WINDS_CURRENT_GUSTS_ENDPOINT,
  WINDS_FORECASTED_GUSTS_ENDPOINT,
  WINDS_TOP_MAX_ENDPOINT,
  WINDS_TOP_CURRENT_ENDPOINT,
  WIND_GUSTS_ENDPOINT,
  STREAMLINES_ENDPOINT,
  TEMPERATURES_ENDPOINT,
  LOW_TEMP_ENDPOINT,
  HUMIDITY_ENDPOINT,
  SUNRISE_SUNSET_ENDPOINT,
  TIDES_ENDPOINT,
  RED_FLAG_WARNINGS_ENDPOINT,
  RAINFALL_FORECAST_ENDPOINT,
  SNOWFALL_FORECAST_ENDPOINT,
  SAWTI_ENDPOINT,
  THUNDERSTORM_THREAT_ENDPOINT,
  SAFETY_MESSAGE_ENDPOINT,
  PROJECT_ACTIVITY_LEVEL_ENDPOINT,
  WIDGETS_ENDPOINT,
  TWITTER_ENDPOINT,
  FORECAST_ENDPOINT,
  WINTER_STORM_ENDPOINT,
  PSPS_ENDPOINT,
  WEATHER_BRIEFING_ENDPOINT,
  WEATHER_BRIEFING_ARCHIVE_ENDPOINT,
  WEATHER_BRIEFING_OLD_ARCHIVE_ENDPOINT,
  CAMERA_ENDPOINT,
  CHECK_USER_AUTHENTICATED_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  CONFIG_ENDPOINT,
};
