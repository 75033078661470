import styled from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';

const SectionTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 25px;
  text-align: center;
  color: ${props => props.theme.foreground};
`;

const SectionSubtitle = styled.h3`
  margin: 10px 0 40px;
  font-size: 16px;
  font-family: 'Interstate Condensed';
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.foreground};
`;

const SectionWithTable = styled.div`
  margin: 50px 0;
  border-bottom: 1px solid ${COLORS.disabled};
  padding-bottom: 40px;

  h2 {
    color: ${props => props.theme.foreground};
  }

  table {
    width: 100%;
    text-align: center;
    font-family: 'Interstate Condensed';
    font-size: 14px;
    margin-bottom: 20px;

    tr {
      border-bottom: 1px solid #ccc;
      vertical-align: top;
    }

    tr:last-child {
      border: none;
    }

    td {
      padding: 14px 0 20px;
      color: ${props => props.theme.foreground};
    }

    th {
      text-transform: uppercase;
      color: ${props => props.theme.foreground};
    }
  }
`;

export { SectionTitle, SectionSubtitle, SectionWithTable };
