import styled from 'styled-components';
import { WeatherBriefing as IWeatherBriefing } from '@sdge-web-app/shared/dist/types';
import Header from './components/Header';
import RichTextSection from './components/RichTextSection';
import { ReactComponent as SpeechBubble } from './components/svg/speech_bubble.svg';
import { ReactComponent as List } from './components/svg/list.svg';
import { ReactComponent as Discussion } from './components/svg/discussion.svg';
import { ReactComponent as Fire } from './components/svg/fire.svg';
import { ReactComponent as Thermometer } from './components/svg/thermometer.svg';
import TodaysFPI from './components/TodaysFPI';
import SevenDayFPI from './components/SevenDayFPI';
import SevenDayWeatherForecast from './components/SevenDayWeatherForecast';
import TideReport from './components/TideReport';
import SunriseSunset from './components/SunriseSunset';
import Warranty from './components/Warranty';
import Footer from './components/Footer';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const SectionsWrapper = styled.section`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;

  @media (max-width: 720px) {
    padding: 0 20px;
    margin: 0;
    max-width: 100%;
  }
`;

const hasContent = (sectionData: Record<string, any>) => {
  const multipleNodes = sectionData.content.length > 1;
  const firstNodeExistsWithContent = sectionData.content[0] && sectionData.content[0].content.length > 0;
  return multipleNodes || firstNodeExistsWithContent;
};

export default function WeatherBriefing({ weatherBriefing }: { weatherBriefing: IWeatherBriefing }) {
  const themeContext = useContext(ThemeContext)
  document.body.setAttribute("style", `background:${themeContext.background}`);

  return (
    <div>
      <Header date={weatherBriefing.report_date} />
      <SectionsWrapper>
        {/* Only show sections that actually have content in them. */}
        {hasContent(weatherBriefing.safety_point) && (
          <RichTextSection
            title="Safety Talking Point"
            content={weatherBriefing.safety_point}
            IconComponent={SpeechBubble}
          />
        )}
        {hasContent(weatherBriefing.executive_summ) && (
          <RichTextSection title="Executive Summary" content={weatherBriefing.executive_summ} IconComponent={List} />
        )}
        {hasContent(weatherBriefing.forecast_discussion) && (
          <RichTextSection
            title="Forecast Discussion"
            content={weatherBriefing.forecast_discussion}
            IconComponent={Discussion}
          />
        )}
        {hasContent(weatherBriefing.fire_discussion) && (
          <RichTextSection title="Fire Discussion" content={weatherBriefing.fire_discussion} IconComponent={Fire} />
        )}
        {hasContent(weatherBriefing.heat_index_discussion) && (
          <RichTextSection
            title="Heat Index Warning"
            content={weatherBriefing.heat_index_discussion}
            IconComponent={Thermometer}
          />
        )}
        <TodaysFPI show={weatherBriefing.include_fpi} todaysFPI={weatherBriefing.data.fpiTodayByRegion} />
        <SevenDayFPI
          reportDate={weatherBriefing.report_date}
          show={weatherBriefing.include_7day_fpi}
          sevenDayFPI={weatherBriefing.data.sevenDayFPI}
        />
        <SevenDayWeatherForecast
          reportDate={weatherBriefing.report_date}
          show={weatherBriefing.include_forecast}
          forecast={weatherBriefing.data.forecast}
        />
        <TideReport
          reportDate={weatherBriefing.report_date}
          show={weatherBriefing.include_tide_report}
          tides={weatherBriefing.data.tides}
        />
        <SunriseSunset
          reportDate={weatherBriefing.report_date}
          show={weatherBriefing.include_sunrise_sunset}
          sunriseSunset={weatherBriefing.data.sunriseSunset}
        />
        <Warranty />
        <Footer />
      </SectionsWrapper>
    </div>
  );
}
