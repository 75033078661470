import React from 'react';
import moment, { Moment } from 'moment-timezone';
import styled from 'styled-components';
import { Forecast, Station } from '@sdge-web-app/shared/dist/types';
import WeatherConditionIcon from 'components/WeatherBriefing/components/WeatherConditionIcon';

const Wrapper = styled.div`
  h2 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
    color: ${props => props.theme.foreground};
  }

  table {
    width: 100%;
    text-align: center;
    font-family: 'Interstate Condensed';
    color: ${props => props.theme.forecastSlideText};

    thead tr {
      text-transform: uppercase;
    }

    th {
      font-weight: normal;
      color: ${props => props.theme.foreground};
    }

    td {
      line-height: 1.2;
    }
  }
`;

const ConditionCell = styled.td`
  line-height: 1.2;

  img {
    width: 100%;
    max-width: 2.5rem;
    height: auto;
    padding: 0 4px 4px 4px;
  }
`;

export enum ForecastType {
  'Temp',
  'Precipitation',
  'Wind',
}

interface Props {
  data: Forecast[];
  type: ForecastType;
  station?: Station;
}

export default function ForecastSlide({ data, type, station }: Props) {
  const today = moment().startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 7; i++) {
    days.push(today.clone().add(i, 'days'));
  }

  return (
    <Wrapper>
      {type === ForecastType.Temp && <h2>{station?.area_label}</h2>}
      {type === ForecastType.Wind && <h2>Backcountry Winds</h2>}
      {type === ForecastType.Precipitation && <h2>Chance of Precipitation</h2>}
      <table>
        <thead>
          <tr>
            {days.map((day, i) => (
              <th key={day.toISOString()}>{i === 0 ? 'Today' : day.format('ddd').toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {type !== ForecastType.Wind && type !== ForecastType.Precipitation && (
            <tr>
              {days.map((day) => {
                const condition = data.find(
                  (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
                );

                return condition != null ? (
                  <ConditionCell key={day.toISOString()}>
                    <WeatherConditionIcon condition={condition.expected_weather_conditions} />
                  </ConditionCell>
                ) : (
                  <td key={day.toISOString()}></td>
                );
              })}
            </tr>
          )}
          {type === ForecastType.Temp && (
            <tr>
              {days.map((day) => {
                const areaForecast = data.find(
                  (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
                );

                return areaForecast != null ? (
                  <td key={day.toISOString()}>
                    {areaForecast.mint}&deg;
                    <br />
                    {areaForecast.maxt}&deg;
                  </td>
                ) : (
                  <td key={day.toISOString()}></td>
                );
              })}
            </tr>
          )}
          {type === ForecastType.Wind && (
            <tr>
              {days.map((day) => {
                const windForecast = data.find(
                  (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
                );

                return <td key={day.toISOString()}>{windForecast?.gust} mph</td>;
              })}
            </tr>
          )}
          {type === ForecastType.Precipitation && (
            <tr>
              {days.map((day) => {
                const windForecast = data.find(
                  (f) => day.toISOString() === moment(f.valid_time).startOf('day').toISOString(),
                );

                return <td key={day.toISOString()}>{windForecast?.pop != null ? `${windForecast?.pop}%` : ''}</td>;
              })}
            </tr>
          )}
        </tbody>
      </table>
    </Wrapper>
  );
}
