import moment from 'moment-timezone';
import { DATETIME_FORMATS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { useStores } from 'store';
import { observer } from 'mobx-react-lite';
import { SolidDial } from '../components/SolidDial';
import { FpiSevenDay } from '@sdge-web-app/shared/dist/types';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const Fpi = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper>
      {widgetStore.fpiSevenDayData &&
        widgetStore.fpiSevenDayData.map((element: FpiSevenDay, idx: number) => {
          if (idx === 0) {
            return (
              <DialWrapper key={`fpi-${moment(element.valid_time).format()}`}>
                <SolidDial widgetName={WIDGET_NAMES.FPI} current={element.fpi_max} innerText={`${element.fpi_max}`} />
                <DialTitle>TODAY</DialTitle>
              </DialWrapper>
            );
          } else {
            return (
              <DialWrapper key={`fpi-${moment(element.valid_time).format()}`}>
                <SolidDial widgetName={WIDGET_NAMES.FPI} current={element.fpi_max} innerText={`${element.fpi_max}`} />
                <DialTitle>
                  {moment(element.valid_time).format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}
                </DialTitle>
              </DialWrapper>
            );
          }
        })}
    </WidgetWrapper>
  );
});

export default Fpi;
