import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { Row, Wrapper } from './SharedStyles';

export default function WindGustInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <SolidDial widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} withBorder current={15} />
        <SolidDial widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} withBorder current={30} />
        <SolidDial widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} withBorder current={50} />
      </Row>
      Map indicators with station names
      <Row>
        <SolidPinWithSubtext widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} withBorder current={20} subText="Crestwood" />
        <SolidPinWithSubtext
          widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
          withBorder
          current={50}
          subText="Imperial Valley"
        />
      </Row>
    </Wrapper>
  );
}
