import styled from 'styled-components';

export const SubtextWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Subtext = styled.div<{ hasOutline?: boolean }>`
  position: relative;
  bottom: var(--pin-subtext-bottom-spacing);
  background: ${(props) => props.theme.mapPinBackground};
  color: ${(props) => props.theme.foreground};
  padding: 0.3rem 0.5rem;
  font-family: Interstate Condensed;
  text-transform: uppercase;
  text-align: center;
  font-size: var(--pin-subtext-font-size);
  line-height: 1rem;
  border-radius: 0.25rem;
  border: ${(props) => (props.hasOutline ? '1px solid grey' : 'none')};
  max-width: 9rem;
  min-width: 1.85rem;
  z-index: 2;
`;

export const Triangle = styled.div<{ hasSubtext?: boolean }>`
  position: absolute;
  top: ${(props) => (props.hasSubtext ? 'var(--pin-triangle-subtext-bottom-spacing)' : '-2.1rem')};
  z-index: 1;
  svg {
    path {
      fill: ${(props) => props.theme.backgroundCircle};
    }
  }
  @media only screen and (max-width: 600px) {
    top: ${(props) => (props.hasSubtext ? 'var(--pin-triangle-subtext-bottom-spacing)' : '-1.95rem')};
  }
  @media only screen and (max-width: 480px) {
    top: ${(props) => (props.hasSubtext ? 'var(--pin-triangle-subtext-bottom-spacing)' : '-1.75rem')};
  }
`;
