import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';
import { ReactComponent as Play } from 'assets/svg/play.svg';
import { ReactComponent as Pause } from 'assets/svg/pause.svg';

const PauseHover = styled.button<{ paused: boolean }>`
  width: 1rem;
  visibility: hidden;
  position: absolute;
  display: ${({ paused }) => (paused ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  border: none;
  padding: 0;
  background: ${(props) => props.theme.hoverColor};
  stroke: ${(props) => props.theme.foreground};
  fill: ${(props) => props.theme.foreground};

  line {
    stroke: ${(props) => props.theme.foreground};
  }

  height: 100%;
  z-index: 2;
  cursor: pointer;
`;

const Wrapper = styled.div<{ paused: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 0.75rem;
  height: 100%;
  cursor: pointer;
  background-color: ${(props) => props.theme.background};
  display: flex;
  align-content: center;
  &:hover > ${PauseHover} {
    visibility: ${({ paused }) => (paused ? 'initial' : 'visible')};
  }
`;

const Bar = styled.div`
  width: 0.25rem;
  background-color: ${(props) => props.theme.foreground};
`;

const PlayPaused = styled.button<{ paused: boolean }>`
  width: 1rem;
  display: ${({ paused }) => (paused ? 'flex' : 'none')};
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  border: none;
  padding: 0;
  background: ${(props) => props.theme.hoverColor};
  stroke: ${(props) => props.theme.foreground};
  fill: ${(props) => props.theme.foreground};

  path {
    fill: ${(props) => props.theme.foreground};
  }

  height: 100%;
  z-index: 1;
  cursor: pointer;
`;

const ProgressBar = observer(({ widgetName }: { widgetName: string }) => {
  const { mapStateStore } = useStores();
  const progress = mapStateStore.currentWidgetName === widgetName ? mapStateStore.progress : 0;

  return (
    <Wrapper onClick={mapStateStore.togglePlayOrPaused} paused={mapStateStore.paused}>
      <PauseHover paused={mapStateStore.paused}>
        <Pause />
      </PauseHover>
      <PlayPaused paused={mapStateStore.paused && mapStateStore.currentWidgetName === widgetName}>
        <Play />
      </PlayPaused>
      <Bar
        style={{
          height: progress * 100 + '%',
          transition: progress === 0 ? 'none' : 'height 1s linear',
        }}
      />
    </Wrapper>
  );
});

export default ProgressBar;
