import { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper as ISwiper } from 'swiper';
import styled from 'styled-components';
import { FORECAST_STATIONS_LIST } from '@sdge-web-app/shared/dist/constants';

import 'swiper/swiper.min.css';

import CarouselWithProgressDots from '../CarouselWithProgressDots';
import ForecastSlide, { ForecastType } from 'components/TwitterAndWeatherCarousel/ForecastSlide';
import { useStores } from '../../store';

const Wrapper = styled.div`
  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 1.875rem;
  width: 95%;
  max-width: 30rem;
  padding: 0 1.5rem 1rem;
`;

const stations = [
  FORECAST_STATIONS_LIST.SOB,
  FORECAST_STATIONS_LIST.MVN,
  FORECAST_STATIONS_LIST.MPE,
  FORECAST_STATIONS_LIST.CRI,
  FORECAST_STATIONS_LIST.ECJ,
  FORECAST_STATIONS_LIST.RAM,
  FORECAST_STATIONS_LIST.JUL,
  FORECAST_STATIONS_LIST.BRG,
];

const autoplayIntervalMs = 10000;

export default function WeatherCarouselMobile() {
  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const autoplayTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const { sevenDayForecastStore } = useStores();

  const intervalHandler = useCallback(() => {
    if (swiper) swiper.slideNext();
  }, [swiper]);

  const removeInterval = useCallback(() => {
    if (autoplayTimerRef.current) {
      clearInterval(autoplayTimerRef.current);
    }
  }, []);

  const startInterval = useCallback(() => {
    autoplayTimerRef.current = setInterval(intervalHandler, autoplayIntervalMs);
  }, [intervalHandler]);

  useEffect(() => {
    startInterval();

    return removeInterval;
  }, [startInterval, removeInterval]);

  const campoForecasts = sevenDayForecastStore.data.filter((forecast) => forecast.station === 'CPO');

  if (sevenDayForecastStore.data.length === 0) return null;

  return (
    <Wrapper>
      <CarouselWithProgressDots
        uniqueId="weather-mobile"
        setSwiperWhenLoaded={setSwiper}
        isPaused={false}
        autoplayIntervalMs={autoplayIntervalMs}>
        {stations.map((station) => {
          const forecast = sevenDayForecastStore.data.filter((forecast) => forecast.station === station.code);

          return forecast.length > 0 ? (
            <ForecastSlide key={`temp-${station.code}`} data={forecast} type={ForecastType.Temp} station={station} />
          ) : null;
        })}
        {campoForecasts.length > 0 && <ForecastSlide key="winds" data={campoForecasts} type={ForecastType.Wind} />}
        {campoForecasts.length > 0 && (
          <ForecastSlide key="precipitation" data={campoForecasts} type={ForecastType.Precipitation} />
        )}
      </CarouselWithProgressDots>
    </Wrapper>
  );
}
