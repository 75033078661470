import Map from 'components/Map';
import Nav from 'components/Nav';
import Overlay from 'components/Overlay';

export default function Home() {
  return (
    <>
        <Map />
        <Nav />
        <Overlay />
    </>
  );
}

