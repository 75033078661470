import { makeAutoObservable } from 'mobx';
import { StationGustData, WindsCurrentGusts } from '@sdge-web-app/shared/dist/types';

class WindStore {
  currentGusts: WindsCurrentGusts[] = [];
  forecastedGusts: StationGustData[] = [];
  streamlines?: JSON;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setCurrentGusts(currentGusts: WindsCurrentGusts[]) {
    this.currentGusts = currentGusts;
  }

  setForecastedGusts(forecastedGusts: StationGustData[]) {
    this.forecastedGusts = forecastedGusts;
  }

  setStreamlines(streamlines: JSON) {
    this.streamlines = streamlines;
  }
}

export default WindStore;
