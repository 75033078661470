import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useEffect, useState } from 'react';

import { ReactComponent as CircleArrowLeftIcon } from 'assets/svg/circle_arrow_left.svg';
import { ReactComponent as CircleArrowRightIcon } from 'assets/svg/circle_arrow_right.svg';

import 'swiper/swiper.min.css';

import { Camera, cameraDirections } from './Cameras';
import styled from 'styled-components';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type DirectionAndImage = {
  direction: string;
  imageUrl: string;
};

const pagination = {
  clickable: true,
  renderBullet: function (_index: number, className: string) {
    return `
          <svg class="${className}" viewBox="0 0 114 114">
            <circle class="bg" cx="57" cy="57" r="52" />
            <circle class="meter" cx="57" cy="57" r="52" />
          </svg>
        `;
  },
  el: '.camera-swiper-pagination',
};

const Wrapper = styled.div`
  .camera-swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin: 0 1.25rem;
  }

  svg.swiper-pagination-bullet {
    width: 0.625rem;
    height: auto;
    cursor: pointer;
    border-radius: 50%;

    .bg {
      fill: none;
      stroke-width: 50px;
      stroke: #ccc;
    }

    .meter {
      fill: none;
      stroke-width: 50px;
      stroke-linecap: butt;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      stroke-dasharray: 360;
      stroke-dashoffset: 360;
      stroke: black;
    }

    &.swiper-pagination-bullet-active .meter {
      stroke-dashoffset: 0;
    }
  }
`;

const SlideImage = styled.div`
  width: 100%;
  height: 17.5rem;
  max-height: 30vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Bottom = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  padding: 1.5rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 400px) {
    font-size: 1rem;
    padding: 1rem 1rem 1.25rem;
    gap: 1rem;
  }
`;

const BottomText = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  line-height: 1.3;
`;

const BottomNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwiperNavButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
`;

export default function CameraPopupContent({ camera }: { camera: Camera }) {
  const [showSwiper, setShowSwiper] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const directionsWithImage: DirectionAndImage[] = [];

  cameraDirections.forEach((direction) => {
    if (camera[direction] != null)
      directionsWithImage.push({
        direction: capitalizeFirstLetter(direction),
        imageUrl: camera[direction],
      });
  });

  useEffect(() => {
    setShowSwiper(true);
  }, []);

  if (!showSwiper) return null;

  return (
    <Wrapper>
      <Swiper
        enabled={directionsWithImage.length > 1}
        loop
        slidesPerView={1}
        navigation={{ prevEl: '.camera-swiper-prev-button', nextEl: '.camera-swiper-next-button' }}
        pagination={pagination}
        modules={[Navigation, Pagination]}
        onRealIndexChange={(swiper) => {
          setCurrentIndex(swiper.realIndex);
        }}>
        {directionsWithImage.map((item) => (
          <SwiperSlide key={item.direction}>
            <SlideImage>
              <img alt="" src={item.imageUrl} />
            </SlideImage>
          </SwiperSlide>
        ))}
      </Swiper>

      <Bottom>
        <BottomText>
          <div>{camera.name}</div>
          <div>{directionsWithImage[currentIndex].direction}</div>
        </BottomText>
        {directionsWithImage.length > 1 && (
          <BottomNav>
            <SwiperNavButton className="camera-swiper-prev-button">
              <CircleArrowLeftIcon />
            </SwiperNavButton>
            <div className="camera-swiper-pagination"></div>
            <SwiperNavButton className="camera-swiper-next-button">
              <CircleArrowRightIcon />
            </SwiperNavButton>
          </BottomNav>
        )}
      </Bottom>
    </Wrapper>
  );
}
