import {
  COLORS,
  SAWTI_LEVEL_OBJS,
  PAL_LEVELS,
  WIDGET_NAMES,
  OPI_LEVELS,
  RED_FLAG_LEVELS,
  PSPS_LEVELS,
  THREAT_COLORS_OBJ
} from '@sdge-web-app/shared/dist/constants';
import { fpiColor, getOpiLevelColor } from '@sdge-web-app/shared/dist/utils';

export function isNum(val: any) {
  return !isNaN(val);
}

export function isSnowfallNum(val: any) {
  const removePlus = val.split('+').join('');
  return !isNaN(removePlus);
}

export function stripSymbol(val: string) {
  return val.split('"').join('');
}

export function shortenIfDusting(val: string) {
  if (val.toLowerCase() === 'dusting') {
    return 'Dust';
  } else return val;
}

export function palColor(i: string) {
  switch (i) {
    case PAL_LEVELS.green.long:
      return COLORS.fieldGreen;
    case PAL_LEVELS.blue.long:
      return COLORS.middaySky;
    case PAL_LEVELS.yellow.long:
      return COLORS.accessibleYellow;
    case PAL_LEVELS.orange.long:
      return COLORS.electricOrange;
    case PAL_LEVELS.brown.long:
      return COLORS.brown;
    case PAL_LEVELS.red.long:
      return COLORS.energyRed;
    case PAL_LEVELS.gray.long:
      return COLORS.slate;
    default:
      return COLORS.slate;
  }
}

export function palLevelShort(i: string) {
  switch (i) {
    case PAL_LEVELS.green.long:
      return PAL_LEVELS.green.short;
    case PAL_LEVELS.blue.long:
      return PAL_LEVELS.blue.short;
    case PAL_LEVELS.yellow.long:
      return PAL_LEVELS.yellow.short;
    case PAL_LEVELS.orange.long:
      return PAL_LEVELS.orange.short;
    case PAL_LEVELS.brown.long:
      return PAL_LEVELS.brown.short;
    case PAL_LEVELS.red.long:
      return PAL_LEVELS.red.short;
    case PAL_LEVELS.gray.long:
      return PAL_LEVELS.gray.short;
    default:
      return PAL_LEVELS.gray.short;
  }
}

export function temperatureColor(temp: number) {
  if (temp <= 54) {
    return COLORS.middaySky;
  } else if (temp >= 55 && temp <= 74) {
    return COLORS.fieldGreen;
  } else if (temp >= 75 && temp <= 89) {
    return COLORS.electricOrange;
  } else if (temp >= 90) {
    return COLORS.energyRed;
  } else {
    return 'black';
  }
}

export function rhColor(rh: number) {
  if (rh <= 10) {
    return COLORS.energyRed;
  } else if (rh >= 11 && rh <= 29) {
    return COLORS.electricOrange;
  } else if (rh >= 30 && rh <= 49) {
    return COLORS.accessibleYellow;
  } else if (rh >= 50) {
    return COLORS.fieldGreen;
  } else {
    return COLORS.fieldGreen;
  }
}

export function sawtiColor(i: string) {
  switch (i) {
    case SAWTI_LEVEL_OBJS.none.long:
      return COLORS.slate;
    case SAWTI_LEVEL_OBJS.marginal.long:
      return COLORS.accessibleYellow;
    case SAWTI_LEVEL_OBJS.moderate.long:
      return COLORS.electricOrange;
    case SAWTI_LEVEL_OBJS.high.long:
      return COLORS.energyRed;
    case SAWTI_LEVEL_OBJS.extreme.long:
      return COLORS.purple;
    default:
      return COLORS.slate;
  }
}

export function sawtiLevelShort(i: string) {
  switch (i) {
    case SAWTI_LEVEL_OBJS.none.long:
      return SAWTI_LEVEL_OBJS.none.short;
    case SAWTI_LEVEL_OBJS.marginal.long:
      return SAWTI_LEVEL_OBJS.marginal.short;
    case SAWTI_LEVEL_OBJS.moderate.long:
      return SAWTI_LEVEL_OBJS.moderate.short;
    case SAWTI_LEVEL_OBJS.high.long:
      return SAWTI_LEVEL_OBJS.high.short;
    case SAWTI_LEVEL_OBJS.extreme.long:
      return SAWTI_LEVEL_OBJS.extreme.short;
    default:
      return SAWTI_LEVEL_OBJS.none.short;
  }
}

export const opiLevelShort = (value: string) => {
  if (value === OPI_LEVELS.elevated.long) {
    return OPI_LEVELS.elevated.short;
  } else if (value === OPI_LEVELS.extreme.long) {
    return OPI_LEVELS.extreme.short;
  } else {
    return OPI_LEVELS.normal.short;
  }
};

export const redFlagLevelShort = (value: boolean) => {
  if (value) {
    return RED_FLAG_LEVELS.active.short;
  } else {
    return RED_FLAG_LEVELS.not_active.short;
  }
};

export function pspsColor(i: string) {
  switch (i) {
    case PSPS_LEVELS.normal.long:
      return COLORS.fieldGreen;
    case PSPS_LEVELS.watch.long:
      return COLORS.accessibleYellow;
    case PSPS_LEVELS.warning.long:
      return COLORS.energyRed;
    default:
      return COLORS.fieldGreen;
  }
}

export const pspsLevelShort = (value: string) => {
  if (value === PSPS_LEVELS.warning.long) {
    return PSPS_LEVELS.warning.short;
  } else if (value === PSPS_LEVELS.watch.long) {
    return PSPS_LEVELS.watch.short;
  } else {
    return PSPS_LEVELS.normal.short;
  }
};

export const thunderstormColor = (i: string) => {
  switch (i) {
    case THREAT_COLORS_OBJ.green.long:
      return COLORS.fieldGreen;
    case THREAT_COLORS_OBJ.yellow.long:
      return COLORS.accessibleYellow;
    case THREAT_COLORS_OBJ.red.long:
      return COLORS.energyRed;
    default:
      return COLORS.fieldGreen;
  }
};

export const thunderstormLevelShort = (i: string) => {
  switch (i) {
    case THREAT_COLORS_OBJ.green.long:
      return THREAT_COLORS_OBJ.green.short;
    case THREAT_COLORS_OBJ.yellow.long:
      return THREAT_COLORS_OBJ.yellow.short;
    case THREAT_COLORS_OBJ.red.long:
      return THREAT_COLORS_OBJ.red.short;
    default:
      return THREAT_COLORS_OBJ.green.short;
  }
};

export function generateDialColor(widgetName: string, value: string | number | boolean) {
  switch (widgetName) {
    case WIDGET_NAMES.FPI:
      if (typeof value === 'number') {
        return fpiColor(value);
      } else {
        return null;
      }
    case WIDGET_NAMES.OPI:
      if (typeof value === 'string') {
        return getOpiLevelColor(value as string);
      } else {
        return null;
      }
    case WIDGET_NAMES.LOW_TEMP:
    case WIDGET_NAMES.CURRENT_TEMP:
      if (typeof value === 'number') {
        return temperatureColor(value as number);
      } else {
        return null;
      }
    case WIDGET_NAMES.PAL:
      if (typeof value === 'string') {
        return palColor(value as string);
      } else {
        return null;
      }
    case WIDGET_NAMES.RED_FLAG:
      if (typeof value === 'boolean') {
        if (value) {
          return COLORS.energyRed;
        } else {
          return COLORS.slate;
        }
      } else {
        return null;
      }
    case WIDGET_NAMES.CURRENT_RH:
      if (typeof value === 'number') {
        return rhColor(value);
      } else {
        return null;
      }
    case WIDGET_NAMES.SAWTI:
      if (typeof value === 'string') {
        return sawtiColor(value as string);
      } else {
        return null;
      }
    case WIDGET_NAMES.PSPS:
      if (typeof value === 'string') {
        return pspsColor(value as string);
      } else {
        return null;
      }
    case WIDGET_NAMES.THUNDERSTORM:
      if (typeof value === 'string') {
        return thunderstormColor(value as string);
      } else {
        return null;
      }
    default:
      return null;
  }
}

/**
 * Arrange widget region display in specified order:
 * Beaches, Mesas, Inland Valleys, Foothills, Mountains, Desert
 * 
 * Data returned from API for various widgets can be in different order, formats, and types.
 * This function takes in data and sorts it based off of the specified field name used for
 * that widget type - i.e. Wind Gust API response has the region in the 'common_name' field
 * while Low Temp has it as 'area'. There is also different API structure responses, i.e. 
 * Current Temp and Current Humidity API responses have 'districts' as an object and 'station'
 * as the field name.
 * 
 * @param arrayToSort The data to sort 
 * @param sortByArray The order of the region strings to sort by
 * @param fieldName The field name the data is using
 * @returns sorted list by regions based on specified display order in the widgets
 */
export const sortWidgetRegionArray = (arrayToSort: any[], sortByArray: string[], fieldName: string) => {
  return [...arrayToSort].sort(
    (a, b) => sortByArray.indexOf(a[fieldName]) - sortByArray.indexOf(b[fieldName])
  );
};