import { SunriseSunset as ISunriseSunet } from '@sdge-web-app/shared/dist/types';
import moment, { Moment } from 'moment-timezone';
import { SectionTitle, SectionSubtitle, SectionWithTable } from './shared';

interface Props {
  reportDate: Date | string;
  show: boolean;
  sunriseSunset?: ISunriseSunet[];
}

const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / (60 * 60));
  const divisorForMinutes = seconds % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const remainingSeconds = Math.ceil(divisorForSeconds);

  return {
    h: hours,
    m: minutes,
    s: remainingSeconds,
  };
};

export default function SunriseSunset({ reportDate, show, sunriseSunset }: Props) {
  if (!show || sunriseSunset == null) return null;

  const reportStartDate = moment(reportDate).startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 2; i++) {
    days.push(reportStartDate.clone().add(i, 'days'));
  }

  return (
    <SectionWithTable>
      <SectionTitle>Sunrise & Sunset</SectionTitle>
      <SectionSubtitle>San Diego, California</SectionSubtitle>
      <table>
        <thead>
          <tr>
            <th>Astronomy</th>
            <th>Sunrise</th>
            <th>Sunset</th>
            <th>Length of Day</th>
          </tr>
        </thead>
        <tbody>
          {days.map((day) => {
            const dataForDay = sunriseSunset.find(
              (s) => moment(s.date_formatted, 'MM-DD-YYYY').startOf('day').toISOString() === day.toISOString(),
            );

            if (dataForDay == null) return null;

            const sunrise = moment(dataForDay.sunrise, 'HH:mm:ss');
            const sunset = moment(dataForDay.sunset, 'HH:mm:ss');
            const lengthOfDay = secondsToTime(sunset.diff(sunrise) / 1000);

            return (
              <tr key={day.toISOString()}>
                <td>{day.format('MMMM D')}</td>
                <td>{sunrise.format('hh:mm A')}</td>
                <td>{sunset.format('hh:mm A')}</td>
                <td>{`${lengthOfDay.h}H ${lengthOfDay.m}M ${lengthOfDay.s}S`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </SectionWithTable>
  );
}
