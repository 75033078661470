import { useStores } from 'store';
import { RedFlagWarning } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { redFlagLevelShort } from 'utils/widgetUtils';
import { DialTitle, DialWrapper, WidgetWrapper } from '../components/SharedStyles';

const RedFlagWarnings = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper>
      {widgetStore.redFlagData &&
        widgetStore.redFlagData.map((element: RedFlagWarning) => (
          <DialWrapper key={`redFlag-${element.zone}`}>
            <SolidDial
              widgetName={WIDGET_NAMES.RED_FLAG}
              current={element.active}
              innerText={redFlagLevelShort(element.active)}
            />
            <DialTitle>{element.zone}</DialTitle>
          </DialWrapper>
        ))}
    </WidgetWrapper>
  );
});

export default RedFlagWarnings;
