import styled from 'styled-components';
import { OPI_LEVELS, PAL_LEVELS, SAWTI_LEVEL_OBJS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { fpiShortLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { GradientDial } from 'components/Widgets/components/GradientDial';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { opiLevelShort, palLevelShort, redFlagLevelShort, sawtiLevelShort } from 'utils/widgetUtils';

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
`;

const DialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

function DialEmporium() {
  return (
    <div className="Dials">
      <h2>Dial Examples:</h2>
      <ListWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_TEMP} <br />
          No Max & Min
          <GradientDial widgetName={WIDGET_NAMES.CURRENT_TEMP} current={50} min={20} max={80} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_TEMP} <br /> Large w/Max & Min
          <GradientDial large widgetName={WIDGET_NAMES.CURRENT_TEMP} current={80} min={50} max={110} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_RH}
          <GradientDial widgetName={WIDGET_NAMES.CURRENT_RH} current={70} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.CURRENT_RH} <br /> Large
          <GradientDial large widgetName={WIDGET_NAMES.CURRENT_RH} current={50} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.WIND_GUST_FORECAST}
          <SolidDial widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} current={50} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.WIND_GUST_FORECAST}
          <br /> Large
          <SolidDial large widgetName={WIDGET_NAMES.WIND_GUST_FORECAST} current={50} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.FPI}
          <SolidDial widgetName={WIDGET_NAMES.FPI} current={14} innerText={fpiShortLevelFromValue(14)} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.FPI}
          <br /> Large
          <SolidDial large widgetName={WIDGET_NAMES.FPI} current={13} innerText={fpiShortLevelFromValue(13)} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.OPI}
          <SolidDial
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.normal.long}
            innerText={opiLevelShort(OPI_LEVELS.normal.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.OPI}
          <br /> Large
          <SolidDial
            large
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.extreme.long}
            innerText={opiLevelShort(OPI_LEVELS.extreme.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.LOW_TEMP}
          <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={41} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.LOW_TEMP}
          <br /> Large
          <SolidDial large widgetName={WIDGET_NAMES.LOW_TEMP} current={62} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.SAWTI}
          <SolidDial
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.marginal.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.marginal.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.SAWTI}
          <br /> Large
          <SolidDial
            large
            widgetName={WIDGET_NAMES.SAWTI}
            current={SAWTI_LEVEL_OBJS.moderate.long}
            innerText={sawtiLevelShort(SAWTI_LEVEL_OBJS.moderate.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.PAL}
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.orange.long}
            innerText={palLevelShort(PAL_LEVELS.orange.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.PAL}
          <br /> Large
          <SolidDial
            large
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.blue.long}
            innerText={palLevelShort(PAL_LEVELS.blue.long)}
          />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.RED_FLAG}
          <SolidDial widgetName={WIDGET_NAMES.RED_FLAG} current={true} innerText={redFlagLevelShort(true)} />
        </DialWrapper>
        <DialWrapper>
          {WIDGET_NAMES.RED_FLAG}
          <br /> Large
          <SolidDial large widgetName={WIDGET_NAMES.RED_FLAG} current={false} innerText={redFlagLevelShort(false)} />
        </DialWrapper>
      </ListWrapper>
    </div>
  );
}

export default DialEmporium;
