import { useEffect, useState } from 'react';
import { useStores } from 'store';
import { CONFIG_ENDPOINT } from '../constants';
import { getApiData } from '../utils/axios';

export default function useRadarDataLoader(): {
  errors: unknown[];
} {
  const [errors, setErrors] = useState<unknown[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);
  const { radarStore } = useStores();

  useEffect(() => {
    if (dataRetreived) return;
    // Get Radar URLs from the App Config DB
    getApiData(`${CONFIG_ENDPOINT}?keys=radarUrl`)
      .then((response) => {
        radarStore.setUrl(response.data.radarUrl);
        setDataRetreived(true);
      })
      .catch((err) => {
        setDataRetreived(true);
        console.error(err);
        setErrors([...errors].concat(err));
      });
  }, [errors, radarStore, dataRetreived]);

  // We will refresh our data from the DB every minute both to see if there is an updated url and also to see which image we should show at the given minute
  useEffect(() => {
    const interval = setInterval(() => {
      setDataRetreived(false);
    }, 1000 * 60); // 1 minute
    return () => clearInterval(interval);
  }, [dataRetreived]);

  return { errors };
}
