import styled from 'styled-components';

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { WEATHER_BRIEFING } from '@sdge-web-app/shared/dist/constants';
import { Link } from 'react-router-dom';
import { useStores } from 'store';

const BottomNav = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  column-gap: 3rem;

  @media (max-width: 1140px) {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
`;

const MainNavButton = styled.button<{ indicateNew: boolean }>`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  row-gap: 0.5rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.foreground};

  svg {
    width: 0.75rem;
    height: auto;

    &.briefing-icon {
      width: 1.5rem;
      height: 0.75rem;
    }
  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

  &:after {
    visibility: ${({ indicateNew }) => (indicateNew ? 'visible' : 'hidden')};
    content: '';
    background: #ff0d0d;
    border-radius: 2rem;
    position: relative;
    height: 0.5rem;
    width: 0.5rem;
    bottom: 3.4rem;
    left: 1.2rem;
    transform: scale(1);
    animation: pulse-red 2s infinite;
  }
`;

export default function BottomButtons() {
  const { overlayStore } = useStores();
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <BottomNav>
        <Link to={`/${WEATHER_BRIEFING}`}>
          <MainNavButton data-cy="briefing-toggle" indicateNew={localStorage.getItem('new_brief') === 'true'}>
            {themeContext.briefingIcon.render({ title: 'Briefing', className: 'briefing-icon' })} Briefing
          </MainNavButton>
        </Link>
        <MainNavButton
          data-cy="account-button"
          indicateNew={false}
          onClick={(e) => {
            e.preventDefault();
            overlayStore.open('account');
          }}>
          {themeContext.accountIcon.render({ title: 'Account' })} Account
        </MainNavButton>
        <MainNavButton
          data-cy="quicklinks-button"
          indicateNew={false}
          onClick={(e) => {
            e.preventDefault();
            overlayStore.open('quicklinks');
          }}>
          {themeContext.linkIcon.render({ title: 'Link' })} More Data
        </MainNavButton>
      </BottomNav>
    </>
  );
}
