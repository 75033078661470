import { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper as ISwiper } from 'swiper';
import styled from 'styled-components';

import 'swiper/swiper.min.css';

import CarouselWithProgressDots from '../CarouselWithProgressDots';
// TODO: Reimplement this call when Twitter endpoint updates are in place
// import useTwitterData from '../TwitterAndWeatherCarousel/useTwitterData';
import { Tweet as TweetType } from '@sdge-web-app/shared/dist/types';
import TweetSlide from '../TwitterAndWeatherCarousel/TweetSlide';

const Wrapper = styled.div`
  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 1.875rem;
  width: 95%;
  max-width: 30rem;
  padding: 1rem 1.5rem;
`;

const autoplayIntervalMs = 10000;

export default function TwitterCarouselMobile() {
  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const autoplayTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  // TODO: Reimplement this call when Twitter endpoint updates are in place
  // Currently this just results in lots of Datadog errors thrown
  // const { tweets } = useTwitterData();
  const { tweets }: { tweets: TweetType[] } = { tweets: [] };

  const intervalHandler = useCallback(() => {
    if (swiper) swiper.slideNext();
  }, [swiper]);

  const removeInterval = useCallback(() => {
    if (autoplayTimerRef.current) {
      clearInterval(autoplayTimerRef.current);
    }
  }, []);

  const startInterval = useCallback(() => {
    autoplayTimerRef.current = setInterval(intervalHandler, autoplayIntervalMs);
  }, [intervalHandler]);

  useEffect(() => {
    startInterval();

    return removeInterval;
  }, [startInterval, removeInterval]);

  if (tweets.length === 0) return null;

  return (
    <Wrapper>
      <CarouselWithProgressDots
        uniqueId="twitter"
        setSwiperWhenLoaded={setSwiper}
        isPaused={false}
        autoplayIntervalMs={autoplayIntervalMs}>
        {tweets.map((tweet) => (
          <TweetSlide key={tweet.id} tweet={tweet} />
        ))}
      </CarouselWithProgressDots>
    </Wrapper>
  );
}
