import { makeAutoObservable } from 'mobx';

class OverlayStore {
  overlayType: string | null = null;
  overlayOpen: boolean = false;
  widgetTooltip?: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  open(overlayType: string, widgetTooltip?: string) {
    this.overlayOpen = true;
    this.overlayType = overlayType;
    this.widgetTooltip = widgetTooltip;
  }

  close() {
    this.overlayOpen = false;
    this.overlayType = null;
    this.widgetTooltip = undefined;
  }
}

export default OverlayStore;
