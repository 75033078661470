import { ThunderstormThreat } from '@sdge-web-app/shared/dist/types';
import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';
import { DATETIME_FORMATS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidDial } from '../components/SolidDial';
import { thunderstormLevelShort } from 'utils/widgetUtils';
import moment from 'moment';
import {
  ColumnSection,
  ColumnTitle,
  ColumnWrapper,
  DialTitle,
  DialWrapper,
  ListItem,
  ListSection,
  WidgetWrapper,
} from '../components/SharedStyles';

export const ThunderstormWidgetWrapper = styled(WidgetWrapper)`
  flex-wrap: wrap;
`;

export const ThunderstormColumnSection = styled(ColumnSection)`
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 0.25rem;
`;

export const DaySection = styled(WidgetWrapper)`
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  color: #666366;
  white-space: break-spaces;
  line-height: 1rem;
`;

export const ThunderstormDialWrapper = styled(DialWrapper)`
  width: calc(100% / 7);
  white-space: break-spaces;
`;

const ThunderstormThreatContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <ThunderstormWidgetWrapper className="thunderstormThreat">
      {widgetStore.thunderstormData &&
        widgetStore.thunderstormData.map((threat: ThunderstormThreat) => (
          <ThunderstormWidgetWrapper key={`thunderstormThreat-${threat.id}`}>
            <ThunderstormColumnSection>
              <ColumnWrapper>
                <ColumnTitle>Start</ColumnTitle> {threat.start}
              </ColumnWrapper>
              <ColumnWrapper>
                <ColumnTitle>End</ColumnTitle> {threat.end}
              </ColumnWrapper>
              <ColumnWrapper>
                <ColumnTitle>Where</ColumnTitle> {threat.location}
              </ColumnWrapper>
              <ColumnWrapper>
                <ColumnTitle>Threats</ColumnTitle>
                <ListSection>
                  {threat.threats.map((threatItem) => (
                    <ListItem key={`threatItem=${threatItem}`}>{threatItem}</ListItem>
                  ))}
                </ListSection>
              </ColumnWrapper>
            </ThunderstormColumnSection>
            <DaySection>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day1}
                  innerText={thunderstormLevelShort(threat.day1)}
                />
                <DialTitle>{moment().format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day2}
                  innerText={thunderstormLevelShort(threat.day2)}
                />
                <DialTitle>{moment().add(1, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day3}
                  innerText={thunderstormLevelShort(threat.day3)}
                />
                <DialTitle>{moment().add(2, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day4}
                  innerText={thunderstormLevelShort(threat.day4)}
                />
                <DialTitle>{moment().add(3, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day5}
                  innerText={thunderstormLevelShort(threat.day5)}
                />
                <DialTitle>{moment().add(4, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day6}
                  innerText={thunderstormLevelShort(threat.day6)}
                />
                <DialTitle>{moment().add(5, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
              <ThunderstormDialWrapper>
                <SolidDial
                  widgetName={WIDGET_NAMES.THUNDERSTORM}
                  current={threat.day7}
                  innerText={thunderstormLevelShort(threat.day7)}
                />
                <DialTitle>{moment().add(6, 'day').format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</DialTitle>
              </ThunderstormDialWrapper>
            </DaySection>
          </ThunderstormWidgetWrapper>
        ))}
    </ThunderstormWidgetWrapper>
  );
});

export default ThunderstormThreatContainer;
