import { useState, useEffect } from 'react';
import { OBSERVATIONS_ENDPOINT } from '../../../constants';
import { getApiData } from 'utils/axios';
import { CurrentObservation } from '@sdge-web-app/shared/dist/types';
import moment from 'moment-timezone';

function Observations() {
  const [obsData, setObsData] = useState<CurrentObservation[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    setDataRetreived(true);
  }, []);

  // Make a request for the FPI data from the database
  if (!dataRetreived) {
    getApiData(OBSERVATIONS_ENDPOINT)
      .then(function (response) {
        // handle success
        setObsData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  return (
    <div className="Obs">
      <h2>Current observations:</h2>
      {obsData &&
        obsData.map((station: CurrentObservation) => (
          <div key={`obsData-${station.id}`}>
            <h3>Station Name: {station.common_name}</h3>
            <div>Identifier: {station.station}</div>
            <div>ID: {station.id}</div>
            <div>District: {station.district}</div>
            <div>Observation Time: {moment(station.ob_dt).format()}</div>
            <div>Age: {station.age}</div>
            <div>Temp: {station.temp} degrees</div>
            <div>Relative Humidity (RH): {station.rh}</div>
            <div>
              Max/Min Temps: {station.max_temp}/{station.min_temp}
            </div>
            <div>
              Winds: {station.wspeed} mph from {station.wdir}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Observations;
