import styled from 'styled-components';
import { FpiRegion } from '@sdge-web-app/shared/dist/types';
import { DISTRICTS } from '@sdge-web-app/shared/dist/constants';
import { fpiLongLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { SectionTitle, SectionWithTable } from './shared';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { fpiColor } from '../utils';
import { sortRegionArray } from 'utils/districtSort';

const Wrapper = styled(SectionWithTable)`
  table {
    @media (max-width: 520px) {
      font-size: 12px;
    }

    tr {
      border: none !important;
    }

    td,
    th {
      padding: 4px 0 !important;
      color: ${props => props.theme.foreground};
    }

    td {
      font-weight: normal;
      text-transform: uppercase;
    }
  }
`;

export const FPISpectrum = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(
    270deg,
    ${COLORS.fieldGreen} 0%,
    ${COLORS.accessibleYellow} 32.29%,
    ${COLORS.electricOrange} 67.19%,
    ${COLORS.energyRed} 97.29%
  );
  transform: rotate(180deg);
`;

export const SpectrumLabels = styled.div`
  font-family: 'Interstate Condensed';
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  div {
    margin: 0 4%;
    color: ${props => props.theme.foreground};
  }

  @media (max-width: 520px) {
    font-size: 12px;

    div {
      margin: 0;
    }
  }
`;

export const FPICell = styled.td<{ fpi: number }>`
  // color: ${({ fpi }) => fpiColor(fpi)};
  color: black;
`;

export const ColorBubble = styled.div<{ fpi: number }>`
  border: ${({ fpi }) => `2px solid ${fpiColor(fpi)}`};
  width: 1.65rem;
  border-radius: 5rem;
  margin: 0 auto;
`;

interface Props {
  show: boolean;
  todaysFPI?: FpiRegion[];
}

export default function TodaysFPI({ show, todaysFPI }: Props) {
  if (!show || todaysFPI == null) return null;

  todaysFPI = sortRegionArray(todaysFPI);

  return (
    <Wrapper>
      <SectionTitle>Today's Fire Potential Index (FPI)</SectionTitle>
      <table>
        <thead>
          <tr>
            {todaysFPI.map((fpi) => (
              <th key={fpi.region}>{DISTRICTS[fpi.region].code.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {todaysFPI.map((fpi) =>
              fpi.fpi != null ? (
                <FPICell key={fpi.region} fpi={fpi.fpi}>
                  {fpiLongLevelFromValue(fpi.fpi)}
                </FPICell>
              ) : (
                <td key={fpi.region}></td>
              ),
            )}
          </tr>
          <tr>
            {todaysFPI.map((fpi) =>
              fpi.fpi != null ? (
                <FPICell key={fpi.region} fpi={fpi.fpi}>
                  <ColorBubble fpi={fpi.fpi}>{fpi.fpi}</ColorBubble>
                </FPICell>
              ) : (
                <td key={fpi.region}></td>
              ),
            )}
          </tr>
        </tbody>
      </table>

      <FPISpectrum />
      <SpectrumLabels>
        <div>&lt; 12 | Normal</div>
        <div>12 - 13 | Elevated</div>
        <div>14 | Elevated</div>
        <div>&gt; 14 | Extreme</div>
      </SpectrumLabels>
    </Wrapper>
  );
}
