import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import RichTextRenderer from './RichTextRenderer';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 50px 0;
  border-bottom: 1px solid ${COLORS.disabled};
  padding-bottom: 40px;
  color: ${props => props.theme.foregroundGray};

  & > div {
    width: 50%;
  }

  /* Set uploaded images to 500px max width */
  img {
    max-width: 500px;
  }

  @media (max-width: 720px) {
    display: block;

    & > div {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 25px;
  color: ${props => props.theme.foreground};
`;

interface Props {
  title: string;
  content: any;
  IconComponent: FunctionComponent;
}

export default function RichTextSection({ title, content, IconComponent }: Props) {
  if (content.content.length === 0) return null;

  return (
    <Wrapper>
      <Left>
        <IconComponent />
        <Title>{title}</Title>
      </Left>
      <RichTextRenderer content={content} />
    </Wrapper>
  );
}
