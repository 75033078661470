import { useState, useEffect } from 'react';
import { WINDS_TOP_CURRENT_ENDPOINT } from '../../../constants';
import axios from 'axios';
import { CurrentObservation } from '@sdge-web-app/shared/dist/types';
import moment from 'moment-timezone';

function CurrentGusts() {
  const [currentGustData, setCurrentGustData] = useState<CurrentObservation[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    setDataRetreived(true);
  }, []);

  // Make a request for the FPI data from the database
  if (!dataRetreived) {
    axios
      .get(WINDS_TOP_CURRENT_ENDPOINT)
      .then(function (response) {
        // handle success
        setCurrentGustData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  }

  return (
    <div className="CurrentGusts">
      <h2>Top 5 Current Gusts:</h2>
      {currentGustData &&
        currentGustData.map((station: CurrentObservation) => (
          <div key={`currentGustData-${station.station}`}>
            <h3>Station: {station.common_name}</h3>
            <div>Station Identifier: {station.station}</div>
            <div>Gust speed: {station.gspeed} mph</div>
            <div>Gust direction: {station.gdir}</div>
            <div>Observation time: {moment(station.ob_dt).format()}</div>
          </div>
        ))}
    </div>
  );
}

export default CurrentGusts;
