import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import MainNav from './components/MainNav';
import MainNavMobile from './components/MainNavMobile';
import { WidgetNav } from './components/WidgetNav';
import { WidgetNavMobile } from './components/WidgetNavMobile';
import BottomButtons from './components/BottomButtons';

const Left = styled.aside`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 33rem;
  height: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;

  @media (min-width: 1920px) {
    justify-content: space-between;
  }
`;

const BottomRight = styled.aside`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
`;

const BottomNavWrapper = styled.section`
  position: absolute;
  bottom: 0;
  width: 98%;
  background: ${props => props.theme.background};
  color: ${props => props.theme.foreground};
  border-radius: 2.5rem 2.5rem 0 0;
  margin-left: 1%;
  margin-right: 1%;
`;

export default function Nav() {
  const isMobile = useMediaQuery({ query: '(max-width: 1140px)' });
  const isJumbo = useMediaQuery({ query: '(min-width: 1920px)' });

  return (
    <>
      {isMobile && (
        <>
          <MainNavMobile />
          <WidgetNavMobile />
          <BottomNavWrapper>
            <BottomButtons />
          </BottomNavWrapper>
        </>
      )}
      {!isMobile && (
        <>
          <Left>
            {isJumbo && <MainNav />}
            <WidgetNav />
          </Left>
          {!isJumbo && (
            <BottomRight>
              <MainNav />
            </BottomRight>
          )}
        </>
      )}
    </>
  );
}
