import utils from '../utils';
import { WeatherBriefingOld } from '@sdge-web-app/shared/dist/types';

export default function SafetyTalkingPoint({ weatherBriefing }: { weatherBriefing: WeatherBriefingOld }) {
  // `districtBC` etc. is from older weather briefings.  Ignore those.
  if (!weatherBriefing.includefpi || weatherBriefing.data.fpiReport['districtBC']) return null;

  return (
    <table
      className="row row-today-fire-potential-index"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-12 columns first last"
            style={{
              padding: '40px 50px',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '564px',
              paddingRight: '16px',
            }}>
            <table
              className=""
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th>
                    <div
                      className="section-header text-center"
                      style={{
                        textAlign: 'center',
                      }}>
                      <h4
                        className="text-blue text-center forecast-section-header-title"
                        style={{
                          textAlign: 'center',
                          color: '#004286',
                          margin: '0 0 30px',
                          fontSize: '28px',
                          fontWeight: 'normal',
                        }}>
                        Today's Fire Potential Index (FPI)
                      </h4>
                    </div>
                    <div className="section-content">
                      <table
                        className="main-table"
                        style={{
                          borderSpacing: '0',
                          borderCollapse: 'collapse',
                          padding: '0',
                          verticalAlign: 'top',
                          textAlign: 'left',
                          width: '100%',
                        }}>
                        <thead>
                          <tr
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              background: '#004285',
                            }}>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              ME
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              RA
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              EA
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              NE
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              OC
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              NC
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              BC
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              CM
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tbody-main">
                          <tr
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                            }}>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['mountainempire'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['mountainempire'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['mountainempire'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['mountainempire'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['ramona'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['ramona'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['ramona'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['ramona'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['eastern'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['eastern'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['eastern'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['eastern'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['northeast'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['northeast'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['northeast'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['northeast'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['orangecounty'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['orangecounty'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['orangecounty'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['orangecounty'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['northcoast'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['northcoast'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['northcoast'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['northcoast'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['beachcities'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['beachcities'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['beachcities'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['beachcities'][0]['fpi']}</p>
                            </td>
                            <td
                              style={{
                                color: utils.fpiColor(weatherBriefing.data.fpiReport['metro'][0]['fpi']),
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '20px 0',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="desktop">
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['metro'][0]['fpi'])}
                              </span>
                              <span
                                className="mobile"
                                style={{
                                  display: 'none',
                                }}>
                                {utils.fpiWarningLevel(weatherBriefing.data.fpiReport['metro'][0]['fpi'])}
                              </span>
                              <p>{weatherBriefing.data.fpiReport['metro'][0]['fpi']}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table
                        className="graph-table"
                        style={{
                          borderSpacing: '0',
                          borderCollapse: 'collapse',
                          padding: '0',
                          verticalAlign: 'top',
                          textAlign: 'left',
                          width: '100%',
                        }}>
                        <tbody className="tbody-graph">
                          <tr
                            className="tr-graph"
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              height: '5px',
                              border: 'none',
                            }}>
                            <td
                              className="normal-potential"
                              style={{
                                width: '25%',
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                background: '#7AB034',
                                borderCollapse: 'collapse',
                              }}></td>
                            <td
                              className="elevated-potential"
                              style={{
                                width: '25%',
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                background: '#dbdc47',
                                borderCollapse: 'collapse',
                              }}></td>
                            <td
                              className="elevated-potential"
                              style={{
                                width: '25%',
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                background: '#d1a745',
                                borderCollapse: 'collapse',
                              }}></td>
                            <td
                              className="extreme-potential"
                              style={{
                                width: '25%',
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                background: '#FF2211',
                                borderCollapse: 'collapse',
                              }}></td>
                          </tr>
                          <tr
                            className="tr-description"
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                            }}>
                            <td
                              style={{
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '5px 0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="forecast-legend forecast-legend-Normal">
                                <span>&lt; 12 | Normal</span>
                              </span>
                            </td>
                            <td
                              style={{
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '5px 0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="forecast-legend forecast-legend-Elevated">
                                <span>12 - 13 | Elevated</span>
                              </span>
                            </td>
                            <td
                              style={{
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '5px 0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="forecast-legend forecast-legend-Elevated">
                                <span>14 | Elevated</span>
                              </span>
                            </td>
                            <td
                              style={{
                                wordWrap: 'break-word',

                                hyphens: 'auto',
                                padding: '5px 0',
                                verticalAlign: 'top',
                                textAlign: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                borderCollapse: 'collapse',
                              }}>
                              <span className="forecast-legend forecast-legend-Extreme">
                                <span>&lt; 15 | Extreme</span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </th>
                  <th
                    className="expander"
                    style={{
                      visibility: 'hidden',
                      width: '0',
                      padding: '0 !important',
                    }}></th>
                </tr>
              </tbody>
            </table>
            <div
              className="section-separated-line"
              style={{
                borderBottom: '1px solid #D9D9D9',
                marginTop: '35px',
              }}></div>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
