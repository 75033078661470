import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import { isNum } from 'utils/widgetUtils';
import {
  ColumnSection,
  ColumnTitle,
  ColumnWrapper,
  VolumeSection,
  VolumeWrapper,
  WidgetWrapper,
} from '../components/SharedStyles';

const RainfallForecastContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <WidgetWrapper>
      {widgetStore.rainfallData && (
        <ColumnSection>
          <ColumnWrapper>
            <ColumnTitle>Start</ColumnTitle>
            {widgetStore.rainfallData.start}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>End</ColumnTitle>
            {widgetStore.rainfallData.end}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Beach</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {widgetStore.rainfallData.beach_from} {isNum(widgetStore.rainfallData.beach_from) && 'inch'}
              </VolumeWrapper>
              —
              <VolumeWrapper>
                {widgetStore.rainfallData.beach_to} {isNum(widgetStore.rainfallData.beach_to) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Inland</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {widgetStore.rainfallData.inland_from} {isNum(widgetStore.rainfallData.inland_from) && 'inch'}
              </VolumeWrapper>
              —
              <VolumeWrapper>
                {widgetStore.rainfallData.inland_to} {isNum(widgetStore.rainfallData.inland_to) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Mountain</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {widgetStore.rainfallData.mountain_from} {isNum(widgetStore.rainfallData.mountain_from) && 'inch'}
              </VolumeWrapper>
              —
              <VolumeWrapper>
                {widgetStore.rainfallData.mountain_to} {isNum(widgetStore.rainfallData.mountain_to) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle> Desert</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {widgetStore.rainfallData.desert_from} {isNum(widgetStore.rainfallData.desert_from) && 'inch'}
              </VolumeWrapper>
              —
              <VolumeWrapper>
                {widgetStore.rainfallData.desert_to} {isNum(widgetStore.rainfallData.desert_to) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
        </ColumnSection>
      )}
    </WidgetWrapper>
  );
});

export default RainfallForecastContainer;
