import moment from 'moment-timezone';
import { WeatherBriefingOld } from '@sdge-web-app/shared/dist/types';

export default function SevenDayForecast({ weatherBriefing }: { weatherBriefing: WeatherBriefingOld }) {
  if (!weatherBriefing.forecasts_archive || !weatherBriefing.includesevendayforecast) return null;

  return (
    <table
      className="row row-latest-7-day-forecast"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-12 columns first last"
            style={{
              padding: '0 50px',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '564px',
              paddingRight: '16px',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th>
                    <div
                      className="section-header text-center"
                      style={{
                        textAlign: 'center',
                      }}>
                      <h4
                        className="text-blue text-center latest-7-day-forecast-section-header-title"
                        style={{
                          textAlign: 'center',
                          color: '#004286',
                          margin: '0 0 30px',
                          fontSize: '28px',
                          fontWeight: 'normal',
                        }}>
                        Latest 7-Day Forecast
                      </h4>
                    </div>
                    <div className="section-content">
                      <table
                        className="main-table black-text"
                        style={{
                          borderSpacing: '0',
                          borderCollapse: 'collapse',
                          padding: '0',
                          verticalAlign: 'top',
                          textAlign: 'left',
                          width: '100%',
                        }}>
                        <thead>
                          <tr
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                              background: '#004285',
                            }}>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(1, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(1, 'd').format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(2, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(2, 'd').format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(3, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(3, 'd').format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(4, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(4, 'd').format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(5, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(5, 'd').format('M/DD')}
                            </th>
                            <th
                              style={{
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '20px 0',
                                width: '12.5%',
                                fontSize: '12px',
                                fontWeight: 'bold',
                              }}>
                              {moment(weatherBriefing.forecasts_archive.created_at).add(6, 'd').format('ddd')}
                              <br />
                              {moment(weatherBriefing.forecasts_archive.created_at).add(6, 'd').format('M/DD')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {weatherBriefing.formattedForecastData && (
                            <>
                              {weatherBriefing.formattedForecastData['expected-weather-conditions'].formattedData?.find(
                                (day) => (day.value as string)?.length > 0,
                              ) && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="text-blue"
                                        style={{
                                          paddingLeft: '40px',
                                          color: '#004286',
                                          lineHeight: '26px',
                                        }}>
                                        EXPECTED WEATHER CONDITIONS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData[
                                      'expected-weather-conditions'
                                    ].formattedData.map((d, i) => (
                                      <td
                                        key={`${i}-${d.value}-${d.desc}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        <div
                                          className="{d.value}"
                                          style={{
                                            background: `url(/media/old_weather_briefings/${d.value}.png) no-repeat center center`,
                                            height: '30px',
                                          }}></div>
                                        <div
                                          className="desc"
                                          style={{
                                            padding: '0 5px',
                                            fontWeight: 'normal',
                                          }}>
                                          {d.desc === 'partly-cloudy' ? 'partly cloudy' : d.desc}
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}

                              {weatherBriefing.formattedForecastData['beaches'].formattedData && (
                                <>
                                  <tr
                                    className="gray-green-bg row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        BEACHES
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="gray-green-bg row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    {weatherBriefing.formattedForecastData['beaches'].formattedData.map((day, i) => (
                                      <td
                                        key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        <span
                                          style={{
                                            color: day.loColor ?? 'black',
                                          }}>
                                          {day.lo}
                                        </span>
                                        /
                                        <span
                                          style={{
                                            color: day.hiColor ?? 'black',
                                          }}>
                                          {day.hi}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['mesas'].formattedData && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        MESAS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData['mesas'].formattedData.map((day, i) => (
                                      <td
                                        key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        <span
                                          style={{
                                            color: day.loColor ?? 'black',
                                          }}>
                                          {day.lo}
                                        </span>
                                        /
                                        <span
                                          style={{
                                            color: day.hiColor ?? 'black',
                                          }}>
                                          {day.hi}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['miramar'].formattedData && (
                                <>
                                  <tr
                                    className="gray-green-bg row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        MIRAMAR
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="gray-green-bg row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    {weatherBriefing.formattedForecastData['miramar'].formattedData.map((day, i) => (
                                      <td
                                        key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        <span
                                          style={{
                                            color: day.loColor ?? 'black',
                                          }}>
                                          {day.lo}
                                        </span>
                                        /
                                        <span
                                          style={{
                                            color: day.hiColor ?? 'black',
                                          }}>
                                          {day.hi}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['inland-orange-county'].formattedData && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        INLAND ORANGE COUNTY
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData['inland-orange-county'].formattedData.map(
                                      (day, i) => (
                                        <td
                                          key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                          style={{
                                            wordWrap: 'break-word',

                                            hyphens: 'auto',
                                            padding: '20px 0 30px',
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            borderCollapse: 'collapse',
                                          }}>
                                          <span
                                            style={{
                                              color: day.loColor ?? 'black',
                                            }}>
                                            {day.lo}
                                          </span>
                                          /
                                          <span
                                            style={{
                                              color: day.hiColor ?? 'black',
                                            }}>
                                            {day.hi}
                                          </span>
                                        </td>
                                      ),
                                    )}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['inland-valley'].formattedData && (
                                <>
                                  <tr
                                    className="gray-green-bg row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        INLAND VALLEY
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="gray-green-bg row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    {weatherBriefing.formattedForecastData['inland-valley'].formattedData.map(
                                      (day, i) => (
                                        <td
                                          key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                          style={{
                                            wordWrap: 'break-word',

                                            hyphens: 'auto',
                                            padding: '20px 0 30px',
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            borderCollapse: 'collapse',
                                          }}>
                                          <span
                                            style={{
                                              color: day.loColor ?? 'black',
                                            }}>
                                            {day.lo}
                                          </span>
                                          /
                                          <span
                                            style={{
                                              color: day.hiColor ?? 'black',
                                            }}>
                                            {day.hi}
                                          </span>
                                        </td>
                                      ),
                                    )}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['foothills'].formattedData && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        FOOTHILLS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData['foothills'].formattedData.map((day, i) => (
                                      <td
                                        key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        <span
                                          style={{
                                            color: day.loColor ?? 'black',
                                          }}>
                                          {day.lo}
                                        </span>
                                        /
                                        <span
                                          style={{
                                            color: day.hiColor ?? 'black',
                                          }}>
                                          {day.hi}
                                        </span>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['mountains'].formattedData && (
                                <>
                                  <tr
                                    className="gray-green-bg row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        MOUNTAINS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="gray-green-bg row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    {weatherBriefing.formattedForecastData['mountains'].formattedData
                                      // @ts-ignore
                                      .filter((day) => Object.keys(day).filter((key) => day[key] != null).length === 6)
                                      .map((day, i) => (
                                        <td
                                          key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                          style={{
                                            wordWrap: 'break-word',

                                            hyphens: 'auto',
                                            padding: '20px 0 30px',
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            borderCollapse: 'collapse',
                                          }}>
                                          <span
                                            style={{
                                              color: day.loColor ?? 'black',
                                            }}>
                                            {day.lo}
                                          </span>
                                          /
                                          <span
                                            style={{
                                              color: day.hiColor ?? 'black',
                                            }}>
                                            {day.hi}
                                          </span>
                                        </td>
                                      ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['deserts'].formattedData && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        DESERTS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData['deserts'].formattedData
                                      // @ts-ignore
                                      .filter((day) => Object.keys(day).filter((key) => day[key] != null).length === 6)
                                      .map((day, i) => (
                                        <td
                                          key={`${day.lo}-${day.loColor}-${day.hi}-${day.hiColor}-${i}`}
                                          style={{
                                            wordWrap: 'break-word',

                                            hyphens: 'auto',
                                            padding: '20px 0 30px',
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            borderCollapse: 'collapse',
                                          }}>
                                          <span
                                            style={{
                                              color: day.loColor ?? 'black',
                                            }}>
                                            {day.lo}
                                          </span>
                                          /
                                          <span
                                            style={{
                                              color: day.hiColor ?? 'black',
                                            }}>
                                            {day.hi}
                                          </span>
                                        </td>
                                      ))}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['winds-campo'].formattedData && (
                                <>
                                  <tr
                                    className="gray-green-bg row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-thermometer text-blue"
                                        style={{
                                          color: '#004286',
                                          background:
                                            'url(/media/old_weather_briefings/thermometer.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        BACKCOUNTRY WINDS
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="gray-green-bg row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      background: '#E3EBF3',
                                    }}>
                                    {weatherBriefing.formattedForecastData['winds-campo'].formattedData.map(
                                      (day, i) => (
                                        <td
                                          key={`${day.value}-${i}`}
                                          style={{
                                            wordWrap: 'break-word',

                                            hyphens: 'auto',
                                            padding: '20px 0 30px',
                                            verticalAlign: 'top',
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            borderCollapse: 'collapse',
                                          }}>
                                          {day.value}
                                        </td>
                                      ),
                                    )}
                                  </tr>
                                </>
                              )}
                              {weatherBriefing.formattedForecastData['pop-campo'].formattedData && (
                                <>
                                  <tr
                                    className="row-header"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      colSpan={7}
                                      className=""
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '30px 0 0 3%',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderCollapse: 'collapse',
                                      }}>
                                      <div
                                        className="with-rainy text-blue"
                                        style={{
                                          color: '#004286',
                                          background: 'url(/media/old_weather_briefings/rainy.png) no-repeat 0 center',
                                          lineHeight: '26px',
                                          paddingLeft: '40px',
                                        }}>
                                        CHANCE OF PRECIPITATION
                                      </div>
                                    </td>
                                  </tr>
                                  <tr
                                    className="row-data"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    {weatherBriefing.formattedForecastData['pop-campo'].formattedData.map((day, i) => (
                                      <td
                                        key={`${day.value}-${i}`}
                                        style={{
                                          wordWrap: 'break-word',

                                          hyphens: 'auto',
                                          padding: '20px 0 30px',
                                          verticalAlign: 'top',
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderCollapse: 'collapse',
                                        }}>
                                        {day.value}%
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      <p
                        className="graph-table-caption text-center"
                        style={{
                          margin: '40px 0 5px',
                          lineHeight: '30px',
                          marginBottom: '0',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: '#333',
                        }}>
                        Forecast Weather Legend
                      </p>
                      <table
                        style={{
                          borderSpacing: '0',
                          borderCollapse: 'collapse',
                          padding: '0',
                          verticalAlign: 'top',
                          textAlign: 'left',
                          width: '100%',
                        }}>
                        <tbody>
                          <tr
                            style={{
                              padding: '0',
                              verticalAlign: 'top',
                              textAlign: 'left',
                            }}>
                            <th
                              className="small-12 large-6 columns first"
                              style={{
                                paddingRight: '0',
                                margin: '0 auto',
                                paddingLeft: '0',
                                paddingBottom: '16px',
                                width: '50%',
                              }}>
                              <table
                                className="graph-table heat-graph-table"
                                style={{
                                  borderSpacing: '0',
                                  borderCollapse: 'collapse',
                                  padding: '0',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                  width: '100%',
                                }}>
                                <tbody className="tbody-graph">
                                  <tr
                                    className="tr-graph"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      height: '5px',
                                      border: 'none',
                                    }}>
                                    <td
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '0',
                                        verticalAlign: 'top',
                                        textAlign: 'center',
                                        background: '#FF9711',
                                        borderCollapse: 'collapse',
                                      }}></td>
                                    <td
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '0',
                                        verticalAlign: 'top',
                                        textAlign: 'center',
                                        background: '#d1a745',
                                        borderCollapse: 'collapse',
                                      }}></td>
                                  </tr>
                                  <tr
                                    className="tr-description"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      className="text-left"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '20px 10px',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        width: '25%',
                                        borderCollapse: 'collapse',
                                      }}>
                                      High Heat (above 95F) Illness Prevention measures are mandated by Safety Standard
                                      G 8366: Pre-shift meeting to review preventive, cool down, and communication
                                      measures; Shade & 1 quart of cool water per hour per crew member required.
                                      Supervisors to observe for employee heat illnesses, or use a buddy system or
                                      regular communication with sole employees.
                                    </td>
                                    <td
                                      className="text-left"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '20px 10px',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        width: '25%',
                                        borderCollapse: 'collapse',
                                      }}>
                                      Shading indicates Heat Illness Prevention (heat wave at or above 80F) measures are
                                      mandated by Safety Standard G 8366: Shade & 1 quart of cool water per hour per
                                      crew member required. Supervisors to observe for employee heat illnesses.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                            <th
                              className="small-12 large-6 columns last"
                              style={{
                                paddingLeft: '0',
                                margin: '0 auto',
                                paddingBottom: '16px',
                                width: '50%',
                                paddingRight: '0',
                              }}>
                              <table
                                className="graph-table heat-graph-table"
                                style={{
                                  borderSpacing: '0',
                                  borderCollapse: 'collapse',
                                  padding: '0',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                  width: '100%',
                                }}>
                                <tbody className="tbody-graph">
                                  <tr
                                    className="tr-graph"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                      height: '5px',
                                      border: 'none',
                                    }}>
                                    <td
                                      className="light-blue"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '0',
                                        verticalAlign: 'top',
                                        textAlign: 'center',
                                        background: '#20B5FF',
                                        borderCollapse: 'collapse',
                                      }}></td>
                                    <td
                                      className="purple"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '0',
                                        verticalAlign: 'top',
                                        textAlign: 'center',
                                        background: '#B963EF',
                                        borderCollapse: 'collapse',
                                      }}></td>
                                  </tr>
                                  <tr
                                    className="tr-description"
                                    style={{
                                      padding: '0',
                                      verticalAlign: 'top',
                                      textAlign: 'left',
                                    }}>
                                    <td
                                      className="text-left"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '20px 10px',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        width: '25%',
                                        borderCollapse: 'collapse',
                                      }}>
                                      Shading Indicates low temperatures in this region are expected to be at 40 degrees
                                      Fahrenheit or lower
                                    </td>
                                    <td
                                      className="text-left"
                                      style={{
                                        wordWrap: 'break-word',

                                        hyphens: 'auto',
                                        padding: '20px 10px',
                                        verticalAlign: 'top',
                                        textAlign: 'left',
                                        fontSize: '10px',
                                        fontWeight: 'normal',
                                        width: '25%',
                                        borderCollapse: 'collapse',
                                      }}>
                                      Shading Indicates low temperatures in this region are expected to be at 32 degrees
                                      Fahrenheit or lower
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </th>
                  <th
                    className="expander"
                    style={{
                      visibility: 'hidden',
                      width: '0',
                      padding: '0 !important',
                    }}></th>
                </tr>
              </tbody>
            </table>
            <div
              className="section-separated-line"
              style={{
                borderBottom: '1px solid #D9D9D9',
                marginTop: '35px',
              }}></div>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
