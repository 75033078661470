import { Layer, Marker, Source } from 'react-map-gl';
import { useMemo } from 'react';
import geoJson from '../sources/pal.json';
import { ProjectActivityLevel } from '@sdge-web-app/shared/dist/types';
import { isFeatureCollection } from '../index';
import centerOfMass from '@turf/center-of-mass';
import * as turfHelper from '@turf/helpers';
import { palColor, palLevelShort } from 'utils/widgetUtils';
import { PROJECT_ACTIVITY_LEVEL_ZONES, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

function determineFillPattern(i: string) {
  switch (i) {
    case 'Light Green (A)':
      return 'dots1';
    case 'Blue (B)':
      return 'dots2';
    case 'Yellow (C)':
      return 'dots3';
    case 'Orange (D)':
      return 'lines';
    case 'Brown (E)':
      return 'lines_crossed';
    case 'Red (EV)':
      return 'lines_crossed';
    case 'Gray (NA)':
      return null;
    default:
      return null;
  }
}

export default function Pal({ data }: { data?: ProjectActivityLevel[] }) {
  let polygonData: Record<string, Record<string, any>> = useMemo(() => {
    let data: Record<string, Record<string, any>> = {};
    PROJECT_ACTIVITY_LEVEL_ZONES.forEach((zone: string) => {
      data[zone] = { coordinates: [], pal: '' };
    });
    return { ...data };
  }, []);

  const processedData = useMemo(() => {
    if (data != null && isFeatureCollection(geoJson)) {
      const features = geoJson.features.map((feature) => {
        const pal = data.find((pal: ProjectActivityLevel) => pal.zone_id === feature.properties?.NAME);
        if (pal && feature.properties) {
          feature.properties.color = palColor(pal.color);
          // @ts-ignore
          feature.properties.pattern = determineFillPattern(pal.color);
          polygonData[feature.properties.NAME].coordinates.push(feature.geometry.coordinates);
          polygonData[feature.properties.NAME].pal = pal.color;
        }
        return { ...feature, properties: { ...feature.properties } };
      });

      return { ...geoJson, features };
    } else {
      return null;
    }
  }, [data, polygonData]);

  let centers = [];
  for (const [key, value] of Object.entries(polygonData)) {
    const center = centerOfMass(turfHelper.multiPolygon(value.coordinates)).geometry.coordinates;
    centers.push({ region: key, center: center, pal: value.pal });
  }

  if (!isFeatureCollection(processedData)) return null;

  return (
    <>
      <Source type="geojson" data={processedData}>
        <Layer
          type="fill"
          paint={{
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.7,
            'fill-outline-color': 'black',
          }}
          beforeId="settlement-minor-label"
        />
        <Layer
          type="fill"
          layout={{ 'fill-sort-key': 2 }}
          paint={{
            'fill-pattern': ['get', 'pattern'],
          }}
          beforeId="settlement-minor-label"
        />
      </Source>
      {centers.map((region) => (
        <Marker key={region.region} longitude={region.center[0]} latitude={region.center[1]} anchor="bottom">
          <SolidPinWithSubtext
            widgetName={WIDGET_NAMES.PAL}
            current={region.pal}
            innerText={palLevelShort(region.pal)}
            subText={region.region}
          />
        </Marker>
      ))}
    </>
  );
}
