import { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper as ISwiper } from 'swiper';
import styled from 'styled-components';
import { FORECAST_STATIONS_LIST } from '@sdge-web-app/shared/dist/constants';

import 'swiper/swiper.min.css';

import CarouselWithProgressDots from '../CarouselWithProgressDots';
import TweetSlide from './TweetSlide';
import ForecastSlide, { ForecastType } from './ForecastSlide';
// TODO: Reimplement this call when Twitter endpoint updates are in place
// import useTwitterData from './useTwitterData';
import { Tweet as TweetType } from '@sdge-web-app/shared/dist/types';
import { useStores } from '../../store';

const Wrapper = styled.div`
  padding: 1rem 0 0.5rem;

  & > div {
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
    padding: 0 2.5rem 1rem;
  }
`;

const stations = [
  FORECAST_STATIONS_LIST.SOB,
  FORECAST_STATIONS_LIST.MVN,
  FORECAST_STATIONS_LIST.MPE,
  FORECAST_STATIONS_LIST.CRI,
  FORECAST_STATIONS_LIST.ECJ,
  FORECAST_STATIONS_LIST.RAM,
  FORECAST_STATIONS_LIST.JUL,
  FORECAST_STATIONS_LIST.BRG,
];

const autoplayIntervalMs = 10000;

export default function TwitterAndWeatherCarousel() {
  const [secondSwiper, setSecondSwiper] = useState<ISwiper | null>(null);
  const [firstSwiper, setFirstSwiper] = useState<ISwiper | null>(null);
  const autoplayTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  // TODO: Reimplement this call when Twitter endpoint updates are in place
  // const { tweets } = useTwitterData();
  const { tweets }: { tweets: TweetType[] } = { tweets: [] };
  const { sevenDayForecastStore } = useStores();

  const intervalHandler = useCallback(() => {
    if (firstSwiper) firstSwiper.slideNext();
    if (secondSwiper) secondSwiper.slideNext();
  }, [firstSwiper, secondSwiper]);

  const removeInterval = useCallback(() => {
    if (autoplayTimerRef.current) {
      clearInterval(autoplayTimerRef.current);
    }
  }, []);

  const startInterval = useCallback(() => {
    autoplayTimerRef.current = setInterval(intervalHandler, autoplayIntervalMs);
  }, [intervalHandler]);

  const resetInterval = () => {
    removeInterval();
    startInterval();
  };

  useEffect(() => {
    startInterval();

    return removeInterval;
  }, [startInterval, removeInterval]);

  const campoForecasts = sevenDayForecastStore.data.filter((forecast) => forecast.station === 'CPO');

  return (
    <Wrapper
      className="twitter-weather-carousel"
      onMouseEnter={() => {
        removeInterval();
        setIsPaused(true);
      }}
      onMouseLeave={() => {
        resetInterval();
        setIsPaused(false);

        const activeBullets = document.querySelectorAll<HTMLElement>(
          '.twitter-weather-carousel svg.swiper-progress-bullet.swiper-pagination-bullet-active .meter',
        );

        activeBullets.forEach((bullet) => {
          bullet.style.removeProperty('animation');
          bullet.getClientRects();
          bullet.style.animation = `progress ${autoplayIntervalMs}ms linear`;
        });
      }}>
      {tweets.length > 0 && (
        <CarouselWithProgressDots
          uniqueId="twitter"
          setSwiperWhenLoaded={setFirstSwiper}
          isPaused={isPaused}
          autoplayIntervalMs={autoplayIntervalMs}>
          {tweets.map((tweet) => (
            <TweetSlide key={tweet.id} tweet={tweet} />
          ))}
        </CarouselWithProgressDots>
      )}
      {sevenDayForecastStore.data.length > 0 && (
        <CarouselWithProgressDots
          uniqueId="weather"
          setSwiperWhenLoaded={setSecondSwiper}
          isPaused={isPaused}
          autoplayIntervalMs={autoplayIntervalMs}>
          {stations.map((station) => {
            const forecast = sevenDayForecastStore.data.filter((forecast) => forecast.station === station.code);

            return forecast.length > 0 ? (
              <ForecastSlide key={`temp-${station.code}`} data={forecast} type={ForecastType.Temp} station={station} />
            ) : null;
          })}
          {campoForecasts.length > 0 && <ForecastSlide key="winds" data={campoForecasts} type={ForecastType.Wind} />}
          {campoForecasts.length > 0 && (
            <ForecastSlide key="precipitation" data={campoForecasts} type={ForecastType.Precipitation} />
          )}
        </CarouselWithProgressDots>
      )}
    </Wrapper>
  );
}
