import { WIDGET_NAMES, COLORS } from '@sdge-web-app/shared/dist/constants';
import styled from 'styled-components';
import { SolidDial } from '../../Widgets/components/SolidDial';

const Wrapper = styled.div``;

const Title = styled.h3`
  font-family: 'Interstate Condensed';
  font-size: 14px;
  font-weight: normal;
  color: ${(props) => props.theme.foregroundGray};
  text-align: center;
  margin-bottom: 30px;
`;

const Dials = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WeatherSpectrum = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(
    270deg,
    ${COLORS.middaySky} 0%,
    ${COLORS.fieldGreen} 32.29%,
    ${COLORS.electricOrange} 67.19%,
    ${COLORS.energyRed} 97.29%
  );
  transform: rotate(180deg);
`;

const Descriptions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  margin-top: 40px;

  div {
    color: ${(props) => props.theme.foregroundGray};
    max-width: calc(25% - 14px);

    @media (max-width: 620px) {
      max-width: calc(50% - 20px);
      margin-bottom: 20px;
    }
  }
`;

export default function WeatherForecastLegend() {
  return (
    <Wrapper>
      <Title>Forecast Weather Legend</Title>
      <Dials>
        <SolidDial large widgetName={WIDGET_NAMES.CURRENT_TEMP} current={40} />
        <SolidDial large widgetName={WIDGET_NAMES.CURRENT_TEMP} current={55} />
        <SolidDial large widgetName={WIDGET_NAMES.CURRENT_TEMP} current={75} />
        <SolidDial large widgetName={WIDGET_NAMES.CURRENT_TEMP} current={90} />
      </Dials>
      <WeatherSpectrum />
      <Descriptions>
        <div>
          Shading Indicates low temperatures in this region are expected to be at 54 degrees Fahrenheit or lower.
        </div>
        <div>
          Shading Indicates low temperatures in this region are expected to be at 74 degrees Fahrenheit or lower.
        </div>
        <div>
          Shading indicates Heat Illness Prevention (heat wave at or above 80F) measures are mandated by Safety Standard
          G 8366: Shade & 1 quart of cool water per hour per crew member required. Supervisors to observe for employee
          heat illnesses.
        </div>
        <div>
          High Heat (above 95F) Illness Prevention measures are mandated by Safety Standard G 8366: Pre-shift meeting to
          review preventive, cool down, and communication measures; Shade & 1 quart of cool water per hour per crew
          member required. Supervisors to observe for employee heat illnesses, or use a buddy system or regular
          communication with sole employees.
        </div>
      </Descriptions>
    </Wrapper>
  );
}
