import { ELEVATION_LEVELS, SNOWFALL_AMOUNTS } from '@sdge-web-app/shared/dist/constants';
import { ColumnTitle, ColumnWrapper, VolumeSection } from 'components/Widgets/components/SharedStyles';
import { Row, Wrapper } from './SharedStyles';
import { SnowfallWidgetWrapper, SnowVolumeWrapper } from 'components/Widgets/SnowfallForecast';
import { isSnowfallNum, shortenIfDusting } from 'utils/widgetUtils';

export default function SnowfallInfographic() {
  return (
    <Wrapper>
      Forecasted ranges for each elevation
      <Row>
        <SnowfallWidgetWrapper>
          <ColumnWrapper>
            <ColumnTitle noTextCaseChange>{ELEVATION_LEVELS[0]} ft</ColumnTitle>
            <VolumeSection>
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[1])} {isSnowfallNum(SNOWFALL_AMOUNTS[1]) && 'inch'}
              </SnowVolumeWrapper>
              —
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[3])} {isSnowfallNum(SNOWFALL_AMOUNTS[3]) && 'inch'}
              </SnowVolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle noTextCaseChange>{ELEVATION_LEVELS[1]} ft</ColumnTitle>
            <VolumeSection>
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[1])} {isSnowfallNum(SNOWFALL_AMOUNTS[1]) && 'inch'}
              </SnowVolumeWrapper>
              —
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[3])} {isSnowfallNum(SNOWFALL_AMOUNTS[3]) && 'inch'}
              </SnowVolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle noTextCaseChange>{ELEVATION_LEVELS[2]} ft</ColumnTitle>
            <VolumeSection>
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[1])} {isSnowfallNum(SNOWFALL_AMOUNTS[1]) && 'inch'}
              </SnowVolumeWrapper>
              —
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[3])} {isSnowfallNum(SNOWFALL_AMOUNTS[3]) && 'inch'}
              </SnowVolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle noTextCaseChange>{ELEVATION_LEVELS[3]} ft</ColumnTitle>
            <VolumeSection>
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[1])} {isSnowfallNum(SNOWFALL_AMOUNTS[1]) && 'inch'}
              </SnowVolumeWrapper>
              —
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[3])} {isSnowfallNum(SNOWFALL_AMOUNTS[3]) && 'inch'}
              </SnowVolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle noTextCaseChange>{ELEVATION_LEVELS[4]} ft</ColumnTitle>
            <VolumeSection>
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[1])} {isSnowfallNum(SNOWFALL_AMOUNTS[1]) && 'inch'}
              </SnowVolumeWrapper>
              —
              <SnowVolumeWrapper>
                {shortenIfDusting(SNOWFALL_AMOUNTS[3])} {isSnowfallNum(SNOWFALL_AMOUNTS[3]) && 'inch'}
              </SnowVolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
        </SnowfallWidgetWrapper>
      </Row>
    </Wrapper>
  );
}
