import { SunriseSunset } from '@sdge-web-app/shared/dist/types';
import { useStores } from 'store';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import moment from 'moment';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';
import { ColumnTitle, ColumnWrapper, VolumeWrapper, WidgetWrapper } from '../components/SharedStyles';

export const SunWidgetWrapper = styled(WidgetWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
`;

export const SunColumnWrapper = styled(ColumnWrapper)`
  font-size: 0.85rem;
`;

export const TimeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  width: 80%;
  justify-items: center;
`;

export const TimeWrapper = styled(VolumeWrapper)`
  font-size: 1rem;
`;

const SunriseSunsetContainer = observer(() => {
  const { widgetStore } = useStores();

  return (
    <SunWidgetWrapper className="sunrise-sunset">
      {widgetStore.sunData &&
        widgetStore.sunData.map((day: SunriseSunset) => (
          <SunColumnWrapper key={`sunriseSunset-${day.id}`}>
            <ColumnTitle>
              {day.day} -{' '}
              {moment(day.date_formatted, DATETIME_FORMATS.DATE_FORMAT).format(
                DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY,
              )}
            </ColumnTitle>
            <TimeSection>
              <TimeWrapper>
                <ColumnTitle>Sunrise</ColumnTitle>
                {moment(day.sunrise, 'HH:mm:ss').format(DATETIME_FORMATS.TIME_FORMAT)}
              </TimeWrapper>
              <TimeWrapper>
                <ColumnTitle>Sunset</ColumnTitle>
                {moment(day.sunset, 'HH:mm:ss').format(DATETIME_FORMATS.TIME_FORMAT)}
              </TimeWrapper>
            </TimeSection>
          </SunColumnWrapper>
        ))}
    </SunWidgetWrapper>
  );
});

export default SunriseSunsetContainer;
