import moment from 'moment-timezone';

export default function Header({ reportDate }: { reportDate: string }) {
  return (
    <table
      className="row header-row"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          className="contains-bg"
          style={{
            display: 'inline-table',
            width: '100%',
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
            background: 'url(/media/old_weather_briefings/header-bg.png) center center',
            backgroundSize: 'cover',
            height: '240px',
          }}>
          <th
            className="small-12 large-6 columns first text-middle has-logo"
            style={{
              verticalAlign: 'middle',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '297px',
              paddingRight: '8px',
              padding: '38px 0 67px',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="text-center"
                    style={{
                      textAlign: 'center',
                    }}>
                    <img
                      alt="SDG&E Logo"
                      src="/media/old_weather_briefings/logo-white.svg"
                      className="ibl"
                      style={{
                        outline: 'none',
                        textDecoration: 'none',
                        width: 'auto',
                        maxWidth: '160px',
                        clear: 'both',
                        display: 'inline-block',
                      }}
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
          <th
            className="small-12 large-6 columns last has-email-title"
            style={{
              margin: '0 auto',
              paddingLeft: '8px',
              paddingBottom: '16px',
              width: 'auto',
              paddingRight: '16px',
              padding: '38px 60px 67px 0',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th>
                    <div
                      className="left-line"
                      style={{
                        borderLeft: '1px solid #90ADC9',
                        padding: '50px 0 20px 50px',
                      }}>
                      <div>
                        <h3
                          style={{
                            color: '#FFF',
                            fontSize: '28px',
                          }}>
                          SDG&E Weather Briefing
                        </h3>
                        <h5
                          style={{
                            color: '#FFF',
                            marginBottom: '0',
                            fontSize: '20px',
                          }}>
                          {moment(reportDate).format('dddd, MMMM DD, YYYY')}
                        </h5>
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
