import styled from 'styled-components';
import moment from 'moment-timezone';

const Wrapper = styled.div`
  margin: 50px 0 100px;
  font-family: 'Interstate Condensed';
  font-size: 14px;
  text-align: center;

  & > div {
    margin: 26px;
    color: ${props => props.theme.foreground};
  }
`;

const SmallerText = styled.div`
  font-size: 12px;
`;

const SmallestText = styled.div`
  font-size: 10px;
`;

export default function Footer() {
  return (
    <Wrapper>
      <div>
        Any specific <a href="mailto:meteorology@semprautilities.com">questions or comments?</a>
      </div>
      <div>
        <a href="https://sdgeweather.com" target="_blank" rel="noreferrer">
          sdgeweather.com
        </a>
      </div>
      <SmallerText>
        SDG&E&reg; and San Diego Gas & Electric&reg; are registered trademarks of San Diego Gas & Electric Company.
      </SmallerText>
      <SmallestText>
        &copy; 1998-{moment().format('YYYY')} San Diego Gas & Electric Company. The trademarks used herein are the
        property of their respective owners. All rights reserved.
      </SmallestText>
    </Wrapper>
  );
}
