import { makeAutoObservable } from 'mobx';

class RadarStore {
  url: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setUrl(url: string) {
    this.url = url;
  }
}

export default RadarStore;
