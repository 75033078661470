import { Forecast } from '@sdge-web-app/shared/dist/types';
import { makeAutoObservable } from 'mobx';

class SevenDayForecastStore {
  data: Forecast[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setData(data: Forecast[]) {
    this.data = data;
  }
}

export default SevenDayForecastStore;
