import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/svg/close_x.svg';
import { useStores } from 'store';
import TooltipOverlay from './components/TooltipOverlay';
import { AccountOverlay } from './components/AccountOverlay';
import { observer } from 'mobx-react';
import SafetyMessageOverlay from './components/SafetyMessageOverlay';
import { QuicklinksOverlay } from './components/QuicklinksOverlay';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  position: relative;
  width: 33rem;
  max-height: 40rem;
  padding: 3rem;
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};
  border-radius: 1.875rem;
  margin: 1rem;
  display: flex;
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;

  svg {
    path {
      stroke: ${(props) => props.theme.foreground};
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

const Overlay: React.FC = observer(() => {
  const { overlayStore } = useStores();

  const createContent = () => {
    if (overlayStore.overlayType === 'tooltip') {
      return <TooltipOverlay />;
    } else if (overlayStore.overlayType === 'account') {
      return <AccountOverlay />;
    } else if (overlayStore.overlayType === 'quicklinks') {
      return <QuicklinksOverlay />;
    } else if (overlayStore.overlayType === 'Safety Message') {
      return <SafetyMessageOverlay />;
    } else {
      return <div>No content found</div>;
    }
  };

  if (!overlayStore.overlayOpen) return null;

  return (
    <Background onClick={overlayStore.close}>
      <Inner onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={overlayStore.close}>
          <CloseIcon />
        </CloseButton>
        {createContent()}
      </Inner>
    </Background>
  );
});

export default Overlay;
