import { Layer, Marker, Source } from 'react-map-gl';
import { useMemo } from 'react';
import geoJson from '../sources/sawti.json';
import { SAWTI } from '@sdge-web-app/shared/dist/types';
import centerOfMass from '@turf/center-of-mass';
import * as turfHelper from '@turf/helpers';
import { isFeatureCollection } from '../index';
import { COLORS, SAWTI_LEVEL_OBJS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from '../pins/SolidPin';
import { sawtiLevelShort } from 'utils/widgetUtils';

function getSawtiThreatColor(threatLevel: string) {
  switch (threatLevel) {
    case SAWTI_LEVEL_OBJS.none.long:
      return COLORS.slate;
    case SAWTI_LEVEL_OBJS.marginal.long:
      return COLORS.accessibleYellow;
    case SAWTI_LEVEL_OBJS.moderate.long:
      return COLORS.electricOrange;
    case SAWTI_LEVEL_OBJS.high.long:
      return COLORS.energyRed;
    case SAWTI_LEVEL_OBJS.extreme.long:
      return COLORS.purple;
    default:
      return COLORS.slate;
  }
}

function getSawtiThreatPattern(threatLevel: string) {
  switch (threatLevel) {
    case SAWTI_LEVEL_OBJS.none.long:
      return null;
    case SAWTI_LEVEL_OBJS.marginal.long:
      return 'dots2';
    case SAWTI_LEVEL_OBJS.moderate.long:
      return 'dots3';
    case SAWTI_LEVEL_OBJS.high.long:
      return 'lines';
    case SAWTI_LEVEL_OBJS.extreme.long:
      return 'lines_crossed';
    default:
      return null;
  }
}

export default function Sawti({ data }: { data?: SAWTI[] }) {
  const processedData = useMemo(() => {
    if (data != null && isFeatureCollection(geoJson)) {
      if (geoJson.features[0].properties) {
        geoJson.features[0] = { ...geoJson.features[0], properties: { ...geoJson.features[0].properties } };
        geoJson.features[0].properties.color = getSawtiThreatColor(data[0].today);
        geoJson.features[0].properties.pattern = getSawtiThreatPattern(data[0].today);
        let featurePolygon = turfHelper.polygon(geoJson.features[0].geometry.coordinates);
        // @ts-ignore
        geoJson.features[0].properties.center = centerOfMass(featurePolygon).geometry.coordinates;
        geoJson.features[0].properties.sawti = data[0].today;
      }

      return { ...geoJson, features: [...geoJson.features] };
    } else {
      return null;
    }
  }, [data]);

  if (!isFeatureCollection(processedData)) return null;

  const createMarkers = () => {
    return processedData.features.map((feature) => {
      if (feature.properties.sawti === '' || feature.properties.center.length === 0) {
        return null;
      } else {
        return (
          <Marker
            key={geoJson.features[0].properties.county}
            longitude={geoJson.features[0].properties.center[0]}
            latitude={geoJson.features[0].properties.center[1]}
            anchor="bottom">
            <SolidPin
              widgetName={WIDGET_NAMES.SAWTI}
              current={geoJson.features[0].properties.sawti}
              innerText={sawtiLevelShort(geoJson.features[0].properties.sawti)}
            />
          </Marker>
        );
      }
    });
  };

  return (
    <>
      <Source type="geojson" data={processedData}>
        <Layer
          type="fill"
          paint={{
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.7,
            'fill-outline-color': 'black',
          }}
          beforeId="settlement-minor-label"
        />
        <Layer
          type="fill"
          layout={{ 'fill-sort-key': 2 }}
          paint={{
            'fill-pattern': ['get', 'pattern'],
          }}
          beforeId="settlement-minor-label"
        />
      </Source>
      {createMarkers()}
    </>
  );
}
