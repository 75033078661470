import styled from 'styled-components';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { FunctionComponent } from 'react';

const InputBoxWrapper = styled.input<{
  margin?: string;
  bgcolor?: string;
  border?: string;
  textAlign?: string;
  fontSize?: string;
  fontWeight?: string;
}>`
  width: ${(props) => (props.width ? props.width : null)};
  height: 34px;
  padding: 6px 12px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : null)};
  line-height: 1.42857143;
  color: ${(props) => (props.color ? props.color : '#555')};
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : COLORS.menuBackground)};
  background-image: none;
  border: ${(props) => (props.border ? props.border : `1px solid ${COLORS.dropdownBorder}`)};
  margin: ${(props) => (props.margin ? props.margin : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : null)};
`;

type InputBoxTypes = {
  value?: string | number;
  defaultValue?: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, fieldToUpdate: string, id?: string | number) => void;
  id?: string | number;
  fieldType?: string;
  fieldToUpdate: string;
  fontSize?: string;
  fontWeight?: string;
  width?: string;
  color?: string;
  bgcolor?: string;
  border?: string;
  textAlign?: string;
  margin?: string;
  autofocus?: boolean;
  className?: string; // Required for Styled Components override: https://styled-components.com/docs/api#caveat-with-classname
  'data-cy'?: string;
};

export const InputBox: FunctionComponent<InputBoxTypes> = (props: InputBoxTypes) => (
  <InputBoxWrapper
    value={props.value}
    defaultValue={props.defaultValue}
    type={props.fieldType}
    onChange={(evt) => props.onChange(evt, props.fieldToUpdate, props.id)}
    className={props.className}
    width={props.width}
    color={props.color}
    bgcolor={props.bgcolor}
    border={props.border}
    textAlign={props.textAlign}
    margin={props.margin}
    fontSize={props.fontSize}
    fontWeight={props.fontWeight}
    autoFocus={props.autofocus}
    data-cy={props['data-cy']}
  />
);
