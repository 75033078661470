import { Route, Routes } from 'react-router-dom';

import {
  AQI,
  FPI,
  OPI,
  WIDGETS,
  HUMIDITY,
  OBSERVATIONS,
  PROJECT_ACTIVITY_LEVEL,
  FORECAST,
  RAINFALL,
  RED_FLAG,
  SAFETY_MESSAGE,
  SAWTI,
  SNOWFALL,
  SUNRISE_SUNSET,
  TEMPERATURES,
  LOW_TEMP,
  THUNDERSTORM_THREAT,
  TIDES,
  WINDS,
  WINDS_STREAMLINES,
  WINDS_TOP_CURRENT,
  WIND_GUSTS,
  WINTER_STORM_THREAT,
  PSPS,
  API,
  WEATHER_BRIEFING,
} from '@sdge-web-app/shared/src/constants/paths';

import { WINDS_TOP_MAX_ENDPOINT } from './constants';
import Aqi from 'components/Aqi';
import Fpi from 'components/Widgets/Fpi';
import Opi from 'components/Widgets/Opi';
import Widgets from 'components/Widgets';
import Observations from 'components/Api/Observations';
import Winds from 'components/Api/Winds';
import CurrentGusts from 'components/Api/Winds/current-gusts';
import WindGustForecast from 'components/Widgets/WindGustForecast';
import MaxGusts from 'components/Api/Winds/max-gusts';
import Streamlines from 'components/Api/Winds/streamlines';
import Temperatures from 'components/Widgets/Temperatures';
import SevenDayForecast from 'components/SevenDayForecast';
import LowTempForecast from 'components/Widgets/LowTempForecast';
import Humidity from 'components/Widgets/Humidity';
import SunriseSunset from 'components/Widgets/SunriseSunset';
import Tides from 'components/Api/Tides';
import RedFlagWarnings from 'components/Widgets/RedFlagWarnings';
import RainfallForecastContainer from 'components/Widgets/RainfallForecast';
import SnowfallForecastContainer from 'components/Widgets/SnowfallForecast';
import SawtiContainer from 'components/Widgets/Sawti';
import ThunderstormThreat from 'components/Widgets/ThunderstormThreats';
import SafetyMessageContainer from 'components/SafetyMessage';
import ProjectActivityLevelContainer from 'components/Widgets/ProjectActivityLevel';
import WinterStormThreatContainer from 'components/Widgets/WinterStormThreat';
import PSPSContainer from 'components/Widgets/PSPS';
import WeatherBriefingSearch from 'components/WeatherBriefingSearch';
import WeatherBriefingById from 'components/WeatherBriefingById';
import Api from 'components/Api';
import Home from 'components/Home';
import DialEmporium from 'components/Api/DialEmporium';
import MapPins from 'components/Api/MapPins';

export default function Router() {
  return (
    <Routes>
      <Route path={`/${AQI}`} element={<Aqi />} />
      <Route path={`/${FPI}`} element={<Fpi />} />
      <Route path={`/${OPI}`} element={<Opi />} />
      <Route path={`/${WIDGETS}`} element={<Widgets />} />
      <Route path={`/${OBSERVATIONS}`} element={<Observations />} />
      <Route path={`/${WINDS}`} element={<Winds />} />
      <Route path={`/${WINDS_TOP_CURRENT}`} element={<CurrentGusts />} />
      <Route path={`/${WIND_GUSTS}`} element={<WindGustForecast />} />
      <Route path={`/${WINDS_TOP_MAX_ENDPOINT}`} element={<MaxGusts />} />
      <Route path={`/${WINDS_STREAMLINES}`} element={<Streamlines />} />
      <Route path={`/${TEMPERATURES}`} element={<Temperatures />} />
      <Route path={`/${FORECAST}`} element={<SevenDayForecast />} />
      <Route path={`/${LOW_TEMP}`} element={<LowTempForecast />} />
      <Route path={`/${HUMIDITY}`} element={<Humidity />} />
      <Route path={`/${SUNRISE_SUNSET}`} element={<SunriseSunset />} />
      <Route path={`/${TIDES}`} element={<Tides />} />
      <Route path={`/${RED_FLAG}`} element={<RedFlagWarnings />} />
      <Route path={`/${RAINFALL}`} element={<RainfallForecastContainer />} />
      <Route path={`/${SNOWFALL}`} element={<SnowfallForecastContainer />} />
      <Route path={`/${SAWTI}`} element={<SawtiContainer />} />
      <Route path={`/${THUNDERSTORM_THREAT}`} element={<ThunderstormThreat />} />
      <Route path={`/${SAFETY_MESSAGE}`} element={<SafetyMessageContainer />} />
      <Route path={`/${PROJECT_ACTIVITY_LEVEL}`} element={<ProjectActivityLevelContainer />} />
      <Route path={`/${WINTER_STORM_THREAT}`} element={<WinterStormThreatContainer />} />
      <Route path={`/${PSPS}`} element={<PSPSContainer />} />
      <Route path={`/${WEATHER_BRIEFING}`} element={<WeatherBriefingSearch />} />
      <Route path={`/${WEATHER_BRIEFING}/:id`} element={<WeatherBriefingById />} />
      <Route path={`/${API}`} element={<Api />} />
      <Route path={`/dials`} element={<DialEmporium />} />
      <Route path={`/pins`} element={<MapPins />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
