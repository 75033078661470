import styled from 'styled-components';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';
import { ColumnTitle } from 'components/Widgets/components/SharedStyles';
import { Row, Wrapper } from './SharedStyles';
import { SunColumnWrapper, TimeSection, TimeWrapper } from 'components/Widgets/SunriseSunset';
import moment from 'moment';

const SunColumnInfo = styled(SunColumnWrapper)`
  font-family: Interstate Condensed;
  text-transform: uppercase;
  width: 70%;
`;

export default function SunInfographic() {
  return (
    <Wrapper>
      Times for current day and next day
      <Row>
        <SunColumnInfo key={`sunriseSunset-infographic`}>
          <ColumnTitle>today - {moment().format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER_DAY_ONLY)}</ColumnTitle>
          <TimeSection>
            <TimeWrapper>
              <ColumnTitle>Sunrise</ColumnTitle>
              05:50
            </TimeWrapper>
            <TimeWrapper>
              <ColumnTitle>Sunset</ColumnTitle>
              19:58
            </TimeWrapper>
          </TimeSection>
        </SunColumnInfo>
      </Row>
    </Wrapper>
  );
}
