import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DropdownComponent, DropdownDate } from 'react-dropdown-date';
import moment from 'moment-timezone';
import styled from 'styled-components';

import WeatherBriefingArchived from '../WeatherBriefingArchived';
import WeatherBriefingArchivedOld from '../WeatherBriefingArchivedOld';
import arrowDownIcon from 'assets/svg/arrow_down.svg';
import { useStores } from 'store';
import { observer } from 'mobx-react-lite';

const formatDate = (date: Date) => moment(date).format('YYYY/MM/DD');

const BackLink = styled.a`
  color: ${(props) => props.theme.foregroundGray};
  display: inline-block;
  padding: 20px 24px;
  font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1140px) {
    display: none;
  }
`;

const TopWrapper = styled.div`
  padding: 12px;
  border-bottom: 1px solid #ccc;
  max-width: 900px;
  margin: 0 auto 40px;

  @media (max-width: 480px) {
    border: none;
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 40px;
  color: ${(props) => props.theme.foregroundGray};

  @media (max-width: 480px) {
    display: none;
  }
`;

const SelectionWrapper = styled.div`
  #dropdown-date {
    display: flex;
    justify-content: space-between;
  }

  select {
    color: ${(props) => props.theme.foregroundGray};
    appearance: none;
    border: none;
    padding: 8px 34px 8px 0;
    font-size: 18px;
    font-weight: bold;
    background-color: transparent;
    background-image: url(${arrowDownIcon});
    background-repeat: no-repeat, repeat;
    background-position: right 6px top 50%;
  }
`;

export const Loading = styled.div`
  margin: 24px;
  text-align: center;
`;

export const NotFound = styled.div`
  margin: 24px;
  text-align: center;
  color: ${(props) => props.theme.foreground};
`;

function WeatherBriefingSearch() {
  const navigate = useNavigate();
  const { weatherBriefingStore } = useStores();
  const [dataLoadedOnce, setDataLoadedOnce] = useState<boolean>(false);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<string>(moment().format('YYYY/MM/DD'));
  useEffect(() => {
    // Only do this once. We don't want the page to reset to current brief if data is refreshed in the background
    if (!dataLoadedOnce && !weatherBriefingStore.briefLoading) {
      setDate(weatherBriefingStore.data?.report_date);
      setSelectedDate(moment(weatherBriefingStore.data?.report_date).format('YYYY/MM/DD'));
      setDataLoadedOnce(true);
    }
  }, [weatherBriefingStore.data, weatherBriefingStore.briefLoading, dataLoadedOnce]);

  return (
    <div>
      {dataLoadedOnce && (
        <>
          <BackLink
            data-cy="briefing-back"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}>
            Back
          </BackLink>
          <TopWrapper>
            <Heading>Search Briefings</Heading>
            <SelectionWrapper>
              <DropdownDate
                startDate={'2016/08/19'}
                selectedDate={selectedDate}
                onDateChange={(date: Date) => {
                  setDate(date);
                  setSelectedDate(formatDate(date));
                }}
                order={[DropdownComponent.month, DropdownComponent.day, DropdownComponent.year]}
              />
            </SelectionWrapper>
          </TopWrapper>
          <div>
            {selectedDate > '2023/08/09' ? (
              <WeatherBriefingArchived date={moment(date).format('YYYY-MM-DD')} />
            ) : (
              <WeatherBriefingArchivedOld date={moment(date).format('YYYY-MM-DD')} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default observer(WeatherBriefingSearch);
