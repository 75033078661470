import { Marker } from 'react-map-gl';
import { useMemo } from 'react';
import { useStores } from 'store';
import { StationTemp } from '@sdge-web-app/shared/dist/types';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';

export default function CurrentTemp() {
  const { widgetStore } = useStores();
  const currentTempMarkers = useMemo(() => {
    const markers: StationTemp[] = [];

    if (widgetStore.currentTempData) {
      widgetStore.currentTempData.stations.forEach((station: StationTemp) => {
        markers.push(station);
      });
    }

    return markers;
  }, [widgetStore.currentTempData]);

  return (
    <>
      {currentTempMarkers.map((marker) => (
        <Marker key={marker.station} longitude={marker.lon} latitude={marker.lat} anchor="bottom">
          <SolidPinWithSubtext
            widgetName={WIDGET_NAMES.CURRENT_TEMP}
            current={marker.temp ?? 'N/A'}
            subText={marker.common_name}
          />
        </Marker>
      ))}
    </>
  );
}
