import { COLORS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';

export function generateGradient(widgetName?: string, min?: number, current?: number, max?: number) {
  if (widgetName) {
    switch (widgetName) {
      case WIDGET_NAMES.CURRENT_TEMP:
        if (min && max) {
          // console.log(`Min: ${min}, Current: ${current}, Max: ${max}`);
          if (min < 50) {
            if (max < 50) {
              return COLORS.middaySky;
            } else if (max >= 50 && max < 80) {
              return `conic-gradient(
              from 180deg,
              ${COLORS.middaySky} 30%,
              ${COLORS.fieldGreen} 70%
            )`;
            } else if (max >= 80 && max < 110) {
              return `conic-gradient(
              from 180deg,
              ${COLORS.middaySky} 20%,
              ${COLORS.fieldGreen} 50%,
              ${COLORS.electricOrange} 70%
            )`;
            } else {
              return `conic-gradient(
              from 180deg,
              ${COLORS.middaySky} 17%,
              ${COLORS.fieldGreen} 42%,
              ${COLORS.electricOrange} 67%,
              ${COLORS.energyRed} 92%
            )`;
            }
          }
          if (min >= 50 && min < 80) {
            if (max >= 50 && max < 80) {
              return COLORS.fieldGreen;
            } else if (max >= 80 && max < 110) {
              return `conic-gradient(
              from 180deg,
              ${COLORS.fieldGreen} 30%,
              ${COLORS.electricOrange} 70%
            )`;
            } else {
              return `conic-gradient(
              from 180deg,
              ${COLORS.fieldGreen} 20%,
              ${COLORS.electricOrange} 50%,
              ${COLORS.energyRed} 70%
            )`;
            }
          }
          if (min >= 80 && min < 110) {
            if (max >= 80 && max < 110) {
              return COLORS.electricOrange;
            } else {
              return `conic-gradient(
              from 180deg,
              ${COLORS.electricOrange} 30%,
              ${COLORS.energyRed} 70%
            )`;
            }
          }
          if (min >= 110) {
            return COLORS.energyRed;
          }
        } else {
          return `conic-gradient(
            from 180deg,
            ${COLORS.middaySky} 17%,
            ${COLORS.fieldGreen} 42%,
            ${COLORS.electricOrange} 67%,
            ${COLORS.energyRed} 92%
          )`;
        }
        break;
      default:
        return `conic-gradient(
          from 180deg,
          #ff4800 10%,
          #dfd902 35%,
          #20dc68,
          #0092f4,
          #da54d8 72% 75%,
          #ff4800 95%
        )`;
    }
  }
  return `conic-gradient(
    from 180deg,
    #ff4800 10%,
    #dfd902 35%,
    #20dc68,
    #0092f4,
    #da54d8 72% 75%,
    #ff4800 95%
  )`;
}
