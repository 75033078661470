import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Pagination, Swiper as ISwiper } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

import { COLORS } from '@sdge-web-app/shared/dist/constants';

const Wrapper = styled.div`
  .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin-top: 1rem;
  }

  svg.swiper-progress-bullet {
    width: 0.625rem;
    height: auto;
    cursor: pointer;
    border-radius: 50%;

    .bg {
      fill: none;
      stroke-width: 50px;
      stroke: ${COLORS.slate};
    }

    .meter {
      fill: none;
      stroke-width: 50px;
      stroke-linecap: butt;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      stroke-dasharray: 360;
      stroke-dashoffset: 360;
      stroke: black;
    }

    @keyframes progress {
      from {
        stroke-dashoffset: 360;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`;

interface Props {
  children: ReactNode;
  uniqueId: string;
  setSwiperWhenLoaded: (swiper: ISwiper) => void;
  isPaused: boolean;
  autoplayIntervalMs?: number;
}

const CarouselWithProgressDots: React.FC<Props> = ({
  children,
  uniqueId,
  setSwiperWhenLoaded,
  isPaused,
  autoplayIntervalMs = 10000,
}) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return `
          <svg class="swiper-progress-bullet ${className} circle-${index}" viewBox="0 0 114 114">
            <circle class="bg" cx="57" cy="57" r="57" />
            <circle class="meter" cx="57" cy="57" r="57" />
          </svg>
        `;
    },
  };

  return (
    <Wrapper
      className={`${uniqueId}-carousel`}
      onMouseEnter={() => {
        const bullets = document.querySelectorAll<HTMLElement>('svg.swiper-progress-bullet .meter');

        bullets.forEach((bullet) => {
          bullet.style.removeProperty('animation');
          bullet.getClientRects();
        });
      }}>
      <Swiper
        loop
        spaceBetween={50}
        slidesPerView={1}
        onRealIndexChange={(swiper) => {
          if (isPaused) return;

          const bullets = document.querySelectorAll<HTMLElement>(
            `.${uniqueId}-carousel svg.swiper-progress-bullet .meter`,
          );
          bullets.forEach((bullet) => {
            bullet.style.removeProperty('animation');
          });

          const bullet = bullets[swiper.realIndex];
          if (bullet) {
            bullet.style.animation = `progress ${autoplayIntervalMs}ms linear`;
            bullet.getClientRects();
          }
        }}
        onPaginationShow={console.log}
        onSwiper={(swiper) => {
          setSwiperWhenLoaded(swiper);

          const firstBullet = document.querySelector<HTMLElement>(
            `.${uniqueId}-carousel svg.swiper-progress-bullet:first-child .meter`,
          );

          if (firstBullet) {
            firstBullet.style.removeProperty('animation');
            firstBullet.getClientRects();
            firstBullet.style.animation = `progress ${autoplayIntervalMs}ms linear`;
          }
        }}
        pagination={pagination}
        modules={[Pagination]}>
        {children != null && React.Children.map(children, (child) => <SwiperSlide>{child}</SwiperSlide>)}
      </Swiper>
    </Wrapper>
  );
};

export default CarouselWithProgressDots;
