export function fpiColor(fpiLevel: number) {
  if (fpiLevel <= 11) {
    return '#58B947';
  } else if (fpiLevel >= 12 && fpiLevel <= 13) {
    return '#F8DF4F';
  } else if (fpiLevel === 14) {
    return '#FF9400';
  } else if (fpiLevel >= 15) {
    return '#C8003E';
  } else {
    return 'black';
  }
}

const utils = {
  fpiColor,
};

export default utils;
