import { observer } from 'mobx-react-lite';
import { useStores } from 'store';
import styled from 'styled-components';

const Bar = styled.div`
  position: absolute;
  left: 0;
  bottom: -0.125rem;
  height: 0.125rem;
  background-color: ${props => props.theme.foreground};
`;

const ProgressBar = observer(() => {
  const { mapStateStore } = useStores();
  const progress = mapStateStore.progress;

  return (
    <Bar
      style={{
        width: progress * 100 + '%',
        transition: progress === 0 ? 'none' : 'width 1s linear',
      }}
    />
  );
});

export default ProgressBar;
