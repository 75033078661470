import { TIME_FRAMES, RAINFALL_AMOUNTS } from '@sdge-web-app/shared/dist/constants';
import { ColumnTitle, ColumnWrapper, VolumeSection, VolumeWrapper } from 'components/Widgets/components/SharedStyles';
import { isNum } from 'utils/widgetUtils';
import { ForecastTimes, ForecastVolumes, Row, Wrapper } from './SharedStyles';

export default function RainfallInfographic() {
  return (
    <Wrapper>
      Forecast timeframes
      <Row>
        <ForecastTimes>
          <ColumnWrapper>
            <ColumnTitle>Start</ColumnTitle>
            {TIME_FRAMES[5]}
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>End</ColumnTitle>
            {TIME_FRAMES[7]}
          </ColumnWrapper>
        </ForecastTimes>
      </Row>
      Forecasted ranges for each region
      <Row>
        <ForecastVolumes>
          <ColumnWrapper>
            <ColumnTitle>Beach</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[1]} {isNum(RAINFALL_AMOUNTS[1]) && 'inch'}
              </VolumeWrapper>
              ⁠—
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[3]} {isNum(RAINFALL_AMOUNTS[3]) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Inland</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[3]} {isNum(RAINFALL_AMOUNTS[3]) && 'inch'}
              </VolumeWrapper>
              ⁠—
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[5]} {isNum(RAINFALL_AMOUNTS[5]) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle>Mountain</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[6]} {isNum(RAINFALL_AMOUNTS[6]) && 'inch'}
              </VolumeWrapper>
              ⁠—
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[8]} {isNum(RAINFALL_AMOUNTS[8]) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
          <ColumnWrapper>
            <ColumnTitle> Desert</ColumnTitle>
            <VolumeSection>
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[0]} {isNum(RAINFALL_AMOUNTS[0]) && 'inch'}
              </VolumeWrapper>
              ⁠—
              <VolumeWrapper>
                {RAINFALL_AMOUNTS[0]} {isNum(RAINFALL_AMOUNTS[0]) && 'inch'}
              </VolumeWrapper>
            </VolumeSection>
          </ColumnWrapper>
        </ForecastVolumes>
      </Row>
    </Wrapper>
  );
}
