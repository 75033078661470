import styled from 'styled-components';
import { Subtext, SubtextWrapper } from './SharedStyles';
import { COLORS } from '@sdge-web-app/shared/dist/constants';
import { BottomTriangle } from './Triangle';
import moment from 'moment';

const StartStop = styled.span`
  color: ${COLORS.gray};
`;

const TimeSubtext = styled(Subtext)`
  word-spacing: 0.1rem;
  line-height: 0.75rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  justify-items: start;
  grid-column-gap: 0.5rem;
`;

type PinProps = {
  widgetName: string;
  current: string | number | boolean;
  innerText?: string;
  start: Date;
  stop: Date;
};

export function PinWithTimes({ start, stop }: PinProps) {
  return (
    <SubtextWrapper>
      <TimeSubtext>
        <StartStop>START</StartStop> {moment(start).format('ddd MM/DD HH:mm')}
        <StartStop>STOP</StartStop> {moment(stop).format('ddd MM/DD HH:mm')}
      </TimeSubtext>
      {BottomTriangle()}
    </SubtextWrapper>
  );
}
