import { Layer, Source } from 'react-map-gl';
import geoJson from '../sources/tielines.json';
import { isFeatureCollection } from '../index';
import { COLORS } from '@sdge-web-app/shared/dist/constants';

function colorTieLine(voltage: number) {
  switch (voltage) {
    case 138:
      return COLORS.energyRed;
    case 230:
      return COLORS.purple;
    case 500:
      return COLORS.electricOrange;
    default:
      return COLORS.middaySky;
  }
}

export default function TieLines() {
  if (isFeatureCollection(geoJson)) {
    geoJson.features.forEach((feature) => {
      if (feature.properties) {
        // @ts-ignore
        feature.properties.color = colorTieLine(feature.properties.VoltageCla);
      }
    });
  } else {
    return null;
  }

  return (
    <Source type="geojson" data={geoJson}>
      <Layer
        type="line"
        layout={{ 'line-sort-key': 9 }}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': 1,
        }}
        beforeId="settlement-minor-label"
      />
    </Source>
  );
}
