import { Layer, Marker, Source } from 'react-map-gl';
import { useMemo } from 'react';
import geoJson from '../sources/districts.json';
import { OPIRegions } from '@sdge-web-app/shared/dist/types';
import { getOpiLevelColor } from '@sdge-web-app/shared/dist/utils';
import { isFeatureCollection } from '../index';
import { DISTRICTS_LIST, OPI_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import centerOfMass from '@turf/center-of-mass';
import * as turfHelper from '@turf/helpers';
import { opiLevelShort } from 'utils/widgetUtils';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

function determineFillPattern(value: string) {
  if (value === OPI_LEVELS.normal.long) {
    return 'dots1';
  } else if (value === OPI_LEVELS.elevated.long) {
    return 'dots2';
  } else if (value === OPI_LEVELS.extreme.long) {
    return 'lines';
  }

  return 'dots1';
}

export default function Opi({ data }: { data?: OPIRegions[] }) {
  const processedData = useMemo(() => {
    if (data != null && data.length > 0 && isFeatureCollection(geoJson)) {
      const features = geoJson.features.map((feature) => {
        const region = data.find((region: OPIRegions) => {
          const district = DISTRICTS_LIST.find((d) => d.code.toUpperCase() === region.region);
          return district?.id === feature.properties?.id;
        });

        if (region !== undefined && feature.properties) {
          feature.properties.color = getOpiLevelColor(region.level);
          // @ts-ignore
          feature.properties.pattern = determineFillPattern(region.level);
          // @ts-ignore
          feature.properties.opi = region.level;
          // @ts-ignore
          feature.properties.pattern = determineFillPattern(region.level ?? 0);

          let featurePolygon = turfHelper.polygon(feature.geometry.coordinates);
          // @ts-ignore
          feature.properties.center = centerOfMass(featurePolygon).geometry.coordinates;
        }

        return { ...feature, properties: { ...feature.properties } };
      });

      return { ...geoJson, features };
    } else {
      return null;
    }
  }, [data]);

  if (!isFeatureCollection(processedData)) return null;

  const createMarkers = () => {
    return processedData.features.map((feature) => {
      if (feature.properties.opi === '' || feature.properties.center.length === 0) {
        return null;
      } else {
        return (
          <Marker
            key={feature.properties.id}
            longitude={feature.properties.center[0]}
            latitude={feature.properties.center[1]}
            anchor="bottom">
            <SolidPinWithSubtext
              widgetName={WIDGET_NAMES.OPI}
              current={feature.properties.opi}
              innerText={opiLevelShort(feature.properties.opi)}
              subText={feature.properties.title}
            />
          </Marker>
        );
      }
    });
  };

  return (
    <>
      <Source type="geojson" data={processedData}>
        <Layer
          type="fill"
          paint={{
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.7,
            'fill-outline-color': 'black',
          }}
          beforeId="settlement-minor-label"
        />
        <Layer
          type="fill"
          layout={{ 'fill-sort-key': 2 }}
          paint={{
            'fill-pattern': ['get', 'pattern'],
          }}
          beforeId="settlement-minor-label"
        />
      </Source>
      {createMarkers()}
    </>
  );
}
