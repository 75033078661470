import { WeatherBriefing } from '@sdge-web-app/shared/dist/types';
import { makeAutoObservable } from 'mobx';

class WeatherBriefingStore {
  data?: WeatherBriefing;
  briefLoading: boolean = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setData(data: WeatherBriefing) {
    this.data = data;
    this.briefLoading = false;
  }

  setBriefLoadingEnd() {
    this.briefLoading = false;
  }
}

export default WeatherBriefingStore;
