import { useEffect, useState } from 'react';
import { WIDGET_NAMES, CONFIG_KEYS } from '@sdge-web-app/shared/dist/constants';
import { useStores } from 'store';
import {
  FPI_REGION_ENDPOINT,
  FPI_SEVEN_DAY_ENDPOINT,
  FPI_TIME_OF_DAY_ENDPOINT,
  HUMIDITY_ENDPOINT,
  LOW_TEMP_ENDPOINT,
  OPI_REGION_ENDPOINT,
  OPI_SEVEN_DAY_ENDPOINT,
  PROJECT_ACTIVITY_LEVEL_ENDPOINT,
  PSPS_ENDPOINT,
  RAINFALL_FORECAST_ENDPOINT,
  RED_FLAG_WARNINGS_ENDPOINT,
  SAWTI_ENDPOINT,
  SNOWFALL_FORECAST_ENDPOINT,
  SUNRISE_SUNSET_ENDPOINT,
  TEMPERATURES_ENDPOINT,
  THUNDERSTORM_THREAT_ENDPOINT,
  WINDS_CURRENT_GUSTS_ENDPOINT,
  WINDS_FORECASTED_GUSTS_ENDPOINT,
  WINDS_TOP_CURRENT_ENDPOINT,
  WIND_GUSTS_ENDPOINT,
  WINTER_STORM_ENDPOINT,
} from '../constants';
import { getApiData } from '../utils/axios';


export default function useWidgetDataLoader(widgetArray: { name: string }[]): {
  errors: unknown[];
} {
  const [errors, setErrors] = useState<unknown[]>([]);
  const { widgetStore, windsStore } = useStores();

  useEffect(() => {
    let tempErrors: unknown[] = [];
    const determineEndpoint = async (widgetName: string) => {
      switch (widgetName) {
        case WIDGET_NAMES.LOW_TEMP:
          await getApiData(LOW_TEMP_ENDPOINT)
            .then((response) => {
              widgetStore.setLowTempData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.PAL:
          await getApiData(PROJECT_ACTIVITY_LEVEL_ENDPOINT)
            .then((response) => {
              widgetStore.setPalData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.PSPS:
          await getApiData(PSPS_ENDPOINT)
            .then((response) => {
              widgetStore.setPspsData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.RAINFALL:
          await getApiData(RAINFALL_FORECAST_ENDPOINT)
            .then((response) => {
              widgetStore.setRainfallData(response.data[0]);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.RED_FLAG:
          await getApiData(RED_FLAG_WARNINGS_ENDPOINT)
            .then((response) => {
              widgetStore.setRedFlagData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.SNOWFALL:
          await getApiData(SNOWFALL_FORECAST_ENDPOINT)
            .then((response) => {
              widgetStore.setSnowfallData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.SAWTI:
          await getApiData(SAWTI_ENDPOINT)
            .then((response) => {
              widgetStore.setSawtiData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.SUN:
          await getApiData(SUNRISE_SUNSET_ENDPOINT)
            .then((response) => {
              widgetStore.setSunData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.THUNDERSTORM:
          await getApiData(THUNDERSTORM_THREAT_ENDPOINT)
            .then((response) => {
              widgetStore.setThunderstormData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.WIND_GUST_FORECAST:
          await getApiData(WIND_GUSTS_ENDPOINT)
            .then((response) => {
              widgetStore.setWindGustData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          await getApiData(WINDS_FORECASTED_GUSTS_ENDPOINT)
            .then((response) => {
              windsStore.setForecastedGusts(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.WINTER_STORM:
          await getApiData(WINTER_STORM_ENDPOINT)
            .then((response) => {
              widgetStore.setWinterStormData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.FPI:
          await getApiData(FPI_REGION_ENDPOINT)
            .then((response) => {
              widgetStore.setRegionFpiData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          await getApiData(FPI_SEVEN_DAY_ENDPOINT)
            .then((response) => {
              widgetStore.setSevenDayFpiData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          await getApiData(FPI_TIME_OF_DAY_ENDPOINT)
            .then((response) => {
              widgetStore.setFpiTimeOfDay(response.data[`${CONFIG_KEYS.fpiTimeOFDay}`].data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.OPI:
          await getApiData(OPI_REGION_ENDPOINT)
            .then((response) => {
              widgetStore.setRegionOpiData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          await getApiData(OPI_SEVEN_DAY_ENDPOINT)
            .then((response) => {
              widgetStore.setSevenDayOpiData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.CURRENT_TEMP:
          await getApiData(TEMPERATURES_ENDPOINT)
            .then((response) => {
              widgetStore.setCurrentTempData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.CURRENT_RH:
          await getApiData(HUMIDITY_ENDPOINT)
            .then((response) => {
              widgetStore.setCurrentRhData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        case WIDGET_NAMES.TOP_CURRENT_GUSTS:
          await getApiData(WINDS_TOP_CURRENT_ENDPOINT)
            .then((response) => {
              widgetStore.setTopWindGustData(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          await getApiData(WINDS_CURRENT_GUSTS_ENDPOINT)
            .then((response) => {
              windsStore.setCurrentGusts(response.data);
            })
            .catch((error) => {
              tempErrors.push(error);
              setErrors(tempErrors);
            });
          break;
        default:
          tempErrors.push(`Unknown Widget: ${widgetName}`);
          setErrors(tempErrors);
          break;
      }
    };
    Promise.all(
      widgetArray.map(async (widget) => {
        await determineEndpoint(widget.name);
      }),
    );
  }, [widgetArray, widgetStore, windsStore]);
  return { errors };
}
