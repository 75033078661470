import { useState, useEffect } from 'react';
import { AQI_ENDPOINT } from '../../constants';
import styled from 'styled-components';
import moment from 'moment-timezone';
import axios from 'axios';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';

const CityWrapper = styled.div`
  margin: 1rem;
  width: 90%;
  border-bottom: 0.0625rem solid black;
`;

const ForecastWrapper = styled.div`
  margin: 1rem 0;
`;

const ObservationWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Observation = styled.div`
  margin: 1rem;
  padding: 0.25rem;
  background: linen;
`;

function Aqi() {
  const [aqiData, setData] = useState([]);
  const [aqiDataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    if (!aqiDataRetreived) {
      // Make a request for the AQI data
      axios
        .get(`${AQI_ENDPOINT}?startDate=08/06/2021`)
        .then(function (response) {
          // handle success
          setData(response.data);
          setDataRetreived(true);
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        });
    }
  }, [aqiDataRetreived]);

  return (
    <div className="Aqi">
      <h2>AQI data:</h2>

      {aqiData &&
        aqiData.map((city: any) => (
          <CityWrapper key={`aqiData-${city.id}`}>
            <div>
              <div>
                <b>City Name : {city.city}</b>
              </div>
              <div>
                <b>Zipcode : {city.zipcode}</b>
              </div>
              <div>Latitiude : {city.lat}</div>
              <div>Longitude : {city.long}</div>
            </div>
            <ForecastWrapper>
              <div>
                <b>
                  Forecast :{' '}
                  {moment(city.forecast.forecast_date).isValid()
                    ? moment(city.forecast.forecast_date).format(DATETIME_FORMATS.DATE_FORMAT)
                    : 'No forecast found'}
                </b>
              </div>
              <div>
                pm25_aqi : {city.forecast.pm25_aqi || 'N/A'}, {city.forecast.pm25_categoryname}
              </div>
              <div>
                o3_aqi : {city.forecast.o3_aqi || 'N/A'}, {city.forecast.o3_categoryname}
              </div>
            </ForecastWrapper>
            <div>
              <b>Observations :</b>
            </div>
            <ObservationWrapper>
              {city.observations.map((observation: any) => (
                <Observation key={observation.date_observed}>
                  <div>Date Observed : {moment(observation.date_observed).format(DATETIME_FORMATS.DATE_FORMAT)}</div>
                  <div>
                    Avg pm25_aqi : {observation.avg_pm25_aqi}, {observation.avg_pm25_aqi_category}
                  </div>
                  <div>
                    Avg o3_aqi : {observation.avg_o3_aqi}, {observation.avg_o3_aqi_category}
                  </div>
                  <div>
                    Max pm25_aqi : {observation.max_pm25_aqi}, {observation.max_pm25_aqi_category}
                  </div>
                  <div>
                    Max o3_aqi : {observation.max_o3_aqi}, {observation.max_o3_aqi_category}
                  </div>
                  <div>
                    Min pm25_aqi : {observation.min_pm25_aqi}, {observation.min_pm25_aqi_category}
                  </div>
                  <div>
                    Min o3_aqi : {observation.min_o3_aqi}, {observation.min_o3_aqi_category}
                  </div>
                </Observation>
              ))}
            </ObservationWrapper>
          </CityWrapper>
        ))}
    </div>
  );
}

export default Aqi;
