import { Marker } from 'react-map-gl';
import { useMemo } from 'react';
import { useStores } from 'store';
import { StationGustData } from '@sdge-web-app/shared/dist/types';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from '../pins/SolidPinWithSubtext';

export default function ForecastedGusts() {
  const { windsStore } = useStores();
  const windMarkers = useMemo(() => {
    const markers: StationGustData[] = [];

    if (windsStore.forecastedGusts) {
      windsStore.forecastedGusts.forEach((station: StationGustData) => {
        if (station.lat && station.lon) {
          markers.push(station);
        }
      });
    }

    return markers;
  }, [windsStore.forecastedGusts]);

  return (
    <>
      {windMarkers.map((marker) => {
        let name = marker.common_name.split('(')[0];
        return (
          <Marker key={marker.common_name} longitude={marker.lon} latitude={marker.lat} anchor="bottom">
            <SolidPinWithSubtext
              widgetName={WIDGET_NAMES.WIND_GUST_FORECAST}
              current={marker.gust}
              subText={name}
              withBorder
            />
          </Marker>
        );
      })}
    </>
  );
}
