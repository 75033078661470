import { useStores } from 'store';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';
import styled from 'styled-components';
import { SolidDial } from '../components/SolidDial';
import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { opiLevelShort } from 'utils/widgetUtils';
import { DialWrapper, DialTitle, WidgetWrapper } from '../components/SharedStyles';
import { OPISevenDay } from '@sdge-web-app/shared/dist/types';

const OpiTitle = styled(DialTitle)`
  width: 52%;
`;

const Opi = observer(() => {
  const { widgetStore } = useStores();
  return (
    <WidgetWrapper>
      {widgetStore.opiSevenDayData &&
        widgetStore.opiSevenDayData.map((element: OPISevenDay, idx: number) => {
          // We only show data for the AM of the final day since we don't have full data for the PM
          if (idx < 7) {
            return (
              <DialWrapper key={`opi-${element.time_frame}`}>
                <SolidDial
                  widgetName={WIDGET_NAMES.OPI}
                  current={element.level}
                  innerText={opiLevelShort(element.level)}
                />
                <OpiTitle>{moment(element.time_frame).format(DATETIME_FORMATS.DAY_OF_THE_WEEK_HEADER)}</OpiTitle>
              </DialWrapper>
            );
          } else {
            return null;
          }
        })}
    </WidgetWrapper>
  );
});

export default Opi;
