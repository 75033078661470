import { useState, useEffect } from 'react';
import { TIDES_ENDPOINT } from '../../../constants';
import { getApiData } from 'utils/axios';
import { DATETIME_FORMATS } from '@sdge-web-app/shared/dist/constants';
import moment from 'moment-timezone';
import { Tide } from '@sdge-web-app/shared/dist/types';

function Tides() {
  const [tidesData, setTidesData] = useState<Tide[]>([]);
  const [dataRetreived, setDataRetreived] = useState(false);

  useEffect(() => {
    setDataRetreived(true);
  }, []);

  // Make a request for the Tides data from the database
  if (!dataRetreived) {
    getApiData(TIDES_ENDPOINT)
      .then((response) => {
        // handle success
        setTidesData(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }

  return (
    <div className="tides">
      <h2>Tides data:</h2>
      {tidesData &&
        tidesData.map((tide: Tide) => (
          <div key={`tidesData-${tide.id}`}>
            <h3>
              Day: {tide.day}, {moment(tide.date).format(DATETIME_FORMATS.DATE_FORMAT)}
            </h3>
            <div>
              First Low Tide: {tide.first_low_tide_value} feet, {tide.first_low_tide_time}
            </div>
            <div>
              First High Tide: {tide.first_high_tide_value} feet, {tide.first_high_tide_time}
            </div>

            {tide.second_low_tide_time && tide.second_low_tide_value ? (
              <div>
                Second Low Tide: {tide.second_low_tide_value} feet, {tide.second_low_tide_time}
              </div>
            ) : (
              <div>
                Second Low Tide: <i>There is no second Low tide for this day</i>
              </div>
            )}
            {tide.second_high_tide_time && tide.second_high_tide_value ? (
              <div>
                Second High Tide: {tide.second_high_tide_value} feet, {tide.second_high_tide_time}
              </div>
            ) : (
              <div>
                Second High Tide: <i>There is no second High tide for this day</i>
              </div>
            )}
            <div>Water Temp: {tide.water_temp}</div>
            <div>Created At: {moment(tide.created_at).format()}</div>
          </div>
        ))}
    </div>
  );
}

export default Tides;
