import moment, { Moment } from 'moment-timezone';
import styled from 'styled-components';
import { SevenDayFPI as ISevenDayFPI } from '@sdge-web-app/shared/dist/types';
import { DISTRICTS_LIST } from '@sdge-web-app/shared/dist/constants';
import { fpiLongLevelFromValue } from '@sdge-web-app/shared/dist/utils';
import { ColorBubble, FPICell, FPISpectrum, SpectrumLabels } from './TodaysFPI';
import { SectionTitle, SectionWithTable } from './shared';

const Wrapper = styled(SectionWithTable)`
  table {
    @media (max-width: 520px) {
      font-size: 12px;
    }

    th {
      padding: 14px 0 20px;
      color: ${props => props.theme.foreground};
    }

    td {
      font-weight: normal;
      text-transform: uppercase;
    }

    thead tr {
      border-bottom: 1px solid #ccc !important;
    }
  }
`;

interface Props {
  reportDate: Date | string;
  show: boolean;
  sevenDayFPI?: ISevenDayFPI[];
}

export default function SevenDayFPI({ reportDate, show, sevenDayFPI }: Props) {
  if (!show || sevenDayFPI == null) return null;

  const reportStartDate = moment(reportDate).startOf('day');
  const days: Moment[] = [];

  for (let i = 0; i < 7; i++) {
    days.push(reportStartDate.clone().add(i, 'days'));
  }

  return (
    <Wrapper>
      <SectionTitle>7-Day FPI Outlook</SectionTitle>
      <table>
        <thead>
          <tr>
            <th></th>
            {days.map((day) => (
              <th key={day.toISOString()}>
                {day.format('ddd').toUpperCase()}
                <br />
                {day.format('M/D')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DISTRICTS_LIST.map((district) => (
            <tr key={district.code}>
              <th>{district.code.toUpperCase()}</th>
              {days.map((day) => {
                const fpi = sevenDayFPI.find(
                  (fpi) =>
                    fpi.region === district.id &&
                    moment(fpi.valid_time).startOf('day').toISOString() === day.toISOString(),
                );

                return fpi?.fpi != null ? (
                  <FPICell key={day.toISOString()} fpi={fpi.fpi}>
                    {fpiLongLevelFromValue(fpi.fpi)}
                    <br />
                    <ColorBubble fpi={fpi.fpi}>{fpi.fpi}</ColorBubble>
                  </FPICell>
                ) : (
                  <td key={day.toISOString()}></td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <FPISpectrum />
      <SpectrumLabels>
        <div>&lt; 12 | Normal</div>
        <div>12 - 13 | Elevated</div>
        <div>14 | Elevated</div>
        <div>&gt; 14 | Extreme</div>
      </SpectrumLabels>
    </Wrapper>
  );
}
