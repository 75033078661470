import { PAL_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { DialTitle } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { palLevelShort } from 'utils/widgetUtils';
import { Row, WideDialWrapper, Wrapper } from './SharedStyles';

export default function PalInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.green.long}
            innerText={palLevelShort(PAL_LEVELS.green.long)}
          />
          <DialTitle>{PAL_LEVELS.green.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.blue.long}
            innerText={palLevelShort(PAL_LEVELS.blue.long)}
          />
          <DialTitle>{PAL_LEVELS.blue.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.yellow.long}
            innerText={palLevelShort(PAL_LEVELS.yellow.long)}
          />
          <DialTitle>{PAL_LEVELS.yellow.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.orange.long}
            innerText={palLevelShort(PAL_LEVELS.orange.long)}
          />
          <DialTitle>{PAL_LEVELS.orange.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.brown.long}
            innerText={palLevelShort(PAL_LEVELS.brown.long)}
          />
          <DialTitle>{PAL_LEVELS.brown.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.red.long}
            innerText={palLevelShort(PAL_LEVELS.red.long)}
          />
          <DialTitle>{PAL_LEVELS.red.long}</DialTitle>
        </WideDialWrapper>
        <WideDialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.PAL}
            current={PAL_LEVELS.gray.long}
            innerText={palLevelShort(PAL_LEVELS.gray.long)}
          />
          <DialTitle>{PAL_LEVELS.gray.long}</DialTitle>
        </WideDialWrapper>
      </Row>
      Map indicators with region names
      <Row>
        <SolidPinWithSubtext
          widgetName={WIDGET_NAMES.PAL}
          current={PAL_LEVELS.green.long}
          innerText={palLevelShort(PAL_LEVELS.green.long)}
          subText="Inland"
        />
        <SolidPinWithSubtext
          widgetName={WIDGET_NAMES.PAL}
          current={PAL_LEVELS.red.long}
          innerText={palLevelShort(PAL_LEVELS.red.long)}
          subText="Mountain"
        />
      </Row>
    </Wrapper>
  );
}
