import { OPI_LEVELS, WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPin } from 'components/Map/pins/SolidPin';
import { DialTitle, DialWrapper } from 'components/Widgets/components/SharedStyles';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { opiLevelShort } from 'utils/widgetUtils';
import { Row, Wrapper } from './SharedStyles';

export default function OpiInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.normal.long}
            innerText={opiLevelShort(OPI_LEVELS.normal.long)}
          />
          <DialTitle>{OPI_LEVELS.normal.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.elevated.long}
            innerText={opiLevelShort(OPI_LEVELS.elevated.long)}
          />
          <DialTitle>{OPI_LEVELS.elevated.long}</DialTitle>
        </DialWrapper>
        <DialWrapper>
          <SolidDial
            widgetName={WIDGET_NAMES.OPI}
            current={OPI_LEVELS.extreme.long}
            innerText={opiLevelShort(OPI_LEVELS.extreme.long)}
          />
          <DialTitle>{OPI_LEVELS.extreme.long}</DialTitle>
        </DialWrapper>
      </Row>
      Map indictors
      <Row>
        <SolidPin
          widgetName={WIDGET_NAMES.OPI}
          current={OPI_LEVELS.normal.long}
          innerText={opiLevelShort(OPI_LEVELS.normal.long)}
        />
        <SolidPin
          widgetName={WIDGET_NAMES.OPI}
          current={OPI_LEVELS.extreme.long}
          innerText={opiLevelShort(OPI_LEVELS.extreme.long)}
        />
      </Row>
    </Wrapper>
  );
}
