import { WIDGET_NAMES } from '@sdge-web-app/shared/dist/constants';
import { SolidPinWithSubtext } from 'components/Map/pins/SolidPinWithSubtext';
import { SolidDial } from 'components/Widgets/components/SolidDial';
import { Row, Wrapper } from './SharedStyles';

export default function LowTempInfographic() {
  return (
    <Wrapper>
      Graphical level representation
      <Row>
        <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={40} />
        <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={55} />
        <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={75} />
        <SolidDial widgetName={WIDGET_NAMES.LOW_TEMP} current={90} />
      </Row>
      Map indicators with station names
      <Row>
        <SolidPinWithSubtext widgetName={WIDGET_NAMES.LOW_TEMP} current={20} subText="Crestwood" />
        <SolidPinWithSubtext widgetName={WIDGET_NAMES.LOW_TEMP} current={80} subText="Imperial Valley" />
      </Row>
    </Wrapper>
  );
}
