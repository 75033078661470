import { makeAutoObservable, runInAction } from 'mobx';
import { Widget } from '@sdge-web-app/shared/dist/types';

class MapStateStore {
  private interval?: ReturnType<typeof setInterval>;
  private secondsPassed: number = 0;
  private secondsBeforeWidgetChange: number = 10;
  private widgets?: Widget[];
  private currentIndex: number = 0;

  public progress: number = 0;
  public currentWidgetName?: string;
  public paused: boolean = true;
  public darkMode: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private intervalHandler() {
    runInAction(() => {
      if (this.widgets == null) return;

      if (this.secondsPassed >= this.secondsBeforeWidgetChange) {
        let newIndex;

        if (this.currentIndex + 1 < this.widgets.length) {
          newIndex = this.currentIndex + 1;
        } else {
          newIndex = 0;
        }

        this.currentIndex = newIndex;
        this.currentWidgetName = this.widgets[this.currentIndex].name;
        this.secondsPassed = 0;
        this.progress = this.secondsPassed / this.secondsBeforeWidgetChange;
      } else {
        this.secondsPassed += 1;
        this.progress = this.secondsPassed / this.secondsBeforeWidgetChange;
      }
    });
  }

  start(widgets: Widget[]) {
    if (this.interval != null) {
      console.error('Tried to start automatic widget switching, but it has already started!');
      return;
    }

    this.widgets = widgets;
    this.currentWidgetName = widgets[0].name;

    this.interval = setInterval(this.intervalHandler, 1000);
    this.paused = false;
  }

  togglePlayOrPaused() {
    if (this.paused) {
      this.interval = setInterval(this.intervalHandler, 1000);
      this.paused = false;
    } else {
      if (this.interval != null) {
        clearInterval(this.interval);
        this.paused = true;
        this.secondsPassed = 0;
        this.progress = 0;
      }
    }
  }

  stop() {
    if (this.interval != null) {
      clearInterval(this.interval);

      this.interval = undefined;
      this.secondsPassed = 0;
      this.progress = 0;
      this.widgets = undefined;
      this.currentIndex = 0;
      this.currentWidgetName = undefined;
      this.paused = true;
    }
  }

  prevWidget() {
    if (this.widgets == null) return;

    let newIndex;

    if (this.currentIndex - 1 >= 0) {
      newIndex = this.currentIndex - 1;
    } else {
      newIndex = this.widgets.length - 1;
    }

    this.currentIndex = newIndex;
    this.secondsPassed = 0;
    this.progress = 0;
    this.currentWidgetName = this.widgets[newIndex].name;
  }

  nextWidget() {
    if (this.widgets == null) return;

    let newIndex;

    if (this.currentIndex + 1 < this.widgets.length) {
      newIndex = this.currentIndex + 1;
    } else {
      newIndex = 0;
    }

    this.currentIndex = newIndex;
    this.secondsPassed = 0;
    this.progress = 0;
    this.currentWidgetName = this.widgets[newIndex].name;
  }

  switchToWidget(widgetName: string) {
    if (this.widgets == null) return;
    const widget = this.widgets.find((w) => w.name === widgetName);
    if (widget == null) return;

    this.secondsPassed = 0;
    this.progress = 0;
    this.currentIndex = this.widgets.indexOf(widget);
    this.currentWidgetName = widgetName;
  }

  resyncWithNewWidgets(widgets: Widget[]) {
    this.widgets = widgets;

    const currentlySelectedWidget = this.widgets.find((w) => w.name === this.currentWidgetName);

    if (currentlySelectedWidget) {
      this.currentIndex = this.widgets.indexOf(currentlySelectedWidget);
    } else {
      this.currentWidgetName = this.widgets[this.currentIndex].name;
    }
  }

  toggleDarkMode() {
    runInAction(() => {
      this.darkMode = !this.darkMode;
    });
  }

  setDarkMode(dark: boolean) {
    runInAction(() => {
      this.darkMode = dark;
    });
  }
}

export default MapStateStore;
