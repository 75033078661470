import { WeatherBriefingOld } from '@sdge-web-app/shared/dist/types';

export default function SafetyTalkingPoint({ weatherBriefing }: { weatherBriefing: WeatherBriefingOld }) {
  if (!weatherBriefing.data.safety_talking_point) return null;

  return (
    <table
      className="row row-safety-talking-point"
      style={{
        borderSpacing: '0',
        borderCollapse: 'collapse',
        padding: '0',
        verticalAlign: 'top',
        textAlign: 'left',
        width: '100%',
        position: 'relative',
        display: 'table',
      }}>
      <tbody>
        <tr
          style={{
            padding: '0',
            verticalAlign: 'top',
            textAlign: 'left',
          }}>
          <th
            className="small-12 large-6 columns first text-middle has-icon"
            style={{
              verticalAlign: 'middle',
              margin: '0 auto',
              paddingLeft: '16px',
              paddingBottom: '16px',
              width: '274px',
              paddingRight: '8px',
              padding: '75px 0',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="text-center"
                    style={{
                      textAlign: 'center',
                    }}>
                    <img
                      alt=""
                      src="/media/old_weather_briefings/safety-talking-point.png"
                      className="ibl"
                      style={{
                        outline: 'none',
                        textDecoration: 'none',

                        width: 'auto',
                        maxWidth: '100%',
                        clear: 'both',
                        display: 'inline-block',
                      }}
                    />
                    <h3
                      className="text-blue text-center"
                      style={{
                        textAlign: 'center',
                        color: '#004286',
                        fontSize: '28px',
                        fontWeight: 'normal',
                      }}>
                      Safety Talking Point
                    </h3>
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
          <th
            className="small-12 large-6 columns last has-content"
            style={{
              margin: '0 auto',
              paddingLeft: '8px',
              paddingBottom: '16px',
              width: '274px',
              paddingRight: '16px',
              padding: '75px 60px 75px 0',
            }}>
            <table
              style={{
                borderSpacing: '0',
                borderCollapse: 'collapse',
                padding: '0',
                verticalAlign: 'top',
                textAlign: 'left',
                width: '100%',
              }}>
              <tbody>
                <tr
                  style={{
                    padding: '0',
                    verticalAlign: 'top',
                    textAlign: 'left',
                  }}>
                  <th
                    className="padding-lr-5"
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }}>
                    <span
                      className="text-block"
                      style={{ fontWeight: 'normal' }}
                      dangerouslySetInnerHTML={{ __html: weatherBriefing.data.safety_talking_point }}
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </th>
        </tr>
      </tbody>
    </table>
  );
}
