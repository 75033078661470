import styled from 'styled-components';
import { observer } from 'mobx-react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: center;
`;

const Content = styled.div`
  overflow-y: auto;
  width: 100%;
`;

const Link = styled.a`
  font-weight: bold;
  font-size: 1.5625rem;
  cursor: pointer;
  margin: 0.5rem 0;
  color: unset;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkWrapper = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const QuicklinksOverlay = observer(() => {
  return (
    <Wrapper>
      <Content>
        <LinkWrapper>
          <Link href="https://weather.sdgeweather.com">Data</Link>
          <Link href="https://wxmap.sdsc.edu">Models</Link>
        </LinkWrapper>
      </Content>
    </Wrapper>
  );
});
