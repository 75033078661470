function fpiColor(fpiLevel: number) {
  if (fpiLevel <= 11) {
    return '#4EAD5B';
  } else if (fpiLevel >= 12 && fpiLevel <= 13) {
    return '#dbdc47';
  } else if (fpiLevel === 14) {
    return '#F5C343';
  } else if (fpiLevel >= 15) {
    return '#EA3323';
  } else {
    return 'black';
  }
}

export function fpiWarningLevel(fpiLevel: number) {
  if (fpiLevel <= 11) {
    return 'Normal';
  } else if (fpiLevel >= 12 && fpiLevel <= 13) {
    return 'Elevated';
  } else if (fpiLevel === 14) {
    return 'Elevated';
  } else if (fpiLevel >= 15) {
    return 'Extreme';
  }

  return 'Normal';
}

const utils = {
  fpiColor,
  fpiWarningLevel,
};

export default utils;
